import { memo } from 'react';

import { Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import Select from 'react-select/async';

import type { Props } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  selectContainer: {
    width: '100%',
  },
  label: {
    color: theme.palette.grey[700],
  },
}));

function AsyncSelect(props: Props) {
  const classes = useStyles();

  const {
    containerClass,
    disabled,
    label,
    labelClass,
    loadOptions,
    id,
    ...rest
  } = props;
  return (
    <div className={containerClass || classes.selectContainer}>
      {label && (
        <Typography
          variant="caption"
          component="span"
          className={labelClass || classes.label}
        >
          {`${label}${props.required ? '*' : ''}`}
        </Typography>
      )}
      <Select
        {...rest}
        isDisabled={disabled}
        loadOptions={loadOptions}
        inputId={id}
        menuPortalTarget={document.body}
        styles={{
          dropdownIndicator: (provided, state) => ({
            ...provided,
            transform: state.selectProps.menuIsOpen && 'rotate(-180deg)',
            transitionDuration: '0.3s',
            transitionProperty: 'transform',
          }),
          menu: (provided) => ({
            ...provided,
            maxHeight: '200px',
            overflowY: 'auto',
          }),
        }}
      />
    </div>
  );
}

export default memo(AsyncSelect);
