import { memo, useMemo } from 'react';
import {
  CategoryScale,
  Chart,
  LinearScale,
  BarElement,
  BarController,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import type { Props } from '../types';
import { omit } from 'lodash';
Chart.register(CategoryScale, LinearScale, BarElement, BarController);
function ReusableBarChart({
  data: dataProp,
  labels,
  title,
  plugins: pluginProp,
  stacked,
  grouped,
  scales,
  isLine,
}: Props) {
  const theme = useTheme();

  const colors = useMemo(
    () => [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.warning.main,
      theme.palette.error.main,
      theme.palette.info.main,
      theme.palette.success.main,
    ],
    [theme],
  );

  const backgroundColor = useMemo(
    () => dataProp?.slice().map((_, index) => colors[index % 6]),
    [dataProp, colors],
  );
  const plugins = Object.assign(
    {
      legend: {
        display: false,
      },
    },
    pluginProp,
  );

  const isTablet = useMediaQuery('(max-width:1250px)');
  const isMobile = useMediaQuery('(max-width:900px)');

  const minSize = useMemo(
    () => (isMobile ? '250px' : isTablet ? '300px' : '450px'),
    [isMobile, isTablet],
  );
  // const minSize = useMemo(() => (isMobile ? 150 : isTablet ? 175 : 200), [
  //   isMobile,
  //   isTablet,
  // ]);

  const datasets =
    stacked || grouped
      ? (dataProp || []).map((dataPoint, index) => ({
          backgroundColor: backgroundColor
            ? backgroundColor[index % 6]
            : undefined,
          ...dataPoint,
          borderWidth: 1,
          hoverOffset: 5,
        }))
      : [
          {
            label: title,
            data: dataProp || [],
            borderWidth: 1,
            hoverOffset: 5,
            backgroundColor: backgroundColor ? backgroundColor[0] : undefined,
          },
        ];

  const dataObject = {
    labels: labels ? labels.map((l) => l) : [],
    datasets,
  };
  // console.log('dataObjectBar', dataObject);
  return (
    <div
      style={{
        // maxWidth: '50%',
        // maxHeight: '50%',
        // minWidth: minSize,
        minHeight: minSize,
      }}
    >
      <Bar
        options={{
          responsive: true,
          maintainAspectRatio: false,
          // aspectRatio: 1,
          plugins: omit(plugins, ['scales']),
          scales: stacked
            ? {
                y: { stacked: true },
                x: {
                  stacked: true,
                  grid: {
                    display: false,
                  },
                },
              }
            : scales
            ? scales
            : {},
        }}
        data={dataObject}
      />
    </div>
  );
}

export default memo(ReusableBarChart);
