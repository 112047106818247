import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';

import type { InventoryOperation } from './types';

export default function useInventoryOperations() {
  return useSWR<InventoryOperation[]>(
    `/api/v1/reports/operations/inventory`,
    Api.get,
  );
}
