import { useState, useEffect, useMemo, FormEvent } from 'react';
import { omit, isEmpty } from 'lodash';
import { Grid, Paper, Typography, Button, Divider, Modal } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { useSnackbar } from 'notistack';

import useCompanies from '@src/hooks/swr/useCompanies';
import Api from '@oneAppCore/services/Api';
import { TextField, Select } from '@src/Components/common';
import type { CreateUser } from '@oneAppCore/types/userTypes';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';

import { formFields } from './constants';
import { validate } from '../utils';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import UsersApi from '@oneAppCore/services/apis/UsersApi';
import { mutate } from 'swr';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',
  },
  botmarg: {
    marginBottom: '10px',
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

const omitFields = [
  'id',
  'createdBy',
  'updatedBy',
  'deletedBy',
  'createdAt',
  'updatedAt',
  'deletedAt',
  'confirmPassword',
  'roles',
];

function CoreInformation({ user, edit }: { user: any; edit: Boolean }) {
  const classes = useStyles();

  const [form, setForm] = useState<CreateUser>(user);
  const [errors, setErrors] = useState<Record<string, boolean>>({});
  const [modal, setModal] = useState<boolean>(false);
  const [updateUrl, setUpdateUrl] = useState('');

  const { data: companies = [] } = useCompanies();

  const { enqueueSnackbar } = useSnackbar();

  console.log(form.image, 'user form');

  useEffect(() => {
    setForm(user);
  }, [user]);

  const update = (key: string, value: string | number) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const submit = async (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    const error = validate(form);
    setErrors(error);
    if (!isEmpty(error)) {
      return;
    }
    try {
      const payload = omit(form, omitFields);
      if (form.id) {
        await Api.put(`/api/v1/users/${form.id}`, payload);
      } else {
        await Api.post(`/api/v1/users`, payload);
      }
      enqueueSnackbar('Saved', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const companyOptions = useMemo(
    () =>
      companies.map((company) => ({
        value: company.id,
        label: company.name,
      })),
    [companies],
  );

  const handleImageClick = () => {
    setModal(true);
    const clickedImage = form?.image;
    if (clickedImage) {
      setUpdateUrl(clickedImage);
    }
  };
  const updateImage = async (url: string) => {
    const id = form.id;

    const updatedForm = omit(
      {
        ...form,
        image: url,
      },
      ['id', 'createdAt', 'createdBy', 'roles'],
    );
    try {
      await Api.put(`/api/v1/users/${id}`, updatedForm);
      setModal(false);
      enqueueSnackbar('Updated Profile Photo!', { variant: 'success' });

      setForm((prevForm) => ({
        ...prevForm,
        image: url,
      }));
    } catch (e) {
      setModal(false);
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    // <Paper elevation={1} className={classes.root}>
    <form onSubmit={submit}>
      <Grid item container alignItems="center" spacing={1}>
        {/* <Grid item container justify="flex-start" style={{ marginBottom: 10 }}>
          <Typography variant="h6">{`${user.firstName}'s Information`}</Typography>
        </Grid>
        <Grid xs={12} style={{ marginBottom: 15 }}>
          <Divider />
        </Grid> */}
        <Grid item container xs={12} alignItems="flex-end">
          <Grid item xs={1} style={{ marginRight: 10 }}>
            <img
              height={100}
              width={100}
              style={{ borderRadius: '50%' }}
              src={
                form?.image
                  ? form?.image
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
              }}
            />
          </Grid>
          <Grid item xs={1} style={{ marginBottom: 15 }}>
            <FileUpload
              id="userUpload"
              onUpload={(urls: string[]) => {
                if (urls.length > 0) {
                  updateImage(urls[0]);
                }
              }}
              buttonText="Upload Image"
            />
          </Grid>
        </Grid>
        {formFields.map((field, fieldIndex) => {
          let formElement;
          switch (field.type) {
            case 'select':
              formElement = (
                <Select
                  containerClass={!edit && classes.containerClass}
                  disabled={!edit}
                  options={companyOptions}
                  value={companyOptions.find(
                    (option) => option.value === form[field.key],
                  )}
                  onChange={(e) => update(field.key, e.value)}
                  placeholder={`Select ${field.label}...`}
                  label={field.label}
                  required={field.required}
                  styles={{ position: 'absolute', top: '0' }}
                />
              );
              break;
            default:
            case 'password':
              formElement = (
                <TextField
                  disabled={!edit}
                  fullWidth
                  label={field.label}
                  variant="outlined"
                  value={form[field.key] ?? ''}
                  onChange={(e) => update(field.key, e.currentTarget.value)}
                  error={Boolean(errors[field.key])}
                  type={field.type}
                  name={`${field.type}-${field.key}`}
                  required={field.required}
                />
              );
              break;
          }
          return (
            <Grid
              key={field.key}
              item
              style={{ padding: 10 }}
              xs={12}
              md={6}
              className={
                !edit && fieldIndex === formFields.length - 1
                  ? classes.botmarg
                  : ''
              }
            >
              {formElement}
            </Grid>
          );
        })}
      </Grid>
      {edit && (
        <Grid item container justifyContent="flex-end">
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </Grid>
      )}
    </form>
    // </Paper>
  );
}

export default CoreInformation;
