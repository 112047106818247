import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';

type Params = {
  id: number;
  searchFilter?: string;
  filters?: any;
  sku?: string;
  onlyNewListings?: boolean;
};

export default function useVariationData({
  id,
  searchFilter,
  filters,
  sku,
  onlyNewListings = false,
}: Params) {
  return useSWR(
    id
      ? `/api/v1/products/${id}/variations?searchFilter=${searchFilter}&sku=${sku}&onlyNewListings=${onlyNewListings}`
      : null,
    Api.get,
  );
}

export function useVarData({
  id,
  searchFilter,
  filters,
  sku,
  onlyNewListings = false,
}: Params) {
  return useSWR(`/api/v1/products/${id}/variations`, Api.get);
}
