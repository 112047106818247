import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);
import { colorTheme } from '@oneAppCore/constants/internalvdropship';
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import LineChart from '@src/Components/common/charts/LineChart';
import ReportApi from '@oneAppCore/services/ReportApi';
import { Props as SelfProps } from './types';
import { Props } from '@src/Components/Dashboards/Analytics/types';
import { unitDateLabel } from '@src/Components/Dashboards/Analytics/utilities';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import dateUtils from '@oneAppCore/utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  width: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  height: {},
}));

function SupplierCostChart({ unitsBack, unitType, supplierId, supplierName }: Props & SelfProps) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const theme = createTheme(colorTheme);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      setData([]);
      const response = await ReportApi.get('SUPPLIER_COST_CHART', {
        newerThan,
        page: 1,
        limit: 1000000,
        supplierId,
      });


      const objectTotalSales = {};
      const objSupplierSubtotal = {};
      const objMargin = {};

      const obj = {};
      response.rows.forEach((row) => {
        const keyName = dayjs(
          row['Purchase Date'],
          'MM-DD-YYYY HH:mm:ss',
        ).format('MM/DD/YYYY');
        if (!objectTotalSales[keyName]) objectTotalSales[keyName] = 0;
        objectTotalSales[keyName] += row['Total Sales'];

        if (!objSupplierSubtotal[keyName]) objSupplierSubtotal[keyName] = 0;

        objSupplierSubtotal[keyName] += row['Suppier Subtotal'];

        if (!objMargin[keyName]) objMargin[keyName] = 0;

        // objMargin[keyName] += row['Total Profit'];
        objMargin[keyName] += (row['Total Sales'] - row['Suppier Subtotal']);
      });

      const dateLabels = Object.keys(objectTotalSales).sort();
      const totalSalesData = dateLabels.map((key) => objectTotalSales[key]);
      const supplierSubtotalData = dateLabels.map((key) => objSupplierSubtotal[key]);
      const marginData = dateLabels.map((key) => objMargin[key]);

      const chartData = [
        {
          data: totalSalesData,
          label: 'Total Sales',
          backgroundColor: '#5F37B1',
          borderColor: '#5F37B1',
        },
        {
          data: supplierSubtotalData,
          label: 'Supplier Subtotal',
          backgroundColor: '#2188E5',
          borderColor: '#2188E5',
        },
        {
          data: marginData,
          label: 'Total Profit',
          backgroundColor: '#4d9c38',
          borderColor: '#4d9c38',
        },
      ];
      setData(chartData);

      setLabels(dateLabels);
    })();
  }, [unitsBack, unitType, supplierId]);

  const unitsLabel = `Supplier Cost ${unitDateLabel({
    unitsBack,
    unitType,
  })}`;

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">{supplierName} Cost{/*  / Order */}</Typography>
      </Grid>
      <Grid item className={classes.width}>
        <LineChart
          labels={labels}
          data={data}
          stacked
          title={`Supplier Cost`}
          plugins={{
            legend: {
              display: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default SupplierCostChart;
