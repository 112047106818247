import Api from '@oneAppCore/services/Api';

async function fetchVariationData(id, onlyNewListings) {
  try {
    const response = await Api.get(`/api/v1/products/${id}/variations?onlyNewListings=${onlyNewListings}`);
    return response;
  } catch (error) {
    console.error('Error fetching variation data:', error);
    return null;
  }
}

export default async function useVarDataBulk(ids, onlyNewListings) {
  if (!Array.isArray(ids)) {
    throw new Error('IDs must be provided as an array');
  }

  const variationDataPromises = ids.map((id) => fetchVariationData(id, onlyNewListings));
  return Promise.all(variationDataPromises);
}
