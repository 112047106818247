import { orderColumns } from './constants';

import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useRecoilValue } from 'recoil';
import CCRecApi from '@oneAppCore/services/CCRecApi';
import { reposState as reposAtom } from '@oneAppCore/one-app/src/Components/common/containers/SearchView/SearchTable/atoms';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import MiniReports from './MiniReports';
import Api from '@oneAppCore/services/Api';

function CCRecSearch() {
  const [type, setType] = useState([]);
  const [changes, setChanges] = useState([]);
  const [PoList, setPoList] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const tableRowsState = useRecoilValue(reposAtom);
  const [mutateValue, setMutateValue] = useState<number>(1);

  const handleChange = (e) => {
    setType([e.target.value]);
  };

  useEffect(() => {
    const getPONos = async () => {
      const { rows: PoNos } = await Api.get(
        `/api/v1/manufacturer-supplier-records/getPONumbers`,
      );
      setPoList(PoNos);
    };
    getPONos();
  }, [mutateValue]);

  const save = async (id, colName: string, value) => {
    const payload = {
      [colName]: value,
    };
    try {
      await CCRecApi.put(id, payload);
      enqueueSnackbar('Charge has been updated!', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      setMutateValue(mutateValue + 1);
    }
  };

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        mutateValue={mutateValue}
        url="/api/v1/cc-rec/search"
        columns={orderColumns(handleChange, type, PoList, save, tableRowsState)}
        filters={[
          {
            type: 'date range',
            name: 'Transaction Date',
            keyName: 'transactionDate',
          },
          {
            type: 'selector',
            name: 'Matched',
            keyName: 'matched',
            properties: [
              {
                label: 'Matched',
                value: 'matched',
              },
              {
                label: 'Unmatched',
                value: 'unmatched',
              },
            ],
          },
          {
            type: 'selector',
            name: 'Charge Type',
            keyName: 'matchType',
            properties: [
              { label: 'Dropship', value: 'dropship' },
              { label: 'Inventory', value: 'inventory' },
              { label: 'Shipping', value: 'shipping' },
              { label: 'Misc.', value: 'misc' },
            ],
          },
        ]}
        MiniReportComponent={MiniReports}
      />
    </Grid>
  );
}

export default CCRecSearch;
