import { useState, useEffect, FormEvent, useRef } from 'react';
import { useParams } from 'react-router-dom';
import SupplierSelect from '@src/Components/common/Selects/SupplierSelect';
import FontAwesome from '@src/Components/common/FontAwesome';
import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions/index';

import {
  Table,
  TablePagination,
  Card,
  CircularProgress,
  Button,
  Grid,
  TextField,
  Chip,
  Typography,
  Select,
  MenuItem,
  InputLabel,
  IconButton,
  Divider,
  Autocomplete,
  createFilterOptions,
  FormControl,
  OutlinedInput,
  InputAdornment,
} from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import SearchBar from '@src/Components/common/containers/SearchView/SearchTable/SearchBar';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';

import { columns } from './constants';
import { Filters } from '@src/Components/common/containers/SearchView/types';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import AddVariation from './addVariation';

import type {
  Form,
  Params,
  Supplier,
  VariationsForm,
  FitmentForm,
  ManufacturerSupplierForm,
} from '../types';
import { VarOptionType, OptionType } from '@oneAppCore/types/productTypes';
import { primary } from '@src/Components/Theme/themes/constants';
import { Theme } from '@mui/material/styles';
import type { Makes, Models } from '../../NewProductForm/types';
import { useVarData } from '@src/hooks/swr/Inventory/useVariationData';
import VariationBox from '../../NewProductForm/VariationsStep/variationBox';
import FitmentApi from '@oneAppCore/services/FitmentApi';
import type { SaveFunction } from '../../NewProductForm/types';
import { mutate } from 'swr';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    [theme.breakpoints.down('md')]: {
      overflow: 'scroll',
    },
    [theme.breakpoints.up('md')]: {
      overflow: 'visible',
    },
  },
}));

const filter = createFilterOptions<string | VarOptionType>();
const optionFilter = createFilterOptions<string | OptionType>();

function VariationsTable({
  product,
  supps,
  varList,
  brand: brandParam,
  props,
  save,
  updateProductForm,
  row,
  cellIndex,
  isViewMode,
  skuError,
  mpnError,
  mutate,
}: {
  save?: SaveFunction;
  row?: any;
  varList?: any[];
  mutate?: any;
  product?: Form;
  props?: any;
  brand?: string;
  supps?: Supplier[];
  updateProductForm?: any;
  cellIndex?: number;
  isViewMode?: boolean;
  skuError: boolean;
  mpnError: boolean;
}) {
  const { id: paramId } = useParams<Params>();

  const classes = useStyles();
  const [varValue, setVarValue] = useState<VarOptionType | null>(null);
  const [optionValue, setOptionValue] = useState<OptionType | null>(null);
  const [openedCell, setOpenedCell] = useState<number>();
  const [openedType, setOpenedType] = useState<string>('fitment');
  const [form, setForm] = useState<VariationsForm[]>([]);
  const [suppliers, setSuppliers] = useState<ManufacturerSupplierForm[][]>([
    [],
  ]);
  const [imageCell, setImageCell] = useState<number>();
  const [brand, setBrand] = useState<string>(brandParam);
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [page, setPage] = useState(0);
  const [makes, setMakes] = useState<Makes[]>([]);
  const [models, setModels] = useState<Models[]>([]);
  const [isShown, setIsShown] = useState(false);
  const [variantType, setVariantType] = useState<string>('');
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [selectedFilterValues, setSelectedFilterValues] = useState({});
  const [filters, setFilters] = useState<Filters[]>([]);
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const [vars, updateVars] = useState<string[]>([]);
  const [varOptions, setVarOptions] = useState<string[]>([]);
  const [addOptions, setAddOptions] = useState(false);
  const [addVars, setAddVars] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [optionNameOne, setOptionNameOne] = useState('');
  const [hasOptNameOne, setHasOptNameOne] = useState(false);
  const [optionNameTwo, setOptionNameTwo] = useState('');
  const [hasOptNameTwo, setHasOptNameTwo] = useState(false);
  const { data: variations, mutate: mutateVariations } = useVarData({
    id: Number(paramId),
    // searchFilter: searchOptions.searchFilter,
    sku: searchOptions.filters.sku,
  });
  console;
  const CustomColorIconButton = withStyles({
    root: {
      color: 'crimson',
    },
  })(IconButton);

  const hanleKeyPress = (e) => {
    if (e.key === 'Enter') {
      setHasOptNameOne(true);
    }
  };

  const handleKeyPress2 = (e) => {
    if (e.key === 'Enter') {
      setHasOptNameTwo(true);
    }
  };

  // let productCosts = suppliers.map(function (cost) {
  //   return cost.cost + cost.shippingCost;
  // });

  // let totalCost = productCosts.reduce(function (acc, total) {
  //   return acc + total;
  // }, 0);

  const unique = Array.from(new Set(varList));

  const handleClick = () => {
    setIsShown(true);
  };

  const setResetPagination = (shouldReset: boolean) => {
    if (shouldReset) {
      setPage(0);
    }
  };

  const setOpen = (cell: number, type: string = 'fitment') => {
    setOpenedType(type);
    setOpenedCell(cell);
  };

  useEffect(() => {
    const getMakesAndModels = async () => {
      const { rows: make } = await FitmentApi.getMakes();
      const { rows: model } = await FitmentApi.getModels();
      setMakes(make);
      setModels(model);
    };
    getMakesAndModels();
    const updatedVariations = variations?.slice() || [];
    if (updatedVariations.length < 1) {
      updatedVariations.push({});
    }
    if (updatedVariations[0].brand) {
      setBrand(updatedVariations[0].brand);
    }
    setForm(updatedVariations);
  }, [variations]);

  useEffect(() => {
    setBrand(brandParam);
  }, [brandParam]);

  const addSuppliers = (variationIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const updatedSuppliers = suppliers.slice() || [];
    updatedSuppliers[variationIndex].push({ supplierId: 0 });
    updatedVariations[variationIndex].suppliers =
      updatedSuppliers[variationIndex];
    setSuppliers(updatedSuppliers);
    updateProductForm('variations', updatedVariations);
    setOpenedCell(variationIndex);
  };

  const removeSuppliers = (index: number, variationIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers[variationIndex].splice(index, 1);
    updatedVariations[variationIndex].suppliers =
      updatedSuppliers[variationIndex];
    setSuppliers(updatedSuppliers);
    updateProductForm('variations', updatedVariations);
  };

  const updateSuppliers = <K extends keyof ManufacturerSupplierForm>(
    index: number,
    key: K,
    value: ManufacturerSupplierForm[K],
    variationIndex: number,
  ) => {
    const updatedVariations = form?.slice() || [];
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers[variationIndex][index] = {
      ...updatedSuppliers[variationIndex][index],
      [key]: value,
    };
    updatedVariations[variationIndex].suppliers =
      updatedSuppliers[variationIndex];
    setSuppliers(updatedSuppliers);
    updateProductForm('variations', updatedVariations);
  };

  const updateAllVariationTypes = (variantType: string) => {
    const updatedVariations =
      form?.map((variation) => ({
        ...variation,
        variantType: variantType,
      })) || [];
    setForm(updatedVariations);
    updateProductForm('variations', updatedVariations);
  };

  const onChange = <K extends keyof VariationsForm>(
    index: number,
    key: K,
    value: VariationsForm[K],
  ) => {
    const updatedVariations = form?.slice() || [];

    const existingVariationType = updatedVariations[index]?.variantType;

    updatedVariations[index] = {
      ...updatedVariations[index],
      [key]: value,
      variantType: existingVariationType,
    };
    setForm(updatedVariations);
    updateProductForm('variations', updatedVariations);
  };

  const updateFitment = (
    index: number,
    second: number,
    key: any,
    value: FitmentForm,
  ) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[index][second][key] = value;
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const addFitment = (index: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[index].push({ years: [], make: '', model: '' });
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const createNewFitment = (variationIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment.push([]);
    updatedVariations[variationIndex].fitment =
      newFitment[variationIndex] || [];
    setFitment(newFitment);
  };

  const deleteFitment = (variationIndex: number, rowIndex: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[variationIndex].splice(rowIndex, 1);
    updatedVariations[variationIndex].fitment = newFitment[variationIndex];
    setFitment(newFitment);
  };

  const removeFitment = (index: number) => {
    const updatedVariations = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment.splice(index, 1);
    updatedVariations[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const addVariation = () => {
    const updatedVariations = form?.slice() || [];
    updatedVariations.push({
      brand: brand ?? '',
    });
    const newSuppliers = suppliers.slice() || [];
    const newFitment = fitment?.slice() || [];
    if (!newFitment[updatedVariations.length - 1])
      newFitment[updatedVariations.length - 1] = [];
    if (!newSuppliers[updatedVariations.length - 1])
      newSuppliers[updatedVariations.length - 1] = [];
    updatedVariations[updatedVariations.length - 1].fitment =
      newFitment[updatedVariations.length - 1] || [];
    updatedVariations[updatedVariations.length - 1].suppliers =
      newSuppliers[updatedVariations.length - 1] || [];
    setFitment(newFitment);
    setForm(updatedVariations);
    setSuppliers(newSuppliers);

    // createNewFitment(updatedVariations.length);
  };

  const handleTest = (variationIndex: number) => {
    setOpenedCell((prevOpenedCell) =>
      prevOpenedCell === variationIndex ? null : variationIndex,
    );
  };

  const removeVariation = (index: number) => {
    const updatedVariations = form?.slice() || [];
    updatedVariations.splice(index, 1);
    removeFitment(index);
    setForm(updatedVariations);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    save({ variations: form, suppliers, fitment });
  };

  // const handleVarOptionsChange = (value: string) => {
  //   updateAllVariationTypes(value);
  //   setVarOptions(value);
  // };

  useEffect(() => {
    const skuFromLocalStorage = localStorage.getItem('selectedVar') || '';
    if (skuFromLocalStorage) {
      setFilters((prevFilters) => {
        const updatedFilters = prevFilters.map((filter) => {
          if (filter.keyName === 'mpn') {
            return {
              ...filter,
              defaultValue: skuFromLocalStorage,
            };
          }
          return filter;
        });
        return updatedFilters;
      });
    }
  }, []);

  const initialFilters: Filters[] = [
    { type: 'string', name: 'SKU', keyName: 'sku' },
  ];

  const combinedFilters = initialFilters.map((filter) => {
    const updatedFilter = filters.find((f) => f.keyName === filter.keyName);
    return updatedFilter ? updatedFilter : filter;
  });

  const handleVar = (newVariations: any) => {
    if (vars.length < newVariations.length) {
      updateVars(newVariations);
      // mutate();
    } else if (vars.length > newVariations.length) {
      updateVars(newVariations);
      // mutate();
    }
  };

  const handleOptions = (newOptions: any) => {
    if (JSON.stringify(varOptions) !== JSON.stringify(newOptions)) {
      setVarOptions(newOptions);
      // Optionally call mutate() here if needed
    }
  };

  const combinations = vars.flatMap((varItem) =>
    varOptions.map((option) => ({ varItem, option })),
  );
  useEffect(() => {
    const currentForm = form || [];
    const combinations = vars.flatMap((varItem) =>
      varOptions.map((option) => ({ varItem, option })),
    );
    const oneVar = vars.map((item) => {
      return item;
    });

    if (combinations.length > 0) {
      const newForm = combinations.map((combination) => ({
        brand: '',
        fitment: [],
        suppliers: [],
        sku: '',
        variantType: optionNameOne,
        variantTypeTwo: optionNameTwo,
        variation: combination.varItem,
        variationTwo: combination.option,
      }));

      if (
        currentForm.length !== newForm.length ||
        !currentForm.every((f, index) => f.sku === newForm[index].sku)
      ) {
        setForm(newForm);
      }
    } else if (vars.length > 0) {
      const newForm = vars.map((varItem) => ({
        brand: '',
        fitment: [],
        suppliers: [],
        sku: '',
        variantType: optionNameOne,
        variation: varItem,
      }));

      if (
        currentForm.length !== newForm.length ||
        !currentForm.every((f, index) => f.sku === newForm[index].sku)
      ) {
        setForm(newForm);
      }
    }
  }, [vars, varOptions]);

  const vPptions: VarOptionType[] = [{ label: '' }];
  const options: OptionType[] = [{ label: '' }];

  const inputRef = useRef(null);
  const varOneRef = useRef(null);
  const inputTwoRef = useRef(null);
  const varTwoRef = useRef(null);

  return (
    <>
      <Grid container xs={12} alignItems="flex-end">
        {!isViewMode ? (
          <Grid container xs={12} alignItems="center" sx={{ mb: 5 }}>
            <Grid item xs={3} sx={{ mr: 5 }}>
              {addVars === false ? (
                <Button
                  variant="outlined"
                  onClick={() => {
                    setAddVars(true);
                    setTimeout(() => inputRef.current?.focus(), 0);
                  }}
                >
                  Add Options
                </Button>
              ) : hasOptNameOne === false ? (
                <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                  <InputLabel>Option</InputLabel>
                  <OutlinedInput
                    type={'text'}
                    placeholder={'ex. Color'}
                    value={optionNameOne}
                    inputRef={inputRef}
                    onKeyPress={(e) => {
                      hanleKeyPress(e);
                      setTimeout(() => varOneRef.current?.focus(), 0);
                    }}
                    onChange={(e) => setOptionNameOne(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          // onClick={setHasOptOne(true)}
                          // onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Option"
                  />
                </FormControl>
              ) : (
                <Autocomplete
                  multiple
                  freeSolo
                  value={vars}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params) as VarOptionType[];

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        label: `Add "${params.inputValue}"`,
                      });
                    }
                    return filtered;
                  }}
                  options={unique}
                  id="tags-standard"
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.label;
                  }}
                  onChange={(e, value) => {
                    handleVar(value);
                  }}
                  renderInput={(params) => (
                    <TextField
                      onDoubleClick={() => setHasOptNameOne(false)}
                      {...params}
                      variant="outlined"
                      label={optionNameOne}
                      inputRef={varOneRef}
                      placeholder={optionNameOne}
                      style={{
                        marginRight: 10,
                        backgroundColor: primary.transparent.main,
                        width: '100%',
                      }}
                      // fullWidth
                    />
                  )}
                />
              )}
            </Grid>
            {vars.length > 0 && (
              <Grid
                item
                xs={5}
                // sx={{ margin: 20 }}
              >
                {addOptions === false && vars.length !== 0 ? (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setAddOptions(true);
                      setTimeout(() => inputTwoRef.current?.focus(), 0);
                    }}
                  >
                    Add Options
                  </Button>
                ) : hasOptNameTwo === false ? (
                  <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                    <InputLabel>Option</InputLabel>
                    <OutlinedInput
                      type={'text'}
                      placeholder={'ex. Color'}
                      value={optionNameTwo}
                      inputRef={inputTwoRef}
                      onKeyPress={(e) => {
                        handleKeyPress2(e);
                        setTimeout(() => varTwoRef.current?.focus(), 0);
                      }}
                      onChange={(e) => setOptionNameTwo(e.target.value)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            // onClick={setHasOptOne(true)}
                            // onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                    />
                  </FormControl>
                ) : (
                  <Autocomplete
                    multiple
                    freeSolo
                    value={varOptions}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params) as OptionType[];

                      if (params.inputValue !== '') {
                        filtered.push({
                          inputValue: params.inputValue,
                          label: `Add "${params.inputValue}"`,
                        });
                      }
                      return filtered;
                    }}
                    id="tags-standard"
                    options={unique}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      return option.label;
                    }}
                    onChange={(event, value) => {
                      handleOptions(value);
                    }}
                    renderInput={(params) => (
                      <TextField
                        onDoubleClick={() => setHasOptNameTwo(false)}
                        {...params}
                        variant="outlined"
                        label={optionNameTwo}
                        placeholder={optionNameTwo}
                        inputRef={varTwoRef}
                        style={{
                          backgroundColor: primary.transparent.main,
                          width: '50%',
                        }}
                        // fullWidth
                        // onKeyDown={(e) => {
                        //   handleKeyPress(e);
                        // }}
                      />
                    )}
                  />
                )}
              </Grid>
            )}
          </Grid>
        ) : null}
        {/* </Grid> */}
      </Grid>
      <form onSubmit={handleSubmit}>
        <Card className={classes.card}>
          <Grid item container xs={12} style={{ width: '100%' }}>
            {variations ? (
              <>
                <SearchBar
                  filters={combinedFilters}
                  selectedFilterValues={selectedFilterValues}
                  setSelectedFilterValues={setSelectedFilterValues}
                  setResetPagination={setResetPagination}
                />
                <Table
                  aria-label="collapsible table"
                  style={{ maxWidth: '100%' }}
                >
                  <TableHeader columns={columns} row={variations} />
                  <TableRows
                    columns={columns}
                    rows={variations}
                    mutate={mutateVariations}
                    page={page}
                    rowsPerPage={rowsPerPage}
                    openedCell={openedCell}
                    openedType={openedType}
                    setOpenedCell={setOpen}
                    imageCell={imageCell}
                    setImageCell={setImageCell}
                    suppliers={supps}
                  />
                  {Boolean(form?.length) &&
                    form
                      .filter(
                        (item) =>
                          !(item.sku && item.manufacturerNo && item.brand),
                      )
                      .map((variation, index) => (
                        <>
                          <AddVariation
                            key={index}
                            variationIndex={form.length - 1}
                            variation={variation}
                            columns={columns}
                            product={{ ...product }}
                            mutate={mutateVariations}
                            deleteRow={deleteFitment}
                            removeVariation={removeVariation}
                            varOptions={variations?.[0]?.variantType}
                            varOptionsTwo={variations?.[0]?.variantTypeTwo}
                          />
                        </>
                      ))}
                  {paramId && (
                    <Grid
                      item
                      container
                      justifyContent="flex-start"
                      style={{ marginBottom: 20, marginTop: 10 }}
                    >
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={addVariation}
                        // onClick={handleClick}
                        style={{ marginLeft: 10, minWidth: 'max-content' }}
                        disabled={
                          form.filter(
                            (item) =>
                              !(item.sku && item.manufacturerNo && item.brand),
                          ).length > 0
                        }
                      >
                        Add Variation
                      </Button>
                    </Grid>
                  )}
                  <TablePagination
                    count={variations.length}
                    rowsPerPage={rowsPerPage}
                    rowsPerPageOptions={[rowsPerPage]}
                    page={page}
                    onPageChange={(e, page) => {
                      setPage(page);
                      setOpenedCell(null);
                    }}
                  />
                </Table>
              </>
            ) : (
              <>
                {Boolean(form?.length) &&
                  form.map((variation, vIndex) => {
                    const cellNeedsOpen = paramId
                      ? vIndex === openedCell
                      : true;
                    return (
                      <>
                        <VariationBox
                          combinations={combinations}
                          vars={vars}
                          index={vIndex}
                          columns={columns}
                          variation={variation}
                          onChange={onChange}
                          fitment={fitment}
                          updateFitment={updateFitment}
                          makes={makes}
                          models={models}
                          deleteRow={deleteFitment}
                          removeVariation={removeVariation}
                          imageCell={imageCell}
                          setImageCell={setImageCell}
                          row={row}
                          mutate={mutateVariations}
                          cellIndex={cellIndex}
                          formLength={form.length}
                          varOptions={varOptions}
                          skuError={skuError}
                          mpnError={mpnError}
                          optionNameOne={optionNameOne}
                          optionNameTwo={optionNameTwo}
                          hasOptionNameOne={hasOptNameOne}
                          hasOptionNameTwo={hasOptNameTwo}
                        />
                        <Grid
                          container
                          xs={11}
                          style={{ justifyContent: 'flex-end' }}
                        >
                          {/* <Grid
                                                                                                                                                                                                                                                                                                                                  1                            container
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <Grid
                              item
                              // container
                              xs={2}
                              justifyContent="flex-start"
                              style={{
                                paddingBottom: 20,
                                paddingLeft: 35,
                                alignItems: 'center',
                              }}
                            >
                              <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => addFitment(vIndex)}
                                style={{
                                  whiteSpace: 'nowrap',
                                  minWidth: '120px',
                                }}
                              >
                                Add Fitment
                              </Button>
                            </Grid>
                            {suppliers.length === 0 ? (
                              <Grid
                                item
                                // container
                                xs={1}
                                justifyContent="flex-start"
                                style={{ paddingLeft: 35 }}
                              >
                                <Button
                                  variant="outlined"
                                  color="secondary"
                                  onClick={() => {
                                    handleTest(vIndex);
                                    addSuppliers(vIndex);
                                    console.log(vIndex, 'vIndex');
                                    console.log(variation, 'Opened');
                                  }}
                                  style={{
                                    whiteSpace: 'nowrap',
                                    minWidth: '120px',
                                  }}
                                >
                                  Add Supplier
                                </Button>
                              </Grid>
                            ) : (
                              <>
                                <Grid
                                  item
                                  container
                                  xs={1}
                                  justifyContent="flex-start"
                                  style={{
                                    paddingBottom: 20,
                                    paddingRight: 55,
                                    alignItems: 'center',
                                  }}
                                >
                                  <Button
                                    variant="outlined"
                                    color="secondary"
                                    onClick={() => {
                                      addSuppliers(vIndex);
                                    }}
                                    style={{
                                      whiteSpace: 'nowrap',
                                      minWidth: '120px',
                                    }}
                                  >
                                    Add Supplier
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  container
                                  xs={12}
                                  justifyContent="center"
                                  alignItems="flex-end"
                                  style={{
                                    maxWidth: '1733px',
                                    marginLeft: '10px',
                                    padding: 20,
                                    alignItems: 'flex-end',
                                  }}
                                >
                                  {cellNeedsOpen &&
                                    suppliers[vIndex].map(
                                      (
                                        supplier: ManufacturerSupplierForm,
                                        sIndex: number,
                                      ) => (
                                        <Grid
                                          item
                                          container
                                          xs={12}
                                          spacing={2}
                                          justifyContent="center"
                                          alignItems="flex-end"
                                          key={`supplier-${sIndex + 1}`}
                                          style={{ alignItems: 'flex-end' }}
                                        >
                                          <Grid
                                            item
                                            xs={11}
                                            md={4}
                                            lg={4}
                                            style={{ maxWidth: '560px' }}
                                          >
                                            <SupplierSelect
                                              value={supplier.supplierId ?? ''}
                                              onChange={(e) =>
                                                updateSuppliers(
                                                  sIndex,
                                                  'supplierId',
                                                  e.value,
                                                  vIndex,
                                                )
                                              }
                                              required
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={11}
                                            md={3}
                                            lg={3}
                                            style={{ width: '440px' }}
                                          >
                                            <TextField
                                              style={{ width: '100%' }}
                                              variant="outlined"
                                              label="Cost"
                                              value={supplier?.cost ?? ''}
                                              required
                                              type="number"
                                              onChange={(e) =>
                                                updateSuppliers(
                                                  sIndex,
                                                  'cost',
                                                  parseFloat(
                                                    e.currentTarget.value,
                                                  ),
                                                  vIndex,
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={9}
                                            md={3}
                                            lg={3}
                                            style={{ width: '440px' }}
                                          >
                                            <TextField
                                              style={{ width: '100%' }}
                                              variant="outlined"
                                              label="Shipping Cost"
                                              value={
                                                supplier?.shippingCost ?? ''
                                              }
                                              type="number"
                                              onChange={(e) =>
                                                updateSuppliers(
                                                  sIndex,
                                                  'shippingCost',
                                                  parseFloat(
                                                    e.currentTarget.value,
                                                  ),
                                                  vIndex,
                                                )
                                              }
                                            />
                                          </Grid>
                                          <Grid
                                            item
                                            xs={1}
                                            justifyContent="flex-start"
                                            alignItems="flex-end"
                                          >
                                            <CancelTwoToneIcon
                                              onClick={() =>
                                                removeSuppliers(sIndex, vIndex)
                                              }
                                              fontSize="large"
                                              style={{ color: '#FC0301' }}
                                            />
                                          </Grid>
                                        </Grid>
                                      ),
                                    )}
                                </Grid>
                                <Grid container xs={12}>
                                  <Divider />
                                </Grid>
                              </>
                            )}
                          </Grid> */}
                        </Grid>
                      </>
                    );
                  })}
              </>
            )}
            {!paramId && (
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                style={{
                  // marginBottom: 20,
                  // marginTop: 20,
                  alignItems: 'center',
                }}
              ></Grid>
            )}
          </Grid>
        </Card>
      </form>
      <Grid item xs={1}>
        {/* {!paramId && (
          <Grid item container xs={12}>
            <Button
              color="secondary"
              variant="contained"
              onClick={addVariation}
              style={{
                margin: 15,
                marginLeft: 5,
                marginBottom: 20,
                minWidth: 'max-content',
              }}
            >
              Add Variation
            </Button>
          </Grid>
        )} */}
      </Grid>
    </>
  );
}

export default VariationsTable;
