import { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import Api from '@oneAppCore/services/Api';
import CancelIcon from '@mui/icons-material/Cancel';
import FontAwesome from '@src/Components/common/FontAwesome';
import useSearch from '@oneAppCore/one-app/src/Components/common/containers/SearchView/hooks/useSearch';

type Props = {
  internalRestockId: number,
  mpn: string,
  purchased: number,
  received: number,
  mpnId: number,
}

function CancelItem({ mpnId, internalRestockId, mpn, purchased, received }: Props) {
  const [open, setOpen] = useState(false);
  const [clicked, setClicked] = useState(false);

  const { mutate } = useSearch();
  const { enqueueSnackbar } = useSnackbar();

  const closePurchaseOrder = async () => {
    setClicked(true);
    try {
      const updateObj = {
        internalRestockId,
        received,
        mpnId,
        purchased,
      };
      const updateRes = await Api.put(`/api/v1/manufacturer-suppliers-restock/cancelItem`, updateObj);
      if (updateRes.id) {
        mutate();
        enqueueSnackbar(`Canceled ${mpn}`, {
          variant: 'success',
        });
      };
    } catch (error) {
      enqueueSnackbar(`Error cancelling item`, {
        variant: 'error',
      });
    } finally {
      {
        handleClose();
      }
    }
  };

  const handleOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClicked(false);
  };

  return (
    <>
      {!open && (
        <IconButton
          onClick={async () => handleOpen()}
          disabled={purchased <= received}
        >
          <CancelIcon
            style={{ color: purchased <= received ? 'grey' : 'coral' }}
            
          />
        </IconButton>
      )}
      {open && (
        <Tooltip title={'loading'}>
          <span>
            <FontAwesome
              name="circle-notch"
              type="fas"
              form="fa"
              spin
            />
          </span>
        </Tooltip>
      )}
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm Cancel</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to cancel <b>{mpn}</b> (Qty: {purchased - received})?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            onClick={() => handleClose()}
          >
            No
          </Button>
          <Button type="submit" disabled={clicked} color="primary" onClick={() => closePurchaseOrder()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CancelItem;
