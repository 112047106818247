import BaseApi from '../BaseApi';

import type {
  ItemGroups,
  ItemGroupContents,
} from '@oneAppCore/types/itemGroupsTypes';

class ItemGroupsApi extends BaseApi {
  constructor() {
    super();
  }

  post(payload: ItemGroups) {
    return this.postBase<ItemGroups>(`/api/v1/item-groups`, payload);
  }

  addItemToGroup(id: number, payload: any) {
    return this.postBase<any>(`/api/v1/item-groups/${id}`, payload);
  }
}

export default ItemGroupsApi;
