import { useState, FormEvent, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi';
import shipStationApi from '@oneAppCore/services/ShipStationApi';
import VariationsApi from '@oneAppCore/services/VariationsApi';
import { objectFields, requiredAddressFields } from './constants';
import {
  Button,
  Dialog,
  Divider,
  InputAdornment,
  Grid,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { TextField, InlineEdit } from '@src/Components/common';
import { PrintLabelForm } from './types';
import ExportedOrdersApi from '@oneAppCore/services/ExportedOrdersApi';
import ItemImage from './partials/ItemImage';
import { SIGNATURE, PRIORITY_MAIL, USPS_CODE } from '@oneAppCore/constants/shipping';
import { DEMO_COMPANY_ID } from '@oneAppCore/constants/domainLogic';
import { primary } from '@src/Components/Theme/themes/constants';
import ShippingPackageApi from '@oneAppCore/services/ShippingPackageApi';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    label: {
      color: theme.palette.grey[700],
    },
    divider: {
      background: primary.main,
      marginBottom: 5,
      marginTop: 15,
    },
    biggerCheckBox: {
      transform: 'scale(1.45)'
    },
  }),
);

const errorsFromShipStation = {
  tooManyReq: 'Too Many Request',
};

function PrintLabelModal({
  open,
  printLabel,
  openHandler,
  closeHandler,
  defaultForm,
  carrierServiceCodes,
  loadCarrierRates,
  row,
  originalOrderItems,
  carrierData,
  me,
}: {
  open: boolean;
  printLabel: any;
  openHandler?: any;
  closeHandler: any;
  defaultForm: any;
  carrierServiceCodes?: any;
  loadCarrierRates?: any;
  row: any;
  originalOrderItems: any;
  carrierData: any;
  me: any;
}) {
  const { enqueueSnackbar } = useSnackbar();

  const classes = useStyles();
  const isBatch = Array.isArray(defaultForm);
  const [form, setForm] = useState<PrintLabelForm[]>(
    Array.isArray(defaultForm) ? defaultForm : [defaultForm],
  );
  const [orderItems, setOrderItems] = useState<any[]>(originalOrderItems);

  const defaultMinWeight = 0.51;
  const [isPrintButtonDisabled, setIsPrintButtonDisabled] = useState<boolean>(
    false,
  );

  const [defaultCost, setDefaultCost] = useState([]);
  const onChange = <K extends keyof PrintLabelForm>(
    key: K,
    value: PrintLabelForm[K],
    index: number,
  ) => {
    if (key === 'dimensions') {
      setDimensionsChanged(true);
    };
    const newForm = [...form];
    newForm[index] = {
      ...form[index],
      [key]: value,
    };
    setForm(newForm);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [dimensionsChanged, setDimensionsChanged] = useState(false);

  const updatePriceEstimates = async (formItem, index, itemIndex) => {
    setIsLoading(true);
    const promiseArray = [];

    if (dimensionsChanged) {
      let payload = {
        name: null,
        length: formItem.dimensions.length,
        height: formItem.dimensions.height,
        width: formItem.dimensions.width,
        weight: 0,
        type: 'box',
      };
      try {
        const data: any = await ShippingPackageApi.post(payload);
        if (data?.packageId) {
          let packageLoad = {
            mpnId: row.orderItems[itemIndex].mpnId,
            packageId: data?.packageId == 0 ? null : Number(data?.packageId),
          };
          try {
            await ShippingPackageApi.updatePackageId(packageLoad);
          } catch (e) {
            enqueueSnackbar('Error updating package id!', { variant: 'error' });
          };
        };
      } catch (error) {
        enqueueSnackbar('Error updating package id!', { variant: 'error' });
      };
    };

    carrierData.forEach((carrier) => {
      const { code, name } = carrier;
      const { shipFrom, shipTo, weight, dimensions } = formItem;
      const rateForm = {
        carrierCode: code,
        shipFrom,
        shipTo,
        weight,
        dimensions,
      };
      promiseArray.push(shipStationApi.getAllRates(rateForm));
    });
    const allCarriersResponse = await Promise.all(promiseArray);
    try {
      await shipStationApi.updateShipRates({
        orderId: row.id,
        allCarriersResponse
      });
    } catch (error) {
      console.log("error updating ship rates", error);
    };
    let serviceRates = [];
    let allRates = {};
    let errorInNumbers = false;
    allCarriersResponse.forEach((carrierResponse) => {
      if (
        carrierResponse?.minMaxRates?.max == 'NaN' ||
        carrierResponse?.status == 'fail'
      ) {
        errorInNumbers = true;
      } else {
        let filteredServices = [];
        if (carrierResponse.carrierCode == USPS_CODE && !formItem.priorityCheck) {
          carrierResponse.serviceRates.forEach((service) => {
            if (!service.serviceName.includes(PRIORITY_MAIL)) {
              filteredServices.push(service);
            };
          });
        } else {
          filteredServices = carrierResponse.serviceRates;
        }
        const sorted = filteredServices
          .map((rate) => (rate.shipmentCost + rate.otherCost).toFixed(2))
          .sort((a, b) => a - b);
        const strDex = 0;
        const endDex = sorted.length - 1;
        const minMaxRates = { min: sorted[strDex], max: sorted[endDex] };
        allRates[carrierResponse.carrierCode] = {
          minMaxRates: minMaxRates,
          serviceRates: carrierResponse.serviceRates,
        };
        if (carrierResponse.carrierCode === formItem.carrierCode) {
          serviceRates = filteredServices;
        }
        // minMaxRates[carrierResponse.carrierCode] = (carrierResponse.minMaxRates);
      }
    });

    if (errorInNumbers) {
      enqueueSnackbar(
        'Dimensions or weight is not accepted by ship station. Please enter some acceptable numbers.',
        {
          variant: 'error',
        },
      );
      checkForDemo(true);
      setIsLoading(false);
      return;
    }

    const newForm = [...form];

    const selectedOptions = getSelectedService(
      formItem.carrierCode,
      carrierData,
    );
    Object.keys(errorsFromShipStation).forEach((key) => {
      if (serviceRates === errorsFromShipStation[key]) {
        alert(
          `The label Service has returned the following error while pulling Shipping Services: ${errorsFromShipStation[key]}.\nPlease refresh the page, wait a little whileand try again. If the issue persists, please contact OneApp Support`,
        );
      }
    });
    const serviceRateArray = Array.isArray(serviceRates)
      ? serviceRates
      : [serviceRates];

    const editForm = {
      ...formItem,
      ['carrierOptions']: carrierData,
      ['serviceOptions']: serviceRateArray,
      ['packageOptions']: selectedOptions.packages,
      ['allRates']: allRates,
    };

    if (serviceRateArray.length > 0) {
      //selecting the first value as default
      const lowestIndex = findLowestCostIndex(serviceRateArray);
      editForm.serviceCode = serviceRateArray[lowestIndex].serviceCode;
      let packageCode = 'package';
      let defaultService = serviceRateArray[lowestIndex].serviceName;
      let nameParts = defaultService?.split(' - ');
      if (nameParts?.[1]) {
        const match = newForm?.[index]?.packageOptions?.find(
          (e) => e.name === nameParts[1].trim(),
        );
        if (match) {
          packageCode = match.code;
        }
      }
      editForm.selectedServiceName = defaultService;
      editForm.packageCode = packageCode;
    }

    newForm[index] = { ...editForm };
    setForm(newForm);
    checkForDemo(false);
    setIsLoading(false);
  };

  const checkWeightCondition = (orderItems) => {
    // Flatten the array of arrays into a single array of objects
    const items = orderItems.flat();

    return items.some((item) => {
      const totalWeight =
        (parseFloat(item?.weightInPounds || 0) * 16 +
          parseFloat(item?.weightInOunces || 0)) *
        (item?.quantity || 0);

      return totalWeight < defaultMinWeight;
    });
  };

  const onWeightServiceChange = (
    weightValue,
    totalWeightInOunces,
    totalWeightInPounds,
    listIndex,
  ) => {
    const serviceCode =
      weightValue >= 16 ? 'usps_priority_mail' : 'usps_first_class_mail';

    const newForm = [...form];
    const editForm = {
      ...form[listIndex],
      ['serviceCode']: serviceCode,
      ['weight']: { value: weightValue, units: 'ounces' },
      ['totalWeightInOunces']: totalWeightInOunces,
      ['totalWeightInPounds']: totalWeightInPounds,
    };
    newForm[listIndex] = { ...editForm };
    setForm(newForm);
  };

  const onItemChange = (index, key, value, listIndex) => {
    // * Cleaning up value to avoid errors
    if (value !== '0') {
      value = value.toString();
      while (value.charAt(0) == '0') value = value.substring(1); // Remove leading zeros
      value = value.toString().replace(/[^0-9.]/, ''); // Remove non-numeric values
    }

    const items = isBatch ? [...orderItems[listIndex]] : orderItems;
    const newItem = isBatch
      ? { ...orderItems[listIndex][index] }
      : { ...orderItems[index] };
    newItem[key] = value;
    items[index] = newItem;
    let newOrderItems = orderItems;
    if (isBatch) {
      newOrderItems[listIndex] = [...items];
    } else {
      newOrderItems = [...items];
    }
    setOrderItems(newOrderItems);
    const isWeightOk = checkWeightCondition(newOrderItems);
    checkForDemo(isWeightOk);

    let weight = 0;
    let totalWeightInOunces = 0;
    let totalWeightInPounds = 0;
    items.forEach((item) => {
      weight +=
        (parseFloat(item?.weightInPounds || 0) * 16 +
          parseFloat(item?.weightInOunces || 0)) *
        (item?.quantity || 0);
      totalWeightInOunces +=
        parseFloat(item?.weightInOunces || 0) * (item?.quantity || 0);
      totalWeightInPounds +=
        parseFloat(item?.weightInPounds || 0) * (item?.quantity || 0);
    });
    // weight = weight > 1 ? Math.round(weight) : Math.ceil(weight);
    // totalWeightInOunces =
    //   totalWeightInOunces > 1
    //     ? Math.round(totalWeightInOunces)
    //     : totalWeightInOunces
    //       ? Math.ceil(totalWeightInOunces)
    //       : 0;
    // totalWeightInPounds =
    //   totalWeightInPounds > 1
    //     ? Math.floor(totalWeightInPounds)
    //     : totalWeightInPounds
    //       ? Math.ceil(totalWeightInPounds)
    //       : 0;
    onWeightServiceChange(
      weight,
      totalWeightInOunces,
      totalWeightInPounds,
      listIndex,
    );
  };

  const saveLocation = async (value, data, listIndex) => {
    // TODO: reorganize all of this. It's a bit of a cluster fuck.
    const result: any = await ManufacturerSuppliersApi.putByManSup(
      data.manufacturerNo,
      data.supplierId,
      { location: value },
    );
    if (result?.length > 0) {
      enqueueSnackbar(`Updated location for SKU: '${data.sku}' to '${value}'`, {
        variant: 'success',
      });
    }
    onItemChange(data.index, 'location', value, listIndex);
  };

  const saveItemWeights = async () => {
    for (const item1 of orderItems) {
      const itemList = Array.isArray(item1) ? item1 : [item1];
      for (const item of itemList) {
        let weightToSend =
          parseFloat(item.weightInPounds) +
          parseFloat(item.weightInOunces) / 16;
        const result: any = await VariationsApi.put(item.variationId, {
          weight: weightToSend.toFixed(2),
        });
        if (result?.length === 0) {
          enqueueSnackbar(
            `Error updating weight for order-item with ID ${item.id}`,
            {
              variant: 'error',
            },
          );
        }
      }
    }
  };

  const handleSingleSubmit = async () => {
    const formItemsWithZeroWeight = form.filter(
      (form1) => form1.weight.value === 0,
    );
    if (!formItemsWithZeroWeight.length) {
      // Save weights for items
      try {
        await saveItemWeights();
      } catch (e) {
        enqueueSnackbar(`Error updating weights: ${e}`, {
          variant: 'error',
        });

        return;
      }

      await printLabel(form[0]);

      closeHandler();
    } else {
      enqueueSnackbar(
        'Weight cannot be zero. One or more item weights are set to zero.',
        {
          variant: 'error',
        },
      );
    }
  };

  const handleBatchSubmit = async () => {
    const formItemsWithZeroWeight = form.filter(
      (form1) => form1.weight.value === 0,
    );
    if (!formItemsWithZeroWeight.length) {
      // Save weights for items
      try {
        await saveItemWeights();
      } catch (e) {
        enqueueSnackbar(`Error updating weights: ${e}`, {
          variant: 'error',
        });

        return;
      }

      await printLabel(form);
      closeHandler();
    } else {
      enqueueSnackbar(
        'Weight cannot be zero. One or more item weights are set to zero.',
        {
          variant: 'error',
        },
      );
    }
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (me?.companyId === DEMO_COMPANY_ID) {
      alert('This is a test account, no printing of new labels!');
    } else {
      if (isBatch) {
        handleBatchSubmit();
      } else {
        handleSingleSubmit();
      }
      if (row.orderItems[0].supplierId === 8) {
        ExportedOrdersApi.pushOrderToShopify({ row });
      }
    }
  };

  const getSelectedService = (carrierCode, data) => {
    const selected = data.filter((element) => element.code === carrierCode);
    if (selected.length > 0) {
      return { services: selected[0].services, packages: selected[0].packages };
    }
    return { services: [], packages: [] };
  };

  const getCarriers = async () => {
    setIsLoading(true);
    checkForDemo(true);
    const newForm = [...form];
    let cheapestCarrier = 'stamps_com';
    let serviceRates = [];
    const promiseArray = [];

    carrierData?.forEach((carrier) => {
      const { code, name } = carrier;
      for (let index = 0; index < newForm.length; index++) {
        const element = newForm[index];
        const { shipFrom, shipTo, weight, dimensions } = element;
        const rateForm = {
          carrierCode: code,
          shipFrom,
          shipTo,
          weight,
          dimensions,
          index,
        };

        promiseArray.push(shipStationApi.getAllRates(rateForm));
      }
    });
    const allCarriersResponse = await Promise.all(promiseArray);

    for (let index = 0; index < newForm.length; index++) {
      const element = newForm[index];
      Object.keys(errorsFromShipStation).forEach((key) => {
        if (carrierData === errorsFromShipStation[key]) {
          alert(
            `The label Service has returned the following error while pulling Carriers: ${errorsFromShipStation[key]}.\nPlease refresh the page, wait a little whileand try again. If the issue persists, please contact OneApp Support`,
          );
        }
      });

      let allRates = {};
      let badGateWayError = false;
      let lowestCarrierCost = Number.POSITIVE_INFINITY;
      allCarriersResponse.forEach((carrierResponse) => {
        if (carrierResponse.status == 'fail') {
          badGateWayError = true;
        } else {
          if (index == carrierResponse.index) {
            let filteredServices = [];
            if (carrierResponse.carrierCode == USPS_CODE && !newForm[index].priorityCheck) {
              carrierResponse.serviceRates.forEach((service) => {
                if (!service.serviceName.includes(PRIORITY_MAIL)) {
                  filteredServices.push(service);
                };
              });
            } else {
              filteredServices = carrierResponse.serviceRates;
            }
            const sorted = filteredServices
              .map((rate) => (rate.shipmentCost + rate.otherCost).toFixed(2))
              .sort((a, b) => a - b);
            const strDex = 0;
            const endDex = sorted.length - 1;
            const minMaxRates = { min: sorted[strDex], max: sorted[endDex] };

            allRates[carrierResponse.carrierCode] = {
              minMaxRates: minMaxRates,
              serviceRates: carrierResponse.serviceRates,
            };
            if (Number(minMaxRates?.min) < lowestCarrierCost) {
              lowestCarrierCost = minMaxRates.min;
              serviceRates = filteredServices;
              cheapestCarrier = carrierResponse.carrierCode;
            }
            // if (carrierResponse.carrierCode === element.carrierCode) {
            //   serviceRates = carrierResponse.serviceRates;
            // }
          }
          // minMaxRates[carrierResponse.carrierCode] = (carrierResponse.minMaxRates);
        }
      });

      if (badGateWayError) {
        enqueueSnackbar(
          `The label service pulled a 502 bad gateway error. Please refresh the page wait a little while and try again. If the issue persists, please contact OneApp Support`,
          {
            variant: 'error',
          },
        );
        checkForDemo(true);
        setIsLoading(false);
        return;
      }

      // setCarrierNamesWithCosts(minMaxRates);

      // const selectedOptions = getSelectedService(cheapestCarrier, carrierData);
      Object.keys(errorsFromShipStation).forEach((key) => {
        if (serviceRates === errorsFromShipStation[key]) {
          alert(
            `The label Service has returned the following error while pulling Shipping Services: ${errorsFromShipStation[key]}.\nPlease refresh the page, wait a little whileand try again. If the issue persists, please contact OneApp Support`,
          );
        }
      });
      const serviceRateArray = Array.isArray(serviceRates)
        ? serviceRates
        : [serviceRates];

      const lowestIndex = findLowestCostIndex(serviceRateArray);
      const selectedService = serviceRateArray[lowestIndex];
      const profit =
        newForm[index]?.orderCosts?.['itemPrice'] -
        Math.floor(newForm[index]?.orderCosts?.['itemPrice'] * 0.12) -
        newForm[index]?.orderCosts?.['subTotal'] -
        (selectedService?.shipmentCost + selectedService?.otherCost) * 100;
      let defaultService = serviceRateArray?.[lowestIndex]?.serviceName;
      let packageCode = 'package';
      let nameParts = defaultService?.split(' - ');
      if (nameParts?.[1]) {
        // name
        const match = newForm[index].packageOptions.find(
          (e) => e.name === nameParts[1].trim(),
        );
        if (match) {
          packageCode = match.code;
        }
      }
      const editForm = {
        ...newForm[index],
        ['carrierCode']: cheapestCarrier,
        ['carrierOptions']: carrierData,
        ['serviceOptions']: serviceRateArray,
        // ['packageOptions']: selectedOptions.packages,
        ['serviceCode']: serviceRateArray?.[lowestIndex]?.serviceCode,
        ['selectedServiceName']: defaultService,
        ['packageCode']: packageCode,
        ['allRates']: allRates,
        profit: profit,
      };
      newForm[index] = { ...editForm };
    }
    setForm(newForm);
    setIsLoading(false);
    checkForDemo(false);
  };

  const getDefaultRate = async () => {
    setIsLoading(true);
    const newForm = [...form];
    const defaultResponse = await shipStationApi.getRates(newForm);
    setDefaultCost(defaultResponse);
  };

  const findLowestCostIndex = (arr) => {
    let lowestCost = Number.POSITIVE_INFINITY;
    let lowestIndex = 0;

    for (let i = 0; i < arr.length; i++) {
      const element = arr[i];
      const totalCost = element.otherCost + element.shipmentCost;

      if (totalCost < lowestCost) {
        lowestCost = totalCost;
        lowestIndex = i;
      }
    }

    return lowestIndex;
  };

  const checkForDemo = (input = false) => {
    if (!me) {
      setIsPrintButtonDisabled(true);
    } else {
      if (me.companyId === DEMO_COMPANY_ID) {
        setIsPrintButtonDisabled(true);
      } else {
        setIsPrintButtonDisabled(input);
      }
    }
  };

  useEffect(() => {
    // getDefaultRate();
    if (form?.[0]?.serviceOptions.length == 0) {
      getCarriers();
    }
  }, []);

  useEffect(() => {
    let weightCheck = false;
    form?.map((formItem) => {
      if (formItem?.weight?.value < defaultMinWeight) {
        weightCheck = true;
      };
    });
    if (weightCheck) {
      setIsPrintButtonDisabled(true);
    };
  }, [form]);

  const handleCarrierChange = async (value, index) => {
    setIsLoading(true);
    // checkForDemo(true);
    const newForm = [...form];
    let options = getSelectedService(value, carrierData);
    let editForm = {
      ...form[index],
      ['carrierCode']: value,
      ['packageOptions']: options.packages,
      ['packageCode']: '',
      ['serviceCode']: '',
      ['selectedServiceName']: '',
    };
    // let { serviceRates } = await shipStationApi.getAllRates(editForm);

    let { serviceRates } = editForm.allRates[value];

    Object.keys(errorsFromShipStation).forEach((key) => {
      if (serviceRates === errorsFromShipStation[key]) {
        alert(
          `The label Service has returned the following error while pulling Shipping Services: ${errorsFromShipStation[key]}.\nPlease refresh the page, wait a little whileand try again. If the issue persists, please contact OneApp Support`,
        );
      }
    });
    const serviceRateArray = Array.isArray(serviceRates)
      ? serviceRates
      : [serviceRates];

    let filteredServices = [];
    if (!editForm.priorityCheck && value == USPS_CODE) {
      serviceRateArray.forEach((service) => {
        if (!service.serviceName.includes(PRIORITY_MAIL)) {
          filteredServices.push(service);
        }
      });
    } else {
      filteredServices = serviceRateArray;
    };
    editForm.serviceOptions = filteredServices;
    let lowestIndex = 0;
    if (filteredServices.length > 0) {
      //selecting the first value as default
      lowestIndex = findLowestCostIndex(filteredServices);
      editForm.serviceCode = filteredServices[lowestIndex].serviceCode;
      let packageCode = 'package';
      let defaultService = filteredServices[lowestIndex].serviceName;
      let nameParts = defaultService?.split(' - ');
      if (nameParts?.[1]) {
        // name
        const match = newForm?.[index]?.packageOptions?.find(
          (e) => e.name === nameParts?.[1]?.trim(),
        );
        if (match) {
          packageCode = match.code;
        }
      }
      editForm.selectedServiceName = defaultService;
      editForm.packageCode = packageCode;
    }
    const selectedService = filteredServices[lowestIndex];
    editForm.profit =
      newForm[index]?.orderCosts?.['itemPrice'] -
      Math.floor(newForm[index]?.orderCosts?.['itemPrice'] * 0.12) -
      newForm[index]?.orderCosts?.['subTotal'] -
      (selectedService?.shipmentCost + selectedService?.otherCost) * 100;
    // checkForDemo(false);
    newForm[index] = { ...editForm };
    setForm(newForm);
    setIsLoading(false);
  };

  const handleServiceChange = async (value, index) => {
    setIsLoading(true);
    // checkForDemo(true);
    const newForm = [...form];
    let packageCode = 'package';
    const selectedService = newForm[index].serviceOptions.find(
      (service) => service.serviceName == value,
    );

    let nameParts = value.split(' - ');
    if (nameParts[1]) {
      const match = newForm[index].packageOptions.find(
        (e) => e.name === nameParts[1].trim(),
      );
      if (match) {
        packageCode = match.code;
      }
    }

    const profit =
      newForm[index]?.orderCosts?.['itemPrice'] -
      Math.floor(newForm[index]?.orderCosts?.['itemPrice'] * 0.12) -
      newForm[index]?.orderCosts?.['subTotal'] -
      (selectedService?.shipmentCost + selectedService?.otherCost) * 100;

    let editForm = {
      ...form[index],
      ['packageCode']: packageCode,
      ['serviceCode']: selectedService.serviceCode,
      ['selectedServiceName']: value,
      profit,
    };
    newForm[index] = { ...editForm };
    setForm(newForm);
    setIsLoading(false);
  };

  const handleSignatureChange = async (value, index) => {
    setIsLoading(true);
    const newForm = [...form];
    if (value) {
      newForm[index].confirmation = value;
    } else {
      delete newForm[index].confirmation;
    }
    setForm(newForm);
    setIsLoading(false);
  };

  // TODO: function currently only considers USPS. Can be made dynamic to consider other carriers.
  const handlePriorityChange = async (value, index) => {
    setIsLoading(true);
    const newForm = [...form];
    const editForm = { ...newForm[index] };
    editForm.priorityCheck = value;
    // if (editForm.carrierCode == USPS_CODE) {
    let { serviceRates } = editForm.allRates[USPS_CODE];
    const serviceRateArray = Array.isArray(serviceRates)
      ? serviceRates
      : [serviceRates];
    let filteredServices = [];
    serviceRateArray.forEach((service) => {
      if (!service.serviceName.includes(PRIORITY_MAIL)) {
        filteredServices.push(service);
      }
    });
    let sorted = [];
    if (!value) {
      sorted = filteredServices
        .map((rate) => (rate.shipmentCost + rate.otherCost).toFixed(2))
        .sort((a, b) => a - b);
    } else {
      sorted = serviceRateArray
        .map((rate) => (rate.shipmentCost + rate.otherCost).toFixed(2))
        .sort((a, b) => a - b);
    }
    const strDex = 0;
    const endDex = sorted.length - 1;
    editForm.allRates[USPS_CODE].minMaxRates = { min: sorted[strDex], max: sorted[endDex] };
    if (editForm.carrierCode == USPS_CODE) {
      if (!value) {
        editForm.serviceOptions = filteredServices;
      } else {
        editForm.serviceOptions = serviceRateArray;
      }
      let lowestIndex = 0;
      if (filteredServices.length > 0) {
        //selecting the first value as default
        lowestIndex = findLowestCostIndex(filteredServices);
        editForm.serviceCode = filteredServices[lowestIndex].serviceCode;
        let packageCode = 'package';
        let defaultService = filteredServices[lowestIndex].serviceName;
        let nameParts = defaultService?.split(' - ');
        if (nameParts?.[1]) {
          // name
          const match = newForm?.[index]?.packageOptions?.find(
            (e) => e.name === nameParts?.[1]?.trim(),
          );
          if (match) {
            packageCode = match.code;
          }
        }
        editForm.selectedServiceName = defaultService;
        editForm.packageCode = packageCode;
      }
      const selectedService = filteredServices[lowestIndex];
      editForm.profit =
        newForm[index]?.orderCosts?.['itemPrice'] -
        Math.floor(newForm[index]?.orderCosts?.['itemPrice'] * 0.12) -
        newForm[index]?.orderCosts?.['subTotal'] -
        (selectedService?.shipmentCost + selectedService?.otherCost) * 100;
    };

    // checkForDemo(false);
    newForm[index] = { ...editForm };
    setForm(newForm);
    // };
    setIsLoading(false);
  };

  const handlePackageChange = async (value, index) => {
    const newForm = [...form];
    let nameParts = value.split(' - ');
    let packageCode = '';
    if (nameParts[1]) {
      const match = newForm[index].packageOptions.find(
        (e) => e.name === nameParts[1].trim(),
      );
      if (match) {
        packageCode = match.code;
      } else {
        packageCode = 'package';
      }
    }
    const editForm = {
      ...newForm[index],
      ['packageCode']: packageCode,
      ['selectedServiceName']: value,
    };
    newForm[index] = { ...editForm };
    setForm(newForm);
  };


  // TODO: This can definitely be done better.
  // * There's a lot of repeated code here. Talk to Tanner and see what he suggests.
  // * Potentially first loop through form keys, than through each of those keys keys.

  // TODO: Work on carrier, supplier, package management. They should be dynamic, though it's hard to do with shipstation's current api.
  const batchPrint = 'Batch Print Labels';
  const regularPrint = 'Print Shipment Label';
  const printLabelTitle =
    isBatch && form.length > 1 ? batchPrint : regularPrint;

  const orderItemsRender = (orderItem, index, formItem, formIndex) =>
    <>
      <Grid
        key={index}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        item
        container
        xs={12}
      >
        <Grid item xs={12} md={2} lg={2}>
          {<ItemImage orderItem={orderItem} />}
        </Grid>
        <Grid item container xs={12} md={3} lg={3}>
          <Grid item>
            <Typography variant="subtitle1">
              {orderItem.name
                ? `Name: ${orderItem.name}`
                : ''}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="subtitle1">
              SKU: {orderItem.sku}
            </Typography>
          </Grid>
          <Grid item container>
            <Typography
              style={{ marginRight: 10 }}
              variant="subtitle1"
            >
              {'Location:'}
            </Typography>
            <InlineEdit
              label=""
              value={orderItem.location}
              saveHandler={(value, data) => {
                saveLocation(
                  value,
                  data,
                  formIndex,
                );
              }}
              data={{ ...orderItem, index: index }}
            />
          </Grid>
        </Grid>
        <Grid
          direction="row"
          item
          container
          xs={12}
          md={4}
          lg={4}
        >
          <Grid
            item
            container
            alignItems="center"
            xs={12}
          >
            {Object.keys(formItem.dimensions).map(
              (key, index) => {
                if (key == 'units') return;
                return (
                  <Grid
                    key={index}
                    item
                    style={{ paddingLeft: 10 }}
                  >
                    <TextField
                      style={{
                        width: 100,
                      }}
                      label={key}
                      disabled={isLoading}
                      value={
                        formItem.dimensions[key] ??
                        ''
                      }
                      error={
                        formItem.dimensions[key] ===
                        0
                      }
                      required
                      onChange={(e) =>
                        onChange(
                          'dimensions',
                          {
                            ...formItem.dimensions,
                            [key]: e.currentTarget
                              .value
                              ? parseInt(
                                e.currentTarget
                                  .value,
                              )
                              : 0,
                          },
                          formIndex,
                        )
                      }
                      InputProps={{
                        inputMode: 'numeric',
                        endAdornment: (
                          <InputAdornment position="end">
                            In
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                );
              },
            )}
          </Grid>
          <Grid
            direction="row"
            item
            // xs={4}
            style={{
              paddingLeft: 10,
              paddingTop: 15,
            }}
          >
            <TextField
              style={{ width: 100 }}
              type="number"
              label={'lbs (Single Item)'}
              value={orderItem.weightInPounds ?? ''}
              disabled={isLoading}
              error={
                // orderItem.weightInPounds === 0 &&
                // orderItem.weightInOunces === 0
                // parseFloat(`${formItem?.weight?.value}`) < 0.51
                ((parseFloat(orderItem?.weightInPounds || 0) * 16) + parseFloat(orderItem?.weightInOunces || 0)) < defaultMinWeight
              }
              required
              onChange={(e) => {
                let value = e.currentTarget.value;
                onItemChange(
                  index,
                  'weightInPounds',
                  value,
                  formIndex,
                );
              }}
              InputProps={{
                inputMode: 'numeric',
                endAdornment: (
                  <InputAdornment position="end">
                    <div>lbs</div>
                  </InputAdornment>
                ),
              }}
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Qty({orderItem.quantity}) =
              <Typography
                variant="subtitle2"
                style={{
                  fontSize: 20,
                  marginRight: 10,
                  color: primary.main,
                }}
              >
                {isNaN(orderItem.weightInPounds) ? (
                  <>0.</>
                ) : (
                  <>
                    {(
                      orderItem.weightInPounds *
                      orderItem.quantity
                    ).toFixed(2)}
                  </>
                )}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            // xs={4}
            style={{
              paddingLeft: 10,
              paddingTop: 15,
            }}
          >
            {/* <TextField
                          label={'oz (Single Item)'}
                          type="number"
                          disabled={isLoading}
                          // defaultValue="0.51"
                          // inputProps={{ min: '0.51' }}
                          style={{ width: 100 }}
                          // value={orderItem.weightInOunces}
                          value={textFieldValue}
                          error={
                            orderItem.weightInPounds === 0 &&
                            orderItem.weightInOunces === 0
                          }
                          required
                          // onChange={(e) => {
                          //   let value = e.currentTarget.value;
                          //   onItemChange(
                          //     index,
                          //     'weightInOunces',
                          //     value,
                          //     formIndex,
                          //   );
                          onChange={(e) => {
                            let value = e.currentTarget.value;
                            // Check if the entered value is greater than or equal to 0.51
                            if (parseFloat(value) >= 0.51 || value === '') {
                              setTextFieldValue(value);
                              onItemChange(index, 'weightInOunces', value, formIndex);
                            }
                          }}
                          // }}
                          InputProps={{
                            inputMode: 'numeric',
                            endAdornment: (
                              <InputAdornment position="end">
                                <div>oz</div>
                              </InputAdornment>
                            ),
                          }}
                        /> */}
            <TextField
              label={'oz (Single Item)'}
              defaultValue={defaultMinWeight}
              type="number"
              disabled={isLoading}
              style={{ width: 100 }}
              value={orderItem.weightInOunces}
              error={
                // orderItem.weightInPounds === 0 &&
                // parseFloat(
                //   orderItem.weightInOunces,
                // ) < 0.51
                // parseFloat(`${formItem?.weight?.value}`) < 0.51
                ((parseFloat(orderItem?.weightInPounds || 0) * 16) + parseFloat(orderItem?.weightInOunces || 0)) < defaultMinWeight
              }
              required
              onChange={(e) => {
                let value = e.currentTarget.value;
                onItemChange(
                  index,
                  'weightInOunces',
                  value,
                  formIndex,
                );
              }}
              InputProps={{
                inputMode: 'numeric',
                endAdornment: (
                  <InputAdornment position="end">
                    <div>oz</div>
                  </InputAdornment>
                ),
              }}
              inputProps={{ min: '0.51' }} // Set the minimum allowed value to 0.51
            />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              Qty({orderItem.quantity}) =
              <Typography
                variant="subtitle2"
                style={{
                  fontSize: 20,
                  marginRight: 10,
                  color: primary.main,
                }}
              >
                {((parseFloat(orderItem?.weightInPounds || 0) * 16) + parseFloat(orderItem?.weightInOunces || 0)) < defaultMinWeight ? (
                  <span
                    style={{ color: 'crimson' }}
                  >
                    Min {defaultMinWeight} oz.
                  </span>
                ) : isNaN(
                  orderItem.weightInOunces,
                ) ? (
                  <>0.</>
                ) : (
                  <>
                    {(
                      orderItem.weightInOunces *
                      orderItem.quantity
                    ).toFixed(2)}
                  </>
                )}

                {/* // {isNaN(orderItem.weightInOunces) ? (
                            //   <>0.</>
                            // ) : (
                            //   <>
                            //     {(
                            //       orderItem.weightInOunces *
                            //       orderItem.quantity
                            //     ).toFixed(2)}
                            //   </>
                            // )} */}
              </Typography>
            </div>
          </Grid>
          <Grid
            item
            // xs={4}
            style={{
              paddingLeft: 10,
              // paddingTop: 15,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {((parseFloat(orderItem?.weightInPounds || 0) * 16) + parseFloat(orderItem?.weightInOunces || 0)) < defaultMinWeight ? null : (
              <Button
                size="small"
                variant="outlined"
                color="primary"
                disabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  updatePriceEstimates(
                    formItem,
                    formIndex,
                    index
                  );
                }}
              >
                Get Estimates
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          container
          justifyContent="flex-start"
          xs={12}
          md={3}
          lg={3}
          key={`simple-field-carrierr`}
        >

          <Grid
            item
            xs={12}
            container
            direction="row"
            justifyContent="space-evenly"
          >
            {formItem?.profit > 0 ? (
              <Grid
                item
                xs={12}
                container
                direction="row"
                justifyContent="space-evenly"
                spacing={1}
                className={classes.formControl}
                style={{
                  border: '#38692b 1px solid', // todo: replace with color utils
                  borderRadius: 5,
                  padding: 5,
                  backgroundColor: '#e4f8ed',
                }}
              >
                <Grid item xs={3}>
                  <Typography>
                    <b>Fee:</b> $
                    {(
                      (formItem?.orderCosts?.[
                        'itemPrice'
                      ] /
                        100) *
                      0.12
                    )?.toFixed(2) || ''}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    <b>Cost:</b> $
                    {(
                      formItem?.orderCosts?.[
                      'subTotal'
                      ] / 100
                    )?.toFixed(2) || ''}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    <b>Profit:</b>
                    <span
                      style={{
                        color:
                          formItem?.profit > 0
                            ? 'green'
                            : 'red',
                      }}
                    >
                      {' '}
                      {formItem?.profit > 0
                        ? ''
                        : '-'}{' '}
                      $
                      {(
                        Math.abs(formItem?.profit) /
                        100
                      )?.toFixed(2) || ''}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <Grid
                item
                xs={12}
                container
                direction="row"
                spacing={1}
                className={classes.formControl}
                style={{
                  border: '#faeaea 1px solid',
                  borderRadius: 5,
                  padding: 5,
                  backgroundColor: '#ffb4b5',
                }}
              >
                <Grid item>
                  <Typography>
                    <b>Fee:</b> $
                    {(
                      (formItem?.orderCosts?.[
                        'itemPrice'
                      ] /
                        100) *
                      0.12
                    )?.toFixed(2) || ''}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Cost:</b> $
                    {(
                      formItem?.orderCosts?.[
                      'subTotal'
                      ] / 100
                    )?.toFixed(2) || ''}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    <b>Profit:</b>
                    <span
                      style={{
                        color:
                          formItem?.profit > 0
                            ? 'green'
                            : 'red',
                      }}
                    >
                      {' '}
                      {formItem?.profit > 0
                        ? ''
                        : '-'}{' '}
                      $
                      {(
                        Math.abs(formItem?.profit) /
                        100
                      )?.toFixed(2) || ''}
                    </span>
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container item xs={12}>
            <FormControl
              required
              fullWidth
              className={classes.formControl}
              variant="outlined"
              size="small"
            >
              <Typography
                variant="caption"
                component="span"
                className={classes.label}
              >
                Carrier*
              </Typography>
              <Select
                labelId="carrier-select-label"
                id="carrier-select"
                value={formItem.carrierCode}
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  // getContentAnchorEl: () => null,
                }}
                disabled={
                  formItem.carrierOptions.length ==
                  0 || isLoading
                }
                onChange={(e) =>
                  handleCarrierChange(
                    e.target.value,
                    formIndex,
                  )
                }
              >
                {formItem.carrierOptions.length ==
                  0 && (
                    <MenuItem
                      style={{ zIndex: 13 }}
                      value={formItem.carrierCode}
                    >
                      {formItem.weight.value >= 128
                        ? 'UPS'
                        : 'USPS'}
                    </MenuItem>
                  )}
                {formItem.carrierOptions.length > 0 &&
                  formItem.carrierOptions.map(
                    (carrier, cIndex) => (
                      <MenuItem
                        style={{ zIndex: 13 }}
                        value={carrier.code}
                        key={`${carrier.code}-${cIndex}`}
                      >
                        {formItem.allRates[
                          carrier.code
                        ]
                          ? `${carrier.name} (${formItem.allRates[
                            carrier.code
                          ].minMaxRates.min
                          } - ${formItem.allRates[
                            carrier.code
                          ].minMaxRates.max
                          })`
                          : carrier.name}
                      </MenuItem>
                    ),
                  )}
              </Select>
            </FormControl>
          </Grid>

          <Grid container item xs={12}>
            <FormControl
              fullWidth
              className={classes.formControl}
              variant="outlined"
              size="small"
            >
              <Typography
                variant="caption"
                component="span"
                className={classes.label}
              >
                Service*
              </Typography>
              <Select
                labelId="service-select-label"
                id="service-select"
                value={formItem.selectedServiceName}
                defaultValue={
                  formItem.serviceOptions[0]
                }
                disabled={
                  formItem.serviceOptions.length ===
                  0 || isLoading
                }
                onChange={(e) =>
                  handleServiceChange(
                    e.target.value,
                    formIndex,
                  )
                }
              >
                {formItem.serviceOptions.length ===
                  0 && (
                    <MenuItem
                      value={
                        formItem.selectedServiceName
                      }
                    >
                      {defaultCost.length > 0 &&
                        `$ ${(
                          defaultCost[formIndex]
                            .shipmentCost +
                          defaultCost[formIndex]
                            .otherCost
                        ).toFixed(2)} - `}
                      {formItem.selectedServiceName}
                    </MenuItem>
                  )}
                {formItem.serviceOptions.length > 0 &&
                  formItem.serviceOptions
                    .sort(
                      (a, b) =>
                        a.shipmentCost +
                        a.otherCost -
                        (b.shipmentCost +
                          b.otherCost),
                    )
                    .map((service, sIndex) => (
                      <MenuItem
                        value={service.serviceName}
                        key={`${service.serviceName}-${sIndex}`}
                      >
                        $
                        {(
                          service.shipmentCost +
                          service.otherCost
                        ).toFixed(2)}{' '}
                        - {service.serviceName} -{' '}
                        {new Date(
                          service.deliveryDate,
                        )
                          .toLocaleString('default', {
                            month: 'long',
                          })
                          .slice(0, 3)}{' '}
                        {new Date(
                          service.deliveryDate,
                        ).getDate()}
                      </MenuItem>
                    ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid container item xs={6}>
            <FormControlLabel
              label={'Signature'}
              labelPlacement="start"
              control={
                <Checkbox
                  checked={formItem.confirmation === SIGNATURE}
                  color='secondary'
                  disabled={
                    formItem.serviceOptions.length ===
                    0 || isLoading
                  }
                  onChange={(e) => {
                    const value = e.target.checked ? SIGNATURE : null
                    handleSignatureChange(value, formIndex)
                  }}
                  className={classes.biggerCheckBox}
                />
              }
            />
            {/* //todo maybe add insurance options to this modal
                                        */}
          </Grid>
          <Grid container item xs={6}>
            <FormControlLabel
              label={'Priority'}
              labelPlacement="start"
              control={
                <Checkbox
                  checked={formItem.priorityCheck ? formItem.priorityCheck : false}
                  color='secondary'
                  disabled={
                    formItem.serviceOptions.length ===
                    0 || isLoading
                  }
                  onChange={(e) => {
                    const value = e.target.checked;
                    handlePriorityChange(value, formIndex);
                  }}
                  className={classes.biggerCheckBox}
                />
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </>;

  const formItemRender = (formItem, formIndex) => (<Grid item container xs={12} key={`label-item-${formIndex}`}>
    <Grid item container spacing={1} xs={12}>
      <Grid item xs={4} key={`simple-field-services`}></Grid>
    </Grid>
    <Grid item container direction="row" alignItems="center">
      <Grid item container spacing={3}>
        <Grid item container spacing={3}>
          {(isBatch ? orderItems[formIndex] : orderItems)?.map(
            (orderItem, index) => orderItemsRender(orderItem, index, formItem, formIndex),
          )}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Divider className={classes.divider} />
      </Grid>
    </Grid>
    {objectFields.map((field) => {
      return (
        <Grid
          item
          container
          spacing={2}
          style={{ display: 'none' }}
          key={`object-field-${field.key}`}
        >
          <Grid item container>
            <Typography variant="h6">{field.label}:</Typography>
          </Grid>
          <Grid item container spacing={2}>
            {Object.keys(formItem[field.key]).map(
              (key, index) => {
                return (
                  <Grid key={index} item xs={12} md={6} lg={4}>
                    <TextField
                      label={key}
                      value={formItem[field.key][key] ?? ''}
                      required={requiredAddressFields.includes(
                        key,
                      )}
                      onChange={(e) =>
                        onChange(
                          field.key as keyof PrintLabelForm,
                          {
                            ...formItem[field.key],
                            [key]: e.currentTarget.value,
                          },
                          formIndex,
                        )
                      }
                    />
                  </Grid>
                );
              },
            )}
          </Grid>
        </Grid>
      );
    })}
  </Grid>
  );

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="lg"
      style={{ padding: '20px', zIndex: 12 }}
      onClose={closeHandler}
    >
      <form
        onSubmit={handleSubmit}
        style={{ width: '100%', padding: '20px', overflowX: 'hidden' }}
      >
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item container>
              <Typography color="primary" variant="h6">
                {printLabelTitle}
              </Typography>
            </Grid>
            <Grid
              item
              container
              style={{
                maxHeight: '600px',
                overflowY: 'auto',
                overflowX: 'clip',
              }}
              spacing={2}
            >
              {form?.map((formItem, formIndex) => formItemRender(formItem, formIndex))}
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              spacing={2}
            >
              <Grid item>
                <Typography variant="caption">
                  Submitting will save the weight for each orderItem, and print
                  a shipping label.
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => closeHandler()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isPrintButtonDisabled}
                  onClick={(e) => {
                    checkForDemo(true);
                    handleSubmit(e);
                  }}
                >
                  Print
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Dialog>
  );
}

export default PrintLabelModal;
