import BaseApi from './BaseApi';

import type {
  SupplierType,
  ChannelType,
  TrackNumberType,
  SupplierCountType,
  SupplierTrackNumberTypes,
} from 'types/supplierTypes';

class InventoryApi extends BaseApi {
  constructor() {
    super();
  }

  getSuppliers = async () => {
    return this.getBase<SupplierType>(`/api/v1/suppliers/`);
  };
  getAllChannels = async () => {
    return this.getBase<any>('/api/v1/store-channels/inventory');
  };
  runInventory = async () => {
    return this.postBase('/api/v1/inventory/run');
  };
  getLastRun = async () => {
    return this.getBase('/api/v1/inventory/run');
  };
  getMiniReports = async () => {
    return this.getBase<TrackNumberType>('/api/v1/inventory/miniReports');
  };
  getChannelInv = async () => {
    return this.getBase<any>('/api/v1/inventory/channels');
  };
  getSupplierInv = async () => {
    return this.getBase<SupplierCountType>('/api/v1/inventory/suppliers');
  };
  getSuppInvCounts = async () => {
    return this.getBase<SupplierTrackNumberTypes>(
      '/api/v1/inventory/suppliers/tracking',
    );
  };
  getChannelInvCounts = async () => {
    return this.getBase<any>('/api/v1/inventory/channels/tracking');
  };
  setItemOOS = async (itemId) => {
    return this.postBase<any>(`/api/v1/inventory/item/nostock/${itemId}`);
  };
  getInvHistOneItem = async (itemId: number) => {
    return this.getBase<any>(`/api/v1/inventory/scanhist/${itemId}`);
  };
}

export default new InventoryApi();
