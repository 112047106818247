import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import { colorTheme } from '@oneAppCore/constants/suppliers';

import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import BarChart from '@src/Components/common/charts/BarChart';
import dateUtils from '@oneAppCore/utils/dates';

import ReportApi from '@oneAppCore/services/ReportApi';
import onlyUnique from '@oneAppCore/server/utils/arrayUnique';

import { Props } from '../types';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  width: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  height: {},
}));

function InventoryQuantityChart({ unitsBack, unitType }: Props) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const theme = createTheme(colorTheme);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      setData([]);

      const response = await ReportApi.get('INVENTORY_QUANTITY', {
        newerThan,
        page: 1,
        limit: 1000000,
      });
      const obj = {};
      const rows = response.rows.reduce((acc, row) => {
        const keyName = dayjs(row.createdAt).format('MM/DD/YYYY');
        return {
          ...acc,
          [keyName]: acc[keyName] ? acc[keyName] + row.quantity : row.quantity,
        };
      }, {});
      const labelRows = Object.keys(rows)
        // .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: rows[key],
          }),
          {},
        );

      const orderedRows = [];
      response.rows.forEach((row) => {
        if (!obj[row['Name']]) obj[row['Name']] = [];
        obj[row['Name']].push(row);
      });
      Object.keys(obj).forEach((objKey) => {
        const rows = obj[objKey].reduce((acc, row) => {
          const keyName = dayjs(row.createdAt).format('MM/DD/YYYY');
          return {
            ...acc,
            [keyName]: row.quantity,
          };
        }, {});
        let arrayOfOrderedRows = [];
        Object.keys(labelRows)
          // .sort()
          .forEach((labelName) => {
            if (!arrayOfOrderedRows[labelName])
              arrayOfOrderedRows[labelName] = 0;
          });
        const reducedRows = Object.keys(labelRows)
          // .sort()
          .reduce((acc, key) => {
            let returningReducedObject = {
              ...acc,
              [key]: rows[key],
            };
            if (!rows[key])
              returningReducedObject = {
                ...acc,
                [key]: 0,
              };
            return returningReducedObject;
          }, {});
        arrayOfOrderedRows = Object.keys(reducedRows).map(
          (reducedRow, index) => {
            return reducedRows[reducedRow];
          },
        );
        const ret = {
          data: arrayOfOrderedRows,
          label: objKey,
          backgroundColor:
            objKey !== 'null' && theme.palette[objKey]
              ? theme.palette[objKey].main
              : '#000',
          hoverOffset: 5,
        };
        orderedRows.push(ret);
      });

      setData(orderedRows);
      setLabels(Object.keys(labelRows));
    })();
  }, [unitsBack]);

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">Inventory by Supplier</Typography>
      </Grid>
      <Grid item className={classes.width}>
        <BarChart
          labels={labels}
          data={data}
          stacked
          title={'Inventory by Supplier'}
          plugins={{
            legend: {
              display: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default InventoryQuantityChart;
