import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import type { Params, ProductResponse } from './types';

export default function useProduct() {
  const { id } = useParams<Params>();

  return useSWR<ProductResponse>(
    id && !Number.isNaN(id) ? `/api/v1/products/${id}` : null,
    Api.get,
    {
      revalidateOnFocus: true,
      shouldRetryOnError: false,
    },
  );
}
