import BaseApi from '../BaseApi';

import { ChannelCategory } from '@oneAppCore/types/channelTypes';
import type { Search } from 'types/searchTypes';

interface SearchResponse {
  rows: ChannelCategory[];
  count: number;
}

class ChannelCategoriesApi extends BaseApi {
  constructor() {
    super();
  }

  put(id: number, payload: ChannelCategory) {
    return this.putBase<ChannelCategory>(
      `/api/v1/channel-categories/${id}`,
      payload,
    );
  }

  post(payload: ChannelCategory) {
    return this.postBase<ChannelCategory>(
      `/api/v1/channel-categories`,
      payload,
    );
  }

  getById(id: number) {
    return this.getBase<ChannelCategory>(`/api/v1/channel-categories/${id}`);
  }

  search(payload: Partial<Search>) {
    return this.getBase<SearchResponse>('/api/v1/channel-categories/search', payload);
  }
}

export default ChannelCategoriesApi;
