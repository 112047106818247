import BaseApi from './BaseApi';

import type { Store } from 'types/storeTypes';

class StoresApi extends BaseApi {
  createNewStore = async (payload: Partial<Store>) => {
    return this.postBase<string>('/api/v1/stores/', payload);
  };
}

export default new StoresApi();
