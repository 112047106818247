import { columns } from './constants';

import { Grid } from '@mui/material';
import SearchView from '@src/Components/common/containers/SearchView';

function UsersSearch() {
  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/users/search"
        selectTypes={['create']}
        columns={columns}
        filters={[
          {
            type: 'string',
            name: 'Username',
            keyName: 'username',
          },
          {
            type: 'string',
            name: 'Email',
            keyName: 'email',
          },
        ]}
      />
    </Grid>
  );
}

export default UsersSearch;
