import { FontAwesome, Select } from '@src/Components/common';
import { Button, Divider, Grid, IconButton } from '@mui/material';
import { TextField } from '@src/Components/common';
import BrandSelect from '@src/Components/common/Selects/BrandSelect';
import VariationSelect from '@src/Components/common/Selects/VariationSelect';
import { useMemo } from 'react';
import {
  convertDollarsToCents,
  formatCentsToDollars,
} from '@src/utils/currency';
import {
  GREENS,
  REDS2,
  THEME_GREYS,
  THEME_PRIMARY,
} from '@oneAppCore/constants/colors';
import { Column } from '@src/Components/common/containers/SearchView/types';

export const columns = (isInternal?: boolean): (Column & {
  width?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
})[] => [
    {
      keyName: 'manufacturerNo',
      title: 'Manufacturer No',
      type: 'string',
    },
    {
      keyName: 'supplierId',
      title: 'Supplier Id',
      type: 'string',
      width: 12,
      customComponent: function getSupplier(row, update, suppliers, invData, addNewInternal) {
        const supplierOptions = useMemo(() => {
          if (!suppliers) return [];

          return suppliers
            .map(supplier => ({
              label: supplier.name,
              value: supplier.id,
            }));
        }, [suppliers, addNewInternal]);

        if (!suppliers) return null;
        else {
          let displayValue = supplierOptions.find(
            (option) => option.value === row?.supplierId,
          );        
          return (
            <Grid container xs={12} spacing={1} justifyContent="space-between">
              <Grid item xs={6}>
                <Select
                  options={supplierOptions}
                  label="Supplier"
                  onChange={(option) => {
                    update('supplierId', option.value);
                  }}
                  value={displayValue}
                />
              </Grid>
              {!addNewInternal ?
                <Grid item xs={6}>
                  <BrandSelect
                    value={
                      row?.brandId
                        ? row?.brandId
                        : invData?.brandId
                          ? invData.brandId
                          : ''
                    }
                    defaultValue={invData?.brandId}
                    addNewInternal={addNewInternal}
                    onChange={(option) =>
                      update(['brandId', 'brand'], [option.value, option.label])
                    }
                    valueKey="value"
                  />
                </Grid> : null}
            </Grid>
          );
        }
      },
    },
    {
      keyName: 'variationId',
      title: 'Variation',
      type: 'string',
      customComponent: function mpnUpc(row, update, suppliers, addNewInternal) {
        return (
          <Grid item container xs={12}>
            <VariationSelect
              mpn={row?.manufacturerNo}
              brandId={row?.brandId}
              value={row?.variationId}
              addNewInternal={addNewInternal}
              onChange={(option) => {
                update(['variationId'], [option.value]);
              }}
              valueKey="value"
              label={'Brand, sku, variation'}
            />
          </Grid>
        );
      },
    },
    {
      keyName: 'location',
      title: 'Location',
      type: 'string',

    },
    {
      keyName: 'quantity',
      title: 'qty',
      type: 'string',
      width: 12,
      customComponent: function onHand(row, update, suppliers, invData, addNewInternal) {

        const onHand = (
          <Grid item container xs={12} style={{ justifyContent: 'space-around' }}>
            <Grid item xs={12}>
              <TextField
                label="QTY On Hand"
                type="number"
                value={row?.oldQuantity}
                onChange={(e) =>
                  update(
                    'oldQuantity',
                    e.target.value !== ''
                      ? Number(e.target.value)
                      : '',
                  )
                }
              />
            </Grid>
          </Grid>
        );
        const available = (
          <Grid item container xs={12} style={{ justifyContent: 'space-around' }}>
            <Grid item xs={12}>
              <TextField
                label="Available QTY"
                type="number"
                value={row?.quantity}
                onChange={(e) =>
                  update(
                    'quantity',
                    e.target.value !== ''
                      ? Number(e.target.value)
                      : '',
                  )
                }
              />
            </Grid>
          </Grid>
        );
        const newSupplier = (
          <Grid item container xs={12} style={{ justifyContent: 'space-around' }}>
            <Grid item xs={12}>
              <TextField
                label={isInternal ? "QTY On Hand" : "Available QTY"}
                type="number"
                value={row?.oldQuantity}
                onChange={(e) =>
                  update(
                    'oldQuantity',
                    e.target.value !== ''
                      ? Number(e.target.value)
                      : '',
                  )
                }
              />
            </Grid>
          </Grid>
        );
        return (addNewInternal ? newSupplier : isInternal ? onHand : available);
      },
    },
    {
      keyName: 'cost',
      title: 'Cost',
      type: 'number',
      customComponent: function costs(row, update, suppliers, invData) {
        const cheapestSupplier = row?.mansups?.find(
          (mansup) => mansup.supplierId === row.supplierId,
        );
        const formatOpts = { removeMoneySign: true };
        const defValCheck = row?.cost ? row.cost : cheapestSupplier?.cost
          ? cheapestSupplier?.cost
          : invData?.result !== null && invData?.result?.mansups?.length > 0
            ? invData?.result?.mansups[0]?.cost
            : 0;
        return (
          <Grid
            item
            container
            xs={12}
            spacing={1}
            style={{ justifyContent: 'space-around' }}
          >
            <Grid item xs={6}>
              <TextField
                label="Cost"
                type="string"
                defaultValue={
                  formatCentsToDollars(defValCheck, formatOpts)
                }
                onChange={(e) =>
                  update(
                    'cost',
                    e.currentTarget.value !== ''
                      ? convertDollarsToCents(e.currentTarget.value)
                      : '',
                  )
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Shipping Cost"
                type="string"
                defaultValue={
                  cheapestSupplier?.shippingCost
                    ? formatCentsToDollars(cheapestSupplier?.shippingCost, {
                      removeMoneySign: true,
                    })
                    : cheapestSupplier?.estimatedShipping
                      ? formatCentsToDollars(cheapestSupplier?.estimatedShipping, {
                        removeMoneySign: true,
                      })
                      : invData?.result !== null &&
                        invData?.result?.mansups?.length > 0
                        ? formatCentsToDollars(
                          invData?.result?.mansups[0]?.shippingCost,
                          {
                            removeMoneySign: true,
                          },
                        )
                        : row?.cost !== undefined
                          ? formatCentsToDollars(row.shippingCost, {
                            removeMoneySign: true,
                          })
                          : ''
                }
                onChange={(e) =>
                  update(
                    'shippingCost',
                    e.currentTarget.value !== ''
                      ? convertDollarsToCents(e.currentTarget.value)
                      : '',
                  )
                }
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      keyName: 'barcodes',
      title: 'Barcodes',
      type: 'string',
      customComponent: function getSupplier(row, update, suppliers) {
        const barcodes = row?.barcodes || [
          {
            deleted: false,
            upc: `${row?.manufacturerNo || row?.sku}`,
            quantityPerBox: '1',
          },
        ];

        const supplierId = row?.supplierId;
        // const internalSuppliers = suppliers
        //   .filter((s) => s.internalSupplier)
        //   .map((s) => s.id);
        const filteredBarcodes = barcodes?.filter((x) => x) || [];
        return supplierId && isInternal ? (
          <>
            <Divider
              style={{ background: THEME_PRIMARY.main, marginTop: 5, height: 2 }}
            />
            <Grid
              item
              xs={12}
              style={{ marginTop: 5, marginBottom: 5, padding: 10 }}
            >
              <Button
                style={{
                  backgroundColor: THEME_PRIMARY.main,
                  color: 'white',
                }}
                fullWidth
                onClick={() => {
                  const bc = [...barcodes];
                  bc.push({ deleted: false });
                  update('barcodes', bc);
                }}
              >
                Add Barcode
              </Button>
            </Grid>
            <Divider
              style={{ background: THEME_GREYS.main, marginBottom: 5, height: 1 }}
            />
            <Grid
              item
              container
              xs={12}
              style={{
                maxHeight: '150px',
                overflowY: 'scroll',
                padding: 10,
                paddingBottom: 10,
                marginBottom: 5,
              }}
              justifyContent="space-around"
              direction="row"
              spacing={1}
            >
              <Grid item container xs={12} key={`barcode-headers`}>
                <Grid item container xs={7}>
                  Barcode
                </Grid>
                <Grid item container xs={3}>
                  Quantity
                </Grid>
                <Grid item container xs={2}></Grid>
              </Grid>
              {filteredBarcodes
                .filter((bcc) => !bcc.deleted)
                .map((barcode, index) => {
                  return (
                    <Grid item container xs={12} key={`barcode-${index}`}>
                      <Grid item container xs={7}>
                        <TextField
                          type={'text'}
                          variant={'outlined'}
                          value={barcode.upc}
                          onChange={(e) => {
                            const bc = [...barcodes];
                            bc[index].upc = e.target.value;
                            update('barcodes', bc);
                          }}
                          style={{
                            marginRight: '7px',
                          }}
                        />
                      </Grid>
                      <Grid item container xs={3}>
                        <TextField
                          type={'number'}
                          variant={'outlined'}
                          value={barcode.quantityPerBox}
                          onChange={(e) => {
                            const bc = [...barcodes];
                            bc[index].quantityPerBox = e.target.value;
                            update('barcodes', bc);
                          }}
                          style={{
                            marginRight: '7px',
                          }}
                        />
                      </Grid>
                      <Grid item container xs={2}>
                        <IconButton
                          style={{
                            backgroundColor: REDS2.main,
                            color: 'white',
                            borderRadius: 5,
                          }}
                          size="small"
                          onClick={() => {
                            const bc = [...barcodes];
                            bc[index].deleted = true;
                            update('barcodes', bc);
                          }}
                          title={'Remove barcode'}
                        >
                          <FontAwesome name="times" type="fa" form="fas" />
                        </IconButton>
                      </Grid>
                    </Grid>
                  );
                })}
            </Grid>
            <Divider
              style={{
                background: THEME_PRIMARY.main,
                marginBottom: 5,
                height: 2,
              }}
            />
          </>
        ) : null;
      },
    },
  ];
