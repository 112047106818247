import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';

import type { OrderOperations } from './types';

export default function useOrderOperations() {
  return useSWR<OrderOperations[]>(
    `/api/v1/reports/operations/orders`,
    Api.get,
  );
}
