import {
  TableRow,
  TableCell,
  TextField,
  IconButton,
  Stack,
  Grid,
} from '@mui/material';
import { Column } from '@src/Components/common/containers/SearchView/types';
import { FontAwesome } from '@src/Components/common';
import { useEffect, useState } from 'react';
import { CreateVariation, Props } from './types';
import { Form, FitmentForm, VariationsForm } from '../../types';
import VariationsApi from '@oneAppCore/services/VariationsApi';
import { useSnackbar } from 'notistack';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import { primary } from '@src/Components/Theme/themes/constants';
import AddCircleTwoToneIcon from '@mui/icons-material/AddCircleTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlineOutlined from '@mui/icons-material/DeleteOutlineOutlined';

const AddVariation = ({
  columns,
  product,
  mutate,
  variation,
  deleteRow,
  variationIndex,
  removeVariation,
  varOptions,
  varOptionsTwo,
}: {
  columns?: Column[];
  product?: Form;
  variation?: any;
  deleteRow?: any;
  mutate?: any; // ? Ask tanner what the type would be for this
  variationIndex?: number;
  removeVariation?: (type: number) => void;
  varOptions?: any;
  varOptionsTwo?: any;
}) => {
  const [newVariation, setNewVariation] = useState<CreateVariation>({
    title: '',
    sku: '',
    manufacturerNo: '',
    productId: 0,
    upc: '',
    quantityPerBox: 0,
    itemPrice: 0,
    variantType: varOptions,
    variantTypeTwo: varOptionsTwo,
    variationTwo: '',
  });

  const [url, setUrl] = useState('');
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [form, setForm] = useState<VariationsForm[]>([]);

  const { enqueueSnackbar } = useSnackbar();

  const handleCreateVariation = async () => {
    try {
      if (
        newVariation.title === '' ||
        newVariation.sku === '' ||
        newVariation.manufacturerNo === ''
      )
        return;
      else {
        const {
          title: variation,
          sku,
          manufacturerNo,
          upc,
          quantityPerBox,
          itemPrice,
          variantType,
          variationTwo,
        } = newVariation;
        const { id: productId, brandId } = product;
        await VariationsApi.post({
          variation,
          sku,
          manufacturerNo,
          productId,
          brand: brandId,
          upc,
          quantityPerBox,
          url,
          itemPrice,
          variantType: varOptions,
          variantTypeTwo: varOptionsTwo,
          variationTwo,
        }).then(() => {
          enqueueSnackbar('Created A Variation!', { variant: 'success' });
          setNewVariation({
            productId: 0,
            title: '',
            sku: '',
            manufacturerNo: '',
            upc: '',
            quantityPerBox,
            itemPrice,
            variantType: varOptions,
            variantTypeTwo: varOptionsTwo,
          });
          mutate();
        });
      }
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  return (
    <TableRow>
      {columns?.map((column) => {
        if (column.keyName === 'multipack') {
          return (
            <TableCell key={column.keyName} style={{ textAlign: 'center' }}>
              <AddCircleTwoToneIcon
                onClick={handleCreateVariation}
                fontSize="large"
                style={{ color: '#3C9629' }}
              />
            </TableCell>
          );
        } else if (column.keyName === 'image') {
          return (
            <TableCell key={column.keyName}>
              <FileUpload
                id="any"
                onUpload={(uploadUrl) => setUrl(uploadUrl[0])}
              />
            </TableCell>
          );
        } else if (column.keyName === 'title') {
          return (
            <TableCell key={column.keyName}>
              {varOptionsTwo ? (
                <Grid container xs={12} justifyContent="space-between">
                  <Grid item xs={6}>
                    <TextField
                      style={{
                        width: 'fit-content',
                        backgroundColor: primary.transparent.main,
                      }}
                      label={
                        varOptions?.charAt(0)?.toUpperCase() +
                        varOptions?.slice(1)
                      }
                      variant="outlined"
                      value={newVariation[column.keyName]}
                      onChange={(e) =>
                        setNewVariation((nv) => ({
                          ...nv,
                          title: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <TextField
                      style={{
                        width: 'fit-content',
                        backgroundColor: primary.transparent.main,
                      }}
                      label={
                        varOptionsTwo?.charAt(0)?.toUpperCase() +
                        varOptionsTwo?.slice(1)
                      }
                      variant="outlined"
                      value={newVariation.variationTwo}
                      onChange={(e) =>
                        setNewVariation((nv) => ({
                          ...nv,
                          variationTwo: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                </Grid>
              ) : (
                <TextField
                  style={{
                    width: 'fit-content',
                    backgroundColor: primary.transparent.main,
                  }}
                  label={
                    varOptions?.charAt(0)?.toUpperCase() + varOptions?.slice(1)
                  }
                  variant="outlined"
                  value={newVariation[column.keyName]}
                  onChange={(e) =>
                    setNewVariation((nv) => ({
                      ...nv,
                      [column.keyName]: e.target.value,
                    }))
                  }
                />
              )}
            </TableCell>
          );
        } else if (column.editable) {
          return (
            <TableCell key={column.keyName}>
              <TextField
                style={{
                  width: '100%',
                  backgroundColor: primary.transparent.main,
                }}
                label={column.title}
                variant="outlined"
                value={newVariation[column.keyName]}
                onChange={(e) =>
                  setNewVariation((nv) => ({
                    ...nv,
                    [column.keyName]: e.target.value,
                  }))
                }
              />
            </TableCell>
          );
        } else if (column.keyName === 'edit') {
          return (
            <TableCell key={column.keyName}>
              <Stack direction="row">
                <IconButton
                  color="success"
                  size="small"
                  onClick={handleCreateVariation}
                  sx={{
                    // borderRadius: `$1px`,
                    p: 1.25,
                    border: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <CheckOutlinedIcon />
                </IconButton>
                <IconButton
                  color="error"
                  size="small"
                  onClick={() => removeVariation(variationIndex)}
                  sx={{
                    // borderRadius: `$1px`,
                    p: 1.25,
                    border: '1px solid',
                    borderColor: 'divider',
                    marginLeft: 2,
                  }}
                >
                  <DeleteOutlineOutlined />
                </IconButton>
              </Stack>
              {/* <CancelTwoToneIcon
                onClick={() => removeVariation(variationIndex)}
                fontSize="large"
                style={{ color: '#FC0301' }}
              /> */}
            </TableCell>
          );
        } else {
          // ? This is to maintain the spacing consistancy for each column.
          return <TableCell key={column.keyName} />;
        }
      })}
    </TableRow>
  );
};

export default AddVariation;
