import { useState, FormEvent, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { pick } from 'lodash';
import {
  Grid, Typography, Button, FormGroup, InputLabel, InputAdornment, FormControl, FormControlLabel, IconButton, OutlinedInput, Switch
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import useQuery from '@src/hooks/util/useQuery';
import { TextField, Backdrop } from '@src/Components/common';
import type { FormProps } from '@src/Components/common/containers/SearchView/CreationDrawer/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import queryUtils from '@src/utils/queries';
import SupplierApi from '@oneAppCore/services/SupplierApi';
import type { Supplier } from '@oneAppCore/types/supplierTypes';

import { complexFormFields, disabledKeys } from './constants';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
}));

function SupplierDrawer({ onSubmit, close }: FormProps) {
  const classes = useStyles();

  const [form, setForm] = useState<Supplier>({});
  const [metaData, setMetaData] = useState({});
  const [newPropName, setNewPropName] = useState('');


  const [saving, setSaving] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const { id } = useQuery();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await SupplierApi.getSupplierById(Number(id));
        const newForm = pick(response, complexFormFields.map(cf => cf.name));
        setForm(newForm);
        setMetaData(newForm.metaData);
      })();
    } else {
      setForm({});
    }
  }, [id]);

  const update = <K extends keyof Supplier>(
    key: K,
    value: Supplier[K],
  ) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const updateItem = (
    index: number,
    value: any,
    key: string,
  ) => {
    const newList = form[key]
    newList[index] = value;
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const updateMetaData = (
    value: any,
    key: string,
    prop: string,
    subProp: string = '',
  ) => {
    const newData = form[key];
    if (subProp) {
      newData[prop][subProp] = value;
    } else {
      newData[prop] = value;
    }
    setForm({
      ...form,
      [key]: newData,
    });
  };

  const updateNewPropName = (
    value: string,
  ) => {
    setNewPropName(value);
  };

  const addObjectItem = (
    key: string,
    propName: string,
    createdPropName: string,
  ) => {
    const newData = form[key]
    if (!newData?.[propName]?.[createdPropName]) {
      newData[propName][createdPropName] = '';
      setForm({
        ...form,
        [key]: newData,
      });
    } else {
      enqueueSnackbar(`The property ${createdPropName} already exists!`, {
        variant: 'error',
      });
      throw {
        message: 'This property already exists!',
      }
    }
  };

  const removeMetaItem = (
    prop: string,
    subProp: string,
    key: string,
  ) => {
    const newData = form[key];
    delete newData[prop][subProp];
    setForm({
      ...form,
      [key]: newData,
    });
  };

  const addItem = (key: string) => {
    const newList = form[key] ? form[key] : [];
    newList.push('');
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const removeItem = (key: string, index: number) => {
    const newList = form[key];
    newList.splice(index, 1);
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const removeDeeperItem = (
    key: string,
    index: number,
    pathArray: string[],
  ) => {
    const newList = form[key];
    let arrayList = newList;
    pathArray.forEach(path => {
      arrayList = arrayList[path]
    })
    arrayList.splice(index, 1);
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const updateDeeperItem = (
    key: string,
    value: string,
    index: number,
    pathArray: string[],
  ) => {
    const newList = form[key];
    let arrayList = newList;
    pathArray.forEach(path => {
      arrayList = arrayList[path]
    })
    arrayList[index] = value;
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const addDeeperItem = (
    key: string,
    pathArray: string[],
  ) => {
    const newList = form[key];
    let arrayList = newList;
    pathArray.forEach(path => {
      arrayList = arrayList[path]
    })
    arrayList.push('');
    setForm({
      ...form,
      [key]: newList,
    });
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSaving(true);
    try {
      const newForm = form;
      let operation = 'Created';
      complexFormFields.filter(field => field.type === 'list').forEach(field => {
        newForm[field.name] = newForm[field.name].filter(value => value);
      });
      if (id) {
        operation = 'Updated';
        await SupplierApi.editSupplier(Number(id), newForm);
        const newParams = queryUtils.remove('id');
        history.replace({
          search: newParams,
        });
      } else {
        await SupplierApi.createSupplier(newForm);
      }
      enqueueSnackbar(`Supplier ${operation}`, {
        variant: 'success',
      });
      await onSubmit();
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  const renderTextInput = (propertyName: any, propertyLabel: string, variant: "outlined" | "filled" | "standard" = 'outlined') => {
    return (
      <TextField
        fullWidth
        variant={variant}
        label={propertyLabel}
        name={propertyName}
        value={form?.[propertyName] || ''}
        onChange={(e) => update(propertyName, e.target.value)}
        required
      />
    );
  }

  const renderBooleanInput = (propertyName: any, propertyLabel: string) => {
    return (
      <FormGroup>
        <FormControlLabel
          label={propertyLabel}
          labelPlacement="top"
          control={
            <Switch
              name={propertyName}
              color="primary"
              checked={form?.[propertyName] || false}
              // value={form?.[propertyName] || 'off'}
              onChange={(e) => update(propertyName, e.target.checked)}
            />
          }
        />
      </FormGroup>
    );
  }

  const renderListInput = (propertyName: any, propertyLabel: string, variant: "outlined" | "filled" | "standard" = 'outlined') => {
    const listThing = form?.[propertyName] ? form?.[propertyName] : metaData;
    return (
      <>
        <Grid item container>
          <Typography variant="h5">{propertyLabel}</Typography>
        </Grid>
        <Grid container direction="column">
          {listThing?.map((listItem, index) => {
            return (
              <FormControl key={`supplier-${propertyName}-${index + 1}`}>
                <InputLabel htmlFor={`supplier-${propertyName}-${index + 1}`}>{`Alias ${index + 1}`}</InputLabel>
                <OutlinedInput
                  fullWidth
                  id={`supplier-${propertyName}-${index + 1}`}
                  type="text"
                  name={`${propertyName}-${index}`}
                  value={listItem || ''}
                  onChange={(e) => updateItem(index, e.target.value, propertyName)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        color="primary"
                        aria-label={`remove item from ${propertyLabel} list`}
                        onClick={() => { removeItem(propertyName, index) }}
                      >
                        <FontAwesome name="times" type="fa" form="fas" />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
            );
          })}
        </Grid>
        <Grid item>
          <Button onClick={() => { addItem(propertyName); }} >Add Alias</Button>
        </Grid>
      </>
    );
  }

  const renderObjectKeysForInput = (propertyName: any, propertyLabel: string, variant: "outlined" | "filled" | "standard" = 'outlined', objectThing: any, objectKey: string) => {
    return Object.entries(objectThing[objectKey]).map(([objectItemKey, objectItemValue], index) => {
      let result;
      switch (typeof objectItemValue) {
        case 'string':
          result = (
            <FormControl>
              <InputLabel htmlFor={`${propertyName}-${objectItemKey}-${index + 1}`} shrink variant="outlined">{objectItemKey}</InputLabel>
              <OutlinedInput
                fullWidth
                title={`${objectItemValue}`}
                id={`${propertyName}-${objectItemKey}-${index + 1}`}
                type="text"
                name={`${propertyName}-${objectItemKey}-${index}`}
                value={objectItemValue || ''}
                onChange={(e) => updateMetaData(e.target.value, propertyName, objectKey, objectItemKey)}
                disabled={disabledKeys.includes(objectKey)}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      color="primary"
                      aria-label={`remove ${objectKey}-${objectItemKey} from ${propertyLabel} list`}
                      onClick={() => { removeMetaItem(objectKey, objectItemKey, propertyName) }}
                      disabled={disabledKeys.includes(objectKey)}
                    >
                      <FontAwesome name="times" type="fa" form="fas" />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          );
          break;
        case 'object':
          if (Array.isArray(objectItemValue)) {
            result = (
              <>
                <Grid item container direction="column">
                  {objectItemValue.map((listItem, index) => {
                    return (
                      <FormControl key={`supplier-${propertyName}-${objectKey}-${objectItemKey}-${index + 1}`}>
                        <InputLabel htmlFor={`supplier-${propertyName}-${objectKey}-${objectItemKey}-${index + 1}`}>{`${objectItemKey} ${index + 1}`}</InputLabel>
                        <OutlinedInput
                          fullWidth
                          id={`supplier-${propertyName}-${objectKey}-${objectItemKey}-${index + 1}`}
                          type="text"
                          name={`${propertyName}-${objectKey}-${objectItemKey}-${index}`}
                          value={listItem || ''}
                          onChange={(e) => updateDeeperItem(propertyName, e.target.value, index, [objectKey, objectItemKey])}
                          disabled={disabledKeys.includes(objectKey)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                color="primary"
                                aria-label={`remove item from ${objectKey} ${objectItemKey} list`}
                                onClick={() => { removeDeeperItem(propertyName, index, [objectKey, objectItemKey]) }}
                                disabled={disabledKeys.includes(objectKey)}
                              >
                                <FontAwesome name="times" type="fa" form="fas" />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    );
                  })}
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => { addDeeperItem(propertyName, [objectKey, objectItemKey]); }}
                    disabled={disabledKeys.includes(objectKey)}
                  >{`Add ${objectItemKey}`}</Button>
                </Grid>
              </>
            );
          }
          break;
      };
      return (
        <Grid item key={`${propertyName}-${objectItemKey}-${index + 1}`}>
          {result}
        </Grid>
      );
    })
  }

  const renderObjectInput = (propertyName: any, propertyLabel: string, variant: "outlined" | "filled" | "standard" = 'outlined') => {
    const objectThing = form?.[propertyName] ? form?.[propertyName] : metaData;
    console.log('objectThing', propertyName, form?.[propertyName], metaData);
    return (
      <>
        <Grid item container>
          <Typography variant="h5">{propertyLabel}</Typography>
        </Grid>
        <Grid item container direction="column" spacing={2}>
          {Object.keys(objectThing).sort((a, b) => a.localeCompare(b)).map((objectKey) => {
            let result;
            switch (typeof objectThing[objectKey]) {
              case 'string':
                result = (<TextField
                  fullWidth
                  variant={variant}
                  label={''}
                  name={objectKey}
                  value={objectThing?.[objectKey] || ''}
                  onChange={(e) => updateMetaData(e.target.value, propertyName, objectKey)}
                  disabled={disabledKeys.includes(objectKey)}
                />)
                break;
              case 'object':
                result = renderObjectKeysForInput(propertyName, propertyLabel, variant, objectThing, objectKey)
                break;
            }
            return (
              <>
                <Grid item container>
                  <Typography variant="h6">{objectKey}</Typography>
                </Grid>
                {result}
                {typeof objectThing[objectKey] !== 'string' && <Grid item container>
                  <Grid item>
                    <TextField
                      fullWidth
                      variant={variant}
                      label={`New ${objectKey} property name`}
                      value={newPropName || ''}
                      onChange={(e) => updateNewPropName(e.target.value)}
                    />
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" color="secondary" onClick={() => { addObjectItem(propertyName, objectKey, newPropName); }} >{`Add ${objectKey}`}</Button>
                  </Grid>
                </Grid>}
              </>
            );
          })}
        </Grid>
      </>
    );
  }

  const inputTypes = {
    'text': renderTextInput,
    'list': renderListInput,
    'object': renderObjectInput,
    'boolean': renderBooleanInput,
  };

  return (
    <form className={classes.form} onSubmit={submit}>
      <Grid container spacing={2} direction="column" className={classes.form}>
        <Grid item container justifyContent="center">
          <Typography variant="h4">Supplier Drawer</Typography>
        </Grid>
        {complexFormFields.map((field, index) => {
          if (field.logic) {
            if (field.logic.func(form?.[field.logic.col])) {
              return (
                <Grid item key={`edit-supplier-${index}`}>
                  {inputTypes[field.type](field.name, field.label)}
                </Grid>
              );
            } else {
              return null;
            }
          } else {
            return (
              <Grid item key={`edit-supplier-${index}`}>
                {inputTypes[field.type](field.name, field.label)}
              </Grid>
            )
          }
        }).filter((item) => item)}
        <Grid item container justifyContent="space-around">
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={close}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop open={saving} />
    </form>
  );
}

export default SupplierDrawer;
