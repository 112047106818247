import BaseApi from '../BaseApi';
import Api from '@oneAppCore/services/Api';

import { ccRecPayload } from './types';

class CCRecApi extends BaseApi {
  constructor() {
    super();
  }

  put(id: number, payload: Partial<ccRecPayload>) {
    return this.putBase<ccRecPayload>(`/api/v1/cc-rec/${id}`, payload);
  }
}

export default CCRecApi;
