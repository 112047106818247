import Api from '@oneAppCore/services/Api';
import { omit } from 'lodash';
import useSWR from 'swr';

function useStoreListingsRepriceData(params) {
  const queryString = Object.keys(params).length
    ? `?${Object.keys(params)
        .map((key) => {
          if (Array.isArray(params[key])) {
            return params[key].map((value) => `${key}[]=${value}`).join('&');
          } else if (
            params[key] ||
            params[key] === false ||
            params[key] === 0 ||
            params[key] === ''
          ) {
            return `${key}=${params[key]}`;
          }
          return '_';
        })
        .filter((item) => item !== '_')
        .join('&')}`
    : '';
  const result = useSWR(
    [`api/v1/store-listings/reprice${queryString}`],
    Api.get,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: false,
    },
  );
  return result;
}

function useStoreListingChangesData(params) {
  const queryString = Object.keys(params).length
    ? `?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`
    : '';
  const result = useSWR(
    [`api/v1/store-listings/changes${queryString}`],
    Api.get,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );
  return result;
}

function useManufacturerSupplierHistoryData(params) {
  const queryString = Object.keys(params).length
    ? `?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`
    : '';
  const result = useSWR(
    [`api/v1/store-listings/cost-history${queryString}`],
    Api.get,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );
  return result;
}
function useStoreListingChangesDataFormatted(params) {
  const queryString = Object.keys(params).length
    ? `?${Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&')}`
    : '';
  const result = useSWR(
    [`api/v1/store-listings/changes${queryString}`],
    Api.get,
    {
      revalidateOnFocus: false,
      shouldRetryOnError: true,
    },
  );
  const rows =
    result?.data?.rows.map((datum) => {
      const { storeListingId: id, newValues } = datum;
      return {
        id,
        ...omit(newValues, ['id', 'status']),
      };
    }) || [];
  return { ...result, data: { rows } };
}

export default {
  useStoreListingsRepriceData,
  useStoreListingChangesData,
  useStoreListingChangesDataFormatted,
  useManufacturerSupplierHistoryData,
};
