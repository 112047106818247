import { useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Modal, Button } from '@mui/material';
import FontAwesome from '@src/Components/common/FontAwesome';
import type { Props } from './types';
import { useSnackbar } from 'notistack';
import OrderIssueApi from '@oneAppCore/services/OrderIssueApi';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
}));

function ResolveButton({ row }: Props) {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resolveIssue = async () => {
    try {
      const resolvedPayload = {
        resolved: true,
        orderId: row.id,
        placed: false,
      };
      await OrderIssueApi.put(row.id, resolvedPayload);
      enqueueSnackbar('Issue Has Been Resolved!', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Error Resolving Issue', {
        variant: 'error',
      });
    } finally {
      handleClose();
    }
  };

  return (
    <>
      <Grid item container>
        <Tooltip title={'Resolve Issue'}>
          <IconButton
            style={{
              fontSize: '14px',
              color: '#10960E',
              paddingLeft: '15%',
              paddingRight: '15%',
            }}
          >
            <FontAwesome
              name="check"
              type="fa"
              form="fa"
              onClick={handleOpen}
            />
          </IconButton>
        </Tooltip>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              justifyContent: 'center',
              borderRadius: 10,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <h2>Are you sure?</h2>
            </Grid>
            <Grid item container xs={10} style={{ justifyContent: 'center' }}>
              <p
                style={{
                  fontSize: '20px',
                  marginTop: 15,
                  marginBottom: 15,
                  textAlign: 'center',
                }}
              >
                This will resolve the order issue to fulfill the order.
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ padding: 10, justifyContent: 'flex-end' }}
            >
              <Grid item container xs={2} style={{ justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  style={{ border: '1px solid red', color: 'red' }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={3}
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    resolveIssue();
                  }}
                >
                  Resolve
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default ResolveButton;
