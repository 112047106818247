import { useMemo, useState, useEffect } from 'react';
import useSuppliers from '@src/hooks/swr/useSuppliers';
import Api from '@oneAppCore/services/Api';
import { columns } from './constants';

import { Grid } from '@mui/material';

import SearchView from '@src/Components/common/containers/SearchView';
import useMeSuppliers from '@src/hooks/swr/useMeSuppliers';
import { useRecoilValue } from 'recoil';
import { reposState as reposAtom } from '@oneAppCore/one-app/src/Components/common/containers/SearchView/SearchTable/atoms';
import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions';

function RestockInventorySearch() {
  const { data: stores = [] } = useMeSuppliers();
  const { data: suppliers = [] } = useSuppliers();
  const [lastPO, setLastPO] = useState(0);
  const [defaultPO, setDefaultPO] = useState(0);
  const [poList, setPOList] = useState<any>([]);
  const [changeLastPO, setChangeLastPO] = useState(false);
  const [constantFilters, setConstantFilters] = useState({});
  const [filterOptions, setFilterOptions] = useState([]);
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const [supplier, setSupplier] = useState({});

  useEffect(() => {
    const func = async () => {
      if (lastPO === 0) {
        console.log(lastPO);
        const { lastPO: lpo } = await Api.get(
          '/api/v1/manufacturer-suppliers-restock/po',
        );
        setLastPO(lpo);
        setDefaultPO(lpo);
      }
    };
    func();
  }, [changeLastPO]);
  const motoSupplier = stores.map((supplier) => supplier.id);
  useEffect(() => {
    if (motoSupplier.length > 0 && motoSupplier?.[0] > 7) {
      const constFilt = {
        supplierId: motoSupplier[0],
      };
      setConstantFilters(constFilt);
      const opts = {
        ...searchOptions,
        filters: { ...searchOptions.filters, ...constFilt },
      };
      setSearchOptions(opts);
    } else {
      setFilterOptions([
        {
          type: 'selector',
          name: 'Supplier',
          keyName: 'supplierId',
          properties: supplierOptions,
        },
      ]);
    }
  }, [stores]);

  const supplierOptions = useMemo(
    () =>
      suppliers.map((company) => ({
        label: company.name,
        value: company.id,
      })),
    [suppliers],
  );

  const resetPO = () => {
    setChangeLastPO(!changeLastPO);
  };

  const changePO = (e) => {
    setLastPO(e.target.value);
  };

  
  const handleSuppChange = (rowIndex, supplier, event) => {
    const { value } = event.target;
    setSupplier((prevSupplier) => {
      return { ...prevSupplier, [rowIndex]: value };
    });
  };

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/manufacturer-suppliers-restock/advancedSearch"
        select
        selectTypes={['restock']}
        swrOptions = {{
          revalidateOnFocus: false,
          focusThrottleInterval: 7200000,
        }}
        columns={columns(
          supplier,
          lastPO,
          defaultPO,
          poList,
          resetPO,
          changePO,
          handleSuppChange,
        )}
        constantFilters={{
          'recQty.value': 0,
          'recQty.direction': '>',
          purchDateAmount: 10,
        }}
        filters={[
          /* This code is for the last restocked filter, it was requested at one point, but is currently in limbo
          will wait until not needed to remove */
          // {
          //   type: 'selector',
          //   name: 'Last restocked',
          //   keyName: 'orderedFilter',
          //   properties: restockProperties,
          // },
          {
            type: 'string',
            name: 'Brand',
            keyName: 'brand',
          },
          {
            type: 'equality',
            name: 'Suggested Order Qty',
            keyName: 'recQty',
          },
          {
            type: 'equality',
            name: 'Inventory Days Left',
            keyName: 'daysLeftFilter',
          },
          {
            type: 'equality',
            name: 'Current',
            keyName: 'currentFilter',
          },
          {
            type: 'equality',
            name: 'Inbound',
            keyName: 'inboundFilter',
          },
          {
            type: 'number',
            name: 'Order/Qty (# of Days)',
            keyName: 'purchDateAmount',
          }
          /* this code is for the eventual filters for mpn and sku, so we can move them out of the search and into these filters
          This pairs with the backend change to allow sku and mpn in the request */
          // {
          //   type: 'string',
          //   name: 'SKU',
          //   keyName: 'sku',
          // },
          // {
          //   type: 'string',
          //   name: 'Manufacturer Part Number',
          //   keyName: 'mpn',
          // },
        ]}
      />
    </Grid>
  );
}

export default RestockInventorySearch;
