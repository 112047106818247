import { Grid } from '@mui/material';

import { columns } from './constants';

import SearchView from '@src/Components/common/containers/SearchView';

import BrandDrawer from './BrandDrawer';

function BrandSearch() {
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <SearchView
        url="/api/v1/brands/search"
        columns={columns}
        selectTypes={['createBrand']}
        filters={[]}
      />
    </Grid>
  );
}

export default BrandSearch;
