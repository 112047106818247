import { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Grid,
  Tabs,
  Tab,
  Box,
  AppBar,
  Theme,
  CircularProgress,
  Paper,
  Typography,
  Divider,
} from '@mui/material';

import useUser from '@src/hooks/swr/useUser';
import { useHasAdminPermissions } from '@src/hooks/util/useHasPermission';
import useMe from '@src/hooks/swr/useMe';
import CoreInformation from './CoreInformation';
import UserPermissions from './Permissions';
import UserExistance from './Existance';
import UserLocations from './Locations';
import type { Params } from './types';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import RoomIcon from '@mui/icons-material/Room';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Loading from './Loading';

function tabProps(index) {
  return {
    id: `tab-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    // backgroundColor: '#ffffff',
    borderRadius: 5,
    padding: 15,
  },
}));

function UserView({ user: paramUser }) {
  const { id } = useParams<Params>();
  const { pathname } = useLocation();
  const admin = useHasAdminPermissions();
  const [isSameUser] = useState<Boolean>(paramUser.id === Number(id));
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const { data: user, mutate: mutateUser } = id
    ? useUser(Number(id))
    : useUser(Number(useMe().data.id));

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  const accessiblePage = (
    <>
      {/* <Grid container xs={12} style={{ marginBottom: 20, padding: 10 }}>
        <AppBar position="sticky" className={classes.root} elevation={1}>
          <Tabs value={value} onChange={handleChange}>
            <Tab
              style={{ color: '#000000' }}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <InfoIcon color="secondary" fontSize="small" />
                  <span style={{ marginLeft: 10 }}>Information</span>
                </div>
              }
              {...tabProps(0)}
            />
            <Tab
              style={{ color: '#000000' }}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <CheckCircleIcon color="secondary" fontSize="small" />
                  <span style={{ marginLeft: 10 }}>Permissions</span>
                </div>
              }
              {...tabProps(1)}
            />
            <Tab
              style={{ color: '#000000' }}
              label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <RoomIcon color="secondary" fontSize="small" />
                  <span style={{ marginLeft: 10 }}>Locations</span>
                </div>
              }
              {...tabProps(2)}
            />
          </Tabs>
        </AppBar>
      </Grid> */}
      <Grid container sx={{ pl: 2 }}>
        <Paper sx={{ width: '100%', mt: 3 }}>
          <Grid item container xs={12} style={{ padding: '24px' }}>
            <Typography style={{ fontSize: '1.125rem', fontWeight: 500 }}>
              {`${user?.firstName}'s Information`}
            </Typography>
          </Grid>
          <Grid xs={12} style={{ marginBottom: 15 }}>
            <Divider />
          </Grid>
          <Grid container xs={12} className={classes.root}>
            <Grid
              item
              container
              xs={3}
              style={{ justifyContent: 'flex-start' }}
            >
              <Tabs
                value={value}
                onChange={handleChange}
                orientation="vertical"
                style={{ width: '90%', justifyContent: 'flex-start' }}
              >
                <Tab
                  style={{ color: '#000000' }}
                  label={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <InfoIcon color="secondary" fontSize="small" />
                      <span style={{ marginLeft: 10 }}>Information</span>
                    </div>
                  }
                  {...tabProps(0)}
                />
                <Tab
                  style={{ color: '#000000' }}
                  label={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <CheckCircleIcon color="secondary" fontSize="small" />
                      <span style={{ marginLeft: 10 }}>Permissions</span>
                    </div>
                  }
                  {...tabProps(1)}
                />
                <Tab
                  style={{ color: '#000000' }}
                  label={
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <RoomIcon color="secondary" fontSize="small" />
                      <span style={{ marginLeft: 10 }}>Locations{'  '}</span>
                    </div>
                  }
                  {...tabProps(2)}
                />
              </Tabs>
              <Divider orientation="vertical" />
            </Grid>
            {value === 0 ? (
              <Grid item container xs={9}>
                {/* <Grid item xs={9}></Grid> */}
                <CoreInformation
                  user={user}
                  edit={
                    (pathname.includes('edit') && admin) ||
                    pathname.includes('profile')
                  }
                />
              </Grid>
            ) : value === 1 ? (
              <Grid item xs={9}>
                <UserPermissions
                  user={user}
                  edit={pathname.includes('edit') && admin}
                />
              </Grid>
            ) : value === 2 ? (
              <Grid item xs={9}>
                <UserLocations
                  user={user}
                  edit={pathname.includes('edit') && admin}
                />
                {admin && id && (
                  <>
                    <br />
                    <UserExistance user={user} resetUser={mutateUser} />
                  </>
                )}
              </Grid>
            ) : null}
          </Grid>
        </Paper>
      </Grid>
    </>
  );

  const inaccessiblePage = <>no</>;

  const page =
    (!isSameUser && admin) || isSameUser ? accessiblePage : inaccessiblePage;

  return (
    <Grid container spacing={2} direction="row">
      {!user?.id ? <Loading /> : page}
    </Grid>
  );
}

export default UserView;
