import { useState, useMemo, FormEvent, useEffect } from 'react';
import Api from '@oneAppCore/services/Api';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import { useSnackbar } from 'notistack';
import {
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { FontAwesome } from '@src/Components/common';
import FitmentApi from '@oneAppCore/services/FitmentApi';
import useVehicleMake from '@src/hooks/swr/useVehicleMake';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  formControl: {
    // margin: theme.spacing(1),
    minWidth: 120,
  },
  label: {
    color: theme.palette.grey[700],
  },
}));

const defaultAlias = [
  'ebayAlias',
  'amazonAlias',
  'wooCommerceAlias',
  'walmartAlias',
];

const years = () => {
  const now = new Date().getUTCFullYear();
  return Array(now - (now - 100))
    .fill('')
    .map((v, idx) => now - idx) as Array<number>;
};

function ModelDrawer(props) {
  const { enqueueSnackbar } = useSnackbar();
  const { mutate } = useSearch();
  const { close, selectedRow = {} } = props;
  const classes = useStyles();
  const [form, setForm] = useState({
    makeId: selectedRow.makeId ? selectedRow.makeId : null,
    model: selectedRow.model ? selectedRow.model : '',
    alias: selectedRow.id ? [
      {
        aliasType: 'ebayAlias',
        aliasValue: selectedRow.ebayAlias ? selectedRow.ebayAlias : ''
      },
      {
        aliasType: 'amazonAlias',
        aliasValue: selectedRow.amazonAlias ? selectedRow.amazonAlias : ''
      },
      {
        aliasType: 'wooCommerceAlias',
        aliasValue: selectedRow.wooCommerceAlias ? selectedRow.wooCommerceAlias : ''
      },
      {
        aliasType: 'walmartAlias',
        aliasValue: selectedRow.walmartAlias ? selectedRow.walmartAlias : ''
      }
    ] : [],
    years: selectedRow.years ? selectedRow.years : selectedRow.modelYears ? selectedRow.modelYears : [],
  });
  const [makeOptions, setMakeOptions] = useState([]);
  const [aliasOptions, setAliasOptions] = useState(defaultAlias);
  const { data: vehicleMakes = [] } = useVehicleMake();

  const setDefaults = async () => {
    setMakeOptions(vehicleMakes);
  }

  useEffect(() => {
    setDefaults();
  }, [])

  const addAlias = () => {
    setForm({ ...form, alias: [...form.alias, { aliasType: '', aliasValue: '' }] });
  }

  const removeAlias = (index) => {
    const updatedAlias = form.alias.filter((_, i) => i !== index);
    setForm({ ...form, alias: updatedAlias });
  }

  const update = (type, value, index = -1) => {
    if (index != -1) {
      const updatedAlias = form.alias.map((al, i) => {
        if (i == index) {
          return {
            ...al,
            [type]: value
          }
        } else {
          return al;
        }
      });
      setForm({ ...form, alias: updatedAlias });
      return;
    }
    setForm({ ...form, [type]: value });
  }

  const checkAliasDisable = (option) => {
    let disable = false;
    for (let index = 0; index < form.alias.length; index++) {
      const element = form.alias[index];
      if (element.aliasType == option) {
        disable = true;
        break;
      }
    }
    return disable;
  }

  const checkSaveDisable = () => {
    if (!form.makeId || !form.model || !form.years) {
      return true;
    }
    return false;
  }

  const handleSubmit = async () => {
    let formData = {
      makeId: form.makeId,
      model: form.model,
      years: form.years
    }
    for (let index = 0; index < form.alias.length; index++) {
      const element = form.alias[index];
      if (element.aliasType && element.aliasValue) {
        formData = { ...formData, [element.aliasType]: element.aliasValue }
      }
    }

    try {
      if (!selectedRow.id) {
        const response = await Api.post(`/api/v1/fitment/create-model`, { form: formData });
        if (response.status == 'success') {
          enqueueSnackbar(`Created Model`, {
            variant: 'success',
          });
        }
      } else {
        const response = await Api.put(`/api/v1/fitment/update-model`, { form: { ...formData, id: selectedRow.id } });
        if (response.status == 'success') {
          enqueueSnackbar(`Updated Model`, {
            variant: 'success',
          });
        }
      }
      mutate();
      close();
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    }
  };

  return (
    <>
      <Grid container spacing={2} direction="column" className={classes.form}>
        <Grid item container justifyContent="center">
          <Typography variant="h4">
            {selectedRow.id ? 'Edit Model' : 'New Model'}
          </Typography>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            component="span"
            className={classes.label}
          >
            Make*
          </Typography>
          <FormControl
            required
            size="small"
            fullWidth
            variant="outlined"
            className={classes.formControl}
          >
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.makeId}
              onChange={(e) => update('makeId', e.target.value)}
            >
              {makeOptions.map((option, oIndex) => (
                <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <Typography variant="caption" component="span" className={classes.label}>Model*</Typography>
          <TextField
            fullWidth
            variant="outlined"
            name="model"
            value={form?.model}
            onChange={(e) => update('model', e.target.value)}
          />
        </Grid>
        <Grid item>
          <Typography variant="caption" component="span" className={classes.label}>Years*</Typography>
          <FormControl required size='small' fullWidth variant="outlined" className={classes.formControl}>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={form.years}
              multiple
              onChange={(e) => update('years', e.target.value)}
            >
              {years().map((year) => {
                return (
                  <MenuItem key={year} value={year}>
                    {year}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        {form.alias.length > 0 && form.alias.map((al, aIndex) => <Grid key={aIndex} container item spacing={1}>
          <Grid item xs={5}>
            <Typography variant="caption" component="span" className={classes.label}>Alias Type</Typography>
            <FormControl required size='small' fullWidth variant="outlined" className={classes.formControl} disabled={selectedRow.id}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={form.alias[aIndex].aliasType}
                onChange={(e) => update('aliasType', e.target.value, aIndex)}
              >
                {aliasOptions.map((option, oIndex) => (
                  <MenuItem disabled={checkAliasDisable(option)} key={option} value={option}>{option}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption" component="span" className={classes.formControl}>Value</Typography>
            <FormControl required size='small' fullWidth variant="outlined" className={classes.formControl} >
              <TextField
                disabled={selectedRow.id}
                fullWidth
                variant="outlined"
                name="aliasValue"
                value={form.alias[aIndex].aliasValue}
                onChange={(e) => update('aliasValue', e.target.value, aIndex)}
              />
            </FormControl>
          </Grid>
          {!selectedRow.id && <Grid item container xs={1}>
            <IconButton
              color="primary"
              onClick={() => {
                removeAlias(aIndex);
              }}
              style={{ paddingLeft: '0', paddingRight: '0' }}
            >
              <FontAwesome
                size="xs"
                name="times"
                type="fa"
                form="fas"
              />
            </IconButton>
          </Grid>}
        </Grid>)}
        {!selectedRow.id && <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              addAlias();
            }}
            style={{ margin: 5 }}
          >
            Add Alias
          </Button>
        </Grid>}
        <Grid item container justifyContent="space-around">
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={close}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              disabled={checkSaveDisable()}
              onClick={() => handleSubmit()}
            >
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export default ModelDrawer;