import { useEffect, useState, Fragment } from 'react';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import {
  Grid,
  Typography,
  Divider,
  ToggleButtonGroup,
  ToggleButton
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportApi from '@oneAppCore/services/ReportApi';
import { Props } from '../types';
import { primary } from '@src/Components/Theme/themes/constants';
import dateUtils from '@oneAppCore/utils/dates';

// import SmallLoader from './SmallLoader';

const useStyles = makeStyles((theme) => ({
  selectedButton: {
    backgroundImage: 'linear-gradient(147deg, #4f46b0 0%, #7970dc 90%)',
    color: theme.palette.common.white,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
    },
  },
  nonSelectedButton: {
    '&:hover': {
      backgroundImage: 'linear-gradient(147deg, #4ea3de 0%, #1a8cdb 74%)',
      color: theme.palette.common.white,
    },
  },
  responsiveUi: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '5px',
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.up('md')]: {},
    marginTop: 10,
    minWidth: 'max-content',
    overflow: 'wrap',
  },
}));

function TopCategories({ unitsBack, unitType }: Props) {
  const classes = useStyles();
  const [cardData, setCardData] = useState(null);
  const [profitData, setProfitData] = useState(null);
  const [pastProfit, setPastProfit] = useState(null);
  const [revData, setRevData] = useState(null);
  const [pastRev, setPastRev] = useState(null);
  const [pastCount, setPastCount] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [options, setOptions] = useState('all');
  const [prevDays, setPrevDays] = useState(0);

  const handleToggle = (e, newValue: string) => {
    setOptions(newValue);
  };

  const getCount = async (newerThan, newerThan2) => {

    const response = await ReportApi.get('TOP_CATEGORIES', {
      newerThan,
      page,
      limit,
    });

    const pastResponse = await ReportApi.get('TOP_CATEGORIES', {
      newerThan: newerThan2,
      olderThan: newerThan,
      page: 1,
      limit: 5,
    });
    setCardData(response);
    setPastCount(pastResponse);
  };

  const getProfit = async (newerThan, newerThan2) => {

    const response = await ReportApi.get('TOP_CATEGORIES_PROFIT', {
      newerThan,
      page,
      limit,
    });

    const pastResponse = await ReportApi.get('TOP_CATEGORIES_PROFIT', {
      newerThan: newerThan2,
      olderThan: newerThan,
      page: 1,
      limit: 5,
    });
    setProfitData(response);
    setPastProfit(pastResponse);
  };

  const getRevenue = async (newerThan, newerThan2) => {

    const response = await ReportApi.get('TOP_CATEGORIES_REV', {
      newerThan,
      page,
      limit,
    });

    const pastResponse = await ReportApi.get('TOP_CATEGORIES_REV', {
      newerThan: newerThan2,
      olderThan: newerThan,
      page: 1,
      limit: 5,
    });

    setRevData(response);
    setPastRev(pastResponse);
  };

  useEffect(() => {
    const { newerThan, newerThan2, daysPrev } = dateUtils.getDaysBack({ unitsBack, unitType });
    setPrevDays(daysPrev);
    if (options?.includes('all')) {
      getProfit(newerThan, newerThan2);
      getRevenue(newerThan, newerThan2);
      getCount(newerThan, newerThan2);
    } else if (options?.includes('profit')) {
      getProfit(newerThan, newerThan2);
    } else if (options?.includes('revenue')) {
      getRevenue(newerThan, newerThan2);
    } else if (options?.includes('count')) {
      getCount(newerThan, newerThan2);
    }
  }, [unitsBack, options]);

  return (
    <Grid container xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Grid item xs={12} md={12} lg={12} xl={6}>
        <Typography variant="h5" style={{ color: '#616161', marginBottom: 10 }}>
          Top 5 Categories
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={12}
        lg={12}
        xl={6}
        style={{ justifyContent: 'center' }}
      >
        <ToggleButtonGroup
          size="small"
          value={options}
          exclusive
          onChange={handleToggle}
        >
          <ToggleButton
            className={
              options?.includes('profit') || options?.includes('all')
                ? classes.selectedButton
                : classes.nonSelectedButton
            }
            // onClick={() => setIsCount(false)}
            value={'profit'}
          >
            Profit
          </ToggleButton>
          <ToggleButton
            value="count"
            className={
              // isCount ? classes.selectedButton : classes.nonSelectedButton
              options?.includes('count')
                ? classes.selectedButton
                : classes.nonSelectedButton
            }
          >
            Count
          </ToggleButton>
          <ToggleButton
            value="revenue"
            className={
              options?.includes('revenue')
                ? classes.selectedButton
                : classes.nonSelectedButton
            }
          >
            Revenue
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid key="top-categories-header" container style={{ marginTop: 10 }}>
        <Grid item xs={6}>
          <Typography variant="h6">Category</Typography>
        </Grid>

        <Grid item xs={3}>
          {(options === 'profit' || options === 'all') && (
            <Typography variant="h6">Profit</Typography>
          )}
          {options === 'count' && <Typography variant="h6">Count</Typography>}
          {options === 'revenue' && (
            <Typography variant="h6">Revenue</Typography>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">Previous</Typography>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Divider style={{ background: primary.main, marginBottom: 5 }} />
      </Grid>
      {(options === 'profit' || options === 'all') &&
        profitData?.rows.map((elem, index) => {
          const pastProfitValue = pastProfit?.rows[index]?.profit ?? 0;

          const textColor =
            elem.profit > pastProfitValue
              ? 'green'
              : elem.profit < pastProfitValue
                ? 'red'
                : 'inherit';
          return (
            <Fragment key={`top-categories-${index}`}>
              <Grid

                container
                style={{
                  marginTop: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={5}>
                  <Typography key={`${index}-top-category-id`}>
                    {elem.categoryName}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  xs={3}
                  style={{ justifyContent: 'center' }}
                >
                  <Typography style={{ color: textColor }}>
                    {formatCentsToDollarsIntl(elem.profit)}
                  </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="center">
                  <Typography>
                    {formatCentsToDollarsIntl(pastProfitValue)}
                  </Typography>
                </Grid>
                {profitData?.response?.rows?.length !== index + 1 && (
                  <Grid xs={12}>
                    <Divider style={{ marginTop: 5 }} />
                  </Grid>
                )}
              </Grid>
            </Fragment>
          );
        })}
      {options === 'count' &&
        cardData?.rows.map((elem, index) => {
          // Determine the corresponding past count for the current row
          const pastCountValue = pastCount?.rows[index]?.count ?? 0;

          // Determine the color based on comparison
          const textColor =
            elem.count > pastCountValue
              ? 'green'
              : elem.count < pastCountValue
                ? 'red'
                : 'inherit';

          return (
            <Grid
              key={`top-categories-${index}`}
              container
              style={{
                marginTop: 10,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <Grid item xs={5}>
                <Typography key={`${index}-top-category-id`}>
                  {elem.categoryName}
                </Typography>
              </Grid>
              <Grid item container xs={3} justifyContent="center">
                <Typography style={{ color: textColor }}>
                  {elem.count}
                </Typography>
              </Grid>
              <Grid item container xs={3} justifyContent="center">
                <Typography>{pastCountValue}</Typography>
              </Grid>
              {cardData?.response?.rows?.length !== index + 1 && (
                <Grid xs={12}>
                  <Divider style={{ marginTop: 5 }} />
                </Grid>
              )}
            </Grid>
          );
        })}
      {options === 'revenue' &&
        revData?.rows.map((elem, index) => {
          const pastRevData = pastRev?.rows[index]?.revenue ?? 0;
          const textColor =
            elem.revenue > pastRevData
              ? 'green'
              : elem.revenue < pastRevData
                ? 'red'
                : 'inherit';
          return (
            <>
              <Grid
                key={`top-categories-${index}`}
                container
                style={{
                  marginTop: 10,
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <Grid item xs={5}>
                  <Typography key={`${index}-top-category-id`}>
                    {elem.categoryName}
                  </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="center">
                  <Typography style={{ color: textColor }}>
                    {formatCentsToDollarsIntl(elem.revenue)}
                  </Typography>
                </Grid>
                <Grid item container xs={3} justifyContent="center">
                  <Typography>
                    {formatCentsToDollarsIntl(pastRevData)}
                  </Typography>
                </Grid>
              </Grid>
              {revData?.response?.rows?.length !== index + 1 && (
                <Grid xs={12}>
                  <Divider style={{ marginTop: 5 }} />
                </Grid>
              )}
            </>
          );
        })}
    </Grid>
  );
}

export default TopCategories;
