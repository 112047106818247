import { useState } from 'react';
import { useSnackbar } from 'notistack';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import Api from '@oneAppCore/services/Api';

type Props = {
  poId: number,
  poNumber: number,
  openDialog?: boolean,
  handlePoClose?: any,
}

function ClosePoDialog({ poId, poNumber, openDialog, handlePoClose }: Props) {
  const [clicked, setClicked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const closePurchaseOrder = async () => {
    setClicked(true);
    try {
      const updateObj = {
        poId,
        internalRestockData: [],
      };
      const updateRes = await Api.put(`/api/v1/manufacturer-suppliers-restock/close-po`, updateObj);
      if (updateRes.id) {
        enqueueSnackbar(`Closed #PO ${poNumber}`, {
          variant: 'success',
        });
      };
    } catch (error) {
      enqueueSnackbar(`Error closing #PO`, {
        variant: 'error',
      });
    } finally {
      {
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setClicked(false);
    handlePoClose();
  };

  return (
    <>
      <Dialog open={openDialog} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Confirm #PO Close</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Looks like all items are scanned, do you want to mark this #PO as closed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            color="secondary"
            onClick={() => handleClose()}
          >
            No
          </Button>
          <Button type="submit" disabled={clicked} color="primary" onClick={() => closePurchaseOrder()}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ClosePoDialog;
