import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';

import type { OrderOperations } from './types';

type operationResult = {
  rows: OrderOperations[],
  suppliers: any[],
  channels: any[]
}

export default function useOrderProfitOperations() {
  return useSWR<operationResult>(
    `/api/v1/reports/operations/orderProfit`,
    Api.get,
  );
}
