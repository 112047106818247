import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Tooltip, Box } from '@mui/material';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';
import ReplayTwoToneIcon from '@mui/icons-material/ReplayTwoTone';

import BrandDrawer from '@src/Components/Products/Brands/BrandDrawer';
import recoilSearch from '../../hooks/useSearchOptions';
import SearchFilters from '../SearchFilters';
import DownloadButton from './DownloadButton';
import ExportButton from './ExportButton';
import BatchPrintButton from './BatchPrintButton';
import ApproveButton from './ApproveButton';
import RefreshButton from './RefreshButton';
import CreationDrawer from '../../CreationDrawer';
import RestockButton from './RestockButton';

import type { Props } from './types';
import { display } from '@mui/system';

const SearchBar = ({
  supportsDownload,
  showChannelOptions,
  filters,
  constantFilters,
  exportOptions,
  selectedRows,
  select,
  selectTypes,
  deselectAllRows,
  carrierData,
  selectRow,
  customComponents,
  me,
  resetPagination,
  setResetPagination,
  selectedFilterValues,
  setSelectedFilterValues,
  showInventoryOptions,
  showGridEdit,
  hasUnsavedRows,
  undo,
  saveChanges,
}: Props) => {
  const [searchState, setSearchState] = recoilSearch.useState();
  const [currentPage, setCurrentPage] = useState(1);
  const history = useHistory();

  const update = (limit: number) => {
    setSearchState((previous) => ({
      ...previous,
      limit,
    }));
  };

  const newUser = selectTypes?.includes('create') && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => history.push('/admin/users/create')}
      size="small"
    >
      Create
    </Button>
  );

  const newCompany = selectTypes?.includes('createCompany') && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => history.push('/admin/companies/create')}
      size="small"
    >
      Create
    </Button>
  );

  const newSupplier = selectTypes?.includes('createSupplier') && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => history.push('/suppliers/create')}
      size="small"
    >
      Create
    </Button>
  );

  const newStoreChannel = selectTypes?.includes('createSChannel') && (
    <Button
      variant="contained"
      color="primary"
      onClick={() => history.push('/store-channel/create')}
      size="small"
    >
      Create
    </Button>
  );

  const newProduct = selectTypes?.includes('createProduct') && (
    <Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history.push('/products/create')}
        size="small"
      >
        Create
      </Button>
    </Grid>
  );

  // const dayChange = selectTypes?.includes('')

  const brandCreation = selectTypes?.includes('createBrand') && (
    <CreationDrawer Form={BrandDrawer} />
  );

  // const inventoryCreation = selectTypes?.includes('createInventory') && (
  //   <CreationDrawer Form={InventoryDrawer} />
  // );

  const download = supportsDownload && <DownloadButton />;
  // const exports = exportOptions && (
  //   <ExportButton
  //     selectedRows={selectedRows}
  //     deselectAllRows={deselectAllRows}
  //     selectRow={selectRow}
  //     exportOptions={exportOptions}
  //   />
  // );

  const exports = select && selectTypes?.includes('bulkExport') && (
    <ExportButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
      selectRow={selectRow}
      exportOptions={exportOptions}
    />
  );
  const batchPrint = select && selectTypes?.includes('batch-print') && (
    <BatchPrintButton
      carrierData={carrierData}
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
      selectRow={selectRow}
      me={me}
    />
  );

  const approveButton = select && selectTypes.includes('approve') && (
    <ApproveButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
    />
  );
  const refreshButton = select && selectTypes.includes('refresh') && (
    <RefreshButton update={update} />
  );

  const restockButton = select && selectTypes.includes('restock') && (
    <RestockButton
      selectedRows={selectedRows}
      deselectAllRows={deselectAllRows}
    />
  );

  const gridEditControls = hasUnsavedRows ? (
    <>
      <Grid item style={{ marginLeft: 10 }}>
        <Tooltip title={'Save Changes'}>
          <div
            style={{
              color: 'rgb(94, 53, 177)',
              background: 'rgb(237, 231, 246',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              width: '34px',
              height: '34px',
              fontSize: '1.2rem',
              transition: 'all 0.2s ease-in-out 0s',
              cursor: 'pointer',
            }}
            onClick={() => saveChanges()}
          >
            <SaveTwoToneIcon />
          </div>
        </Tooltip>
      </Grid>
      <Grid item style={{ marginLeft: 10 }}>
        <Tooltip title={'Discard Changes'} onClick={undo}>
          <div
            style={{
              color: 'rgb(94, 53, 177)',
              background: 'rgb(237, 231, 246',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              width: '34px',
              height: '34px',
              fontSize: '1.2rem',
              transition: 'all 0.2s ease-in-out 0s',
              cursor: 'pointer',
            }}
          >
            <ReplayTwoToneIcon />
          </div>
        </Tooltip>
      </Grid>
    </>
  ) : (
    <>
      <Grid item style={{ marginLeft: 10 }}>
        <Tooltip title={'Save Changes'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '34px',
              height: '34px',
              fontSize: '1.2rem',
              transition: 'all 0.2s ease-in-out 0s',
              cursor: 'pointer',
            }}
          >
            <SaveTwoToneIcon style={{ color: 'gray' }} />
          </div>
        </Tooltip>
      </Grid>
      <Grid item style={{ marginLeft: 10 }}>
        <Tooltip title={'Discard Changes'}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '34px',
              height: '34px',
              fontSize: '1.2rem',
              transition: 'all 0.2s ease-in-out 0s',
              cursor: 'pointer',
            }}
          >
            <ReplayTwoToneIcon style={{ color: 'gray' }} />
          </div>
        </Tooltip>
      </Grid>
    </>
  );

  // const channelTabs = showChannelOptions && <ChannelOptions />;

  const bulkActions = select ? (
    <>
      <Grid
        container
        columnSpacing={2}
        xs={4}
        width="fit-content"
        alignContent="center"
        justifyContent="flex-start"
      >
        {download}
        {/* {exports} */}
        {batchPrint}
        {approveButton}
        {restockButton}
        {refreshButton}
        {/* {supportsDownload && <DownloadButton />} */}
        {exports}

        {/* {exportOptions && (
        <ExportButton
          exportOptions={exportOptions}
          selectedRows={selectedRows}
          deselectAllRows={deselectAllRows}
          selectRow={selectRow}
        />
      )} */}
      </Grid>
    </>
  ) : (
    <></>
  );

  return (
    <Grid
      container
      spacing={3}
      style={{
        margin: 8,
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <Grid item xs={12} md={8} container spacing={2} alignItems="center">
        {bulkActions}

        {!select && download}
        {newUser}
        {newStoreChannel}
        {newProduct}
        {newCompany}
        {newSupplier}
        {/* {inventoryCreation} */}
        {brandCreation}
        {/* {channelTabs} */}
        {customComponents}
        {showGridEdit ? gridEditControls : null}
      </Grid>
      <Grid item xs={12} md={4}>
        {filters && (
          <SearchFilters
            // resetPagination={resetPagination}
            setResetPagination={setResetPagination}
            filters={filters}
            constantFilters={constantFilters}
            selectedFilterValues={selectedFilterValues}
            setSelectedFilterValues={setSelectedFilterValues}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default SearchBar;
