import SearchViewMiniReport from '@src/Components/common/containers/SearchViewMiniReport';
import { Grid, Paper, Typography, Tooltip } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import Api from '@oneAppCore/services/Api';
import { useEffect, useState } from 'react';
import { EllipseLoader } from '@src/Components/common/Loaders';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(2),
      height: '100%',
    },
    number: {
      color: theme.palette.purple.main,
      fontWeight: 700,
    },
  }),
);

function Created(props: any) {
  const classes = useStyles();
  // const [createdByCount, setcreatedByCount] = useState(0);


  // useEffect(() => {
  //   const getCreatedCount = async () => {
  //     const count = await Api.get(`/api/v1/store-listings/search`).then(
  //       (res) => {
  //         const { createdByCount } = res;
  //         setcreatedByCount(createdByCount);
  //       },
  //     );
  //   };
  //   getCreatedCount();
  // }, []);

  return (
    <SearchViewMiniReport>
      <Tooltip placement="top" title="Listings created by current user">
        <Grid
          item
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Typography variant="body2">Listings Created</Typography>
          </Grid>
          <Grid item>
            <Typography variant="h2" className={classes.number}>

              {Number(props.createdByCount).toLocaleString()}

              {/* {Number(
                createdByCount || <EllipseLoader style={{ display: 'flex' }} />,
              ).toLocaleString()} */}
            </Typography>
          </Grid>
        </Grid>
      </Tooltip>
    </SearchViewMiniReport>
  );
}

export default Created;
