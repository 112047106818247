import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';
import { useParams } from 'react-router-dom';

import type {
  KeywordOptionType,
  ProductHookParams,
  ProductResponse,
  Product,
} from '@oneAppCore/types/productTypes';

function useProductKeywords() {
  return useSWR<(string | KeywordOptionType)[]>(
    `/api/v1/products/keywords`,
    Api.get,
  );
}
function useProductData() {
  const { id } = useParams<ProductHookParams>();

  return useSWR<any>(
    id && !Number.isNaN(id) ? `/api/v1/products/${id}` : null,
    Api.get,
    {
      revalidateOnFocus: true,
      shouldRetryOnError: false,
    },
  );
}

function useProducts() {
  return useSWR<Product[]>(`/api/v1/products`, Api.get);
}

export default {
  useProductKeywords,
  useProductData,
  useProducts,
};
