// import { Typography } from '@mui/material';
// import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

// export const supplierColumns = (discountData, varRow) => [
//   {
//     title: 'Image',
//     keyName: 'imageUrl',
//     customComponent: function image(row) {
//       return <img src={row.imageUrl?.[0]} height={45} width={45} />;
//     },
//   },
//   {
//     title: 'Title',
//     keyName: 'variation',
//     customComponent: function title(row) {
//       return row.variation ? (
//         <Typography>{row.variation}</Typography>
//       ) : (
//         <Typography>{row.name}</Typography>
//       );
//     },
//   },
//   {
//     title: 'SKU',
//     keyName: 'sku',
//     // customComponent: function sku() {
//     //   return <Typography>{varRow.sku}</Typography>;
//     // },
//   },
//   {
//     title: 'MPN',
//     keyName: 'manufacturerNo',
//     // customComponent: function mpn() {
//     //   return <Typography>{varRow.manufacturerNo}</Typography>;
//     // },
//   },
//   {
//     title: 'Supplier',
//     keyName: 'supplier',
//   },
//   {
//     title: 'Cost',
//     keyName: 'cost',
//     customComponent: function costData(row) {
//       console.log(row, 'rowww');
//       let costAfterDiscount = row.cost;
//       let discount;

//       // if (discountData.brands) {
//       //   discount = discountData.brands.find((dc) => dc.supplierId === row.supplierId);
//       // } else if (discountData.prods) {
//       //   discount = discountData.prods.find((dc) => dc.supplierId === row.supplierId);
//       // } else if (discountData.vars) {
//       //   discount = discountData.vars.find((dc) => dc.supplierId === row.supplierId);
//       // }
//       // if (discount) {
//       //   if (discount.type === 'p') {
//       //     costAfterDiscount = Math.ceil(row.cost * ((100 - discount.discount) / 100));
//       //   } else if (discount.type === 'f') {
//       //     costAfterDiscount = row.cost - discount.discount;
//       //   } else if (discount.type === 'd') {
//       //     costAfterDiscount = discount.discount;
//       //   }
//       // }
//       // if (row.discount) {
//       //   if (row.type) {
//       //     costAfterDiscount = Math.floor(row.cost * ((100 - row.discount) / 100));
//       //   } else {
//       //     costAfterDiscount = row.cost - (row.discount * 100);
//       //   }
//       // }
//       return (
//         <div>
//           {formatCentsToDollarsIntl(costAfterDiscount)}
//           <br />
//           {row.discount
//             ? row.percent
//               ? `- ${row.discount}%`
//               : `- $${row.discount}`
//             : ''}
//         </div>
//       );
//       // const perc = (row.discount / 100) * (row.cost / 100);
//       // const percentAmount = row.cost / 100 - perc;
//       // const flatAmount = row.cost / 100 - row.discount;
//       // if (row.percent !== null) {
//       //   if (row.percent === true) {
//       //     return <div>${percentAmount.toFixed(2)}</div>;
//       //   } else {
//       //     return <div>${flatAmount.toFixed(2)}</div>;
//       //   }
//       // } else {
//       //   return (
//       //     <div>
//       //       ${(row.cost / 100).toFixed(2) ? (row.cost / 100).toFixed(2) : 0}
//       //     </div>
//       //   );
//       // }
//     },
//   },
//   {
//     title: 'Quantity',
//     keyName: 'quantity',
//   },
//   {
//     title: 'Shipping Cost',
//     keyName: 'shippingCost',
//     customComponent: function shippingCost(row) {
//       return (
//         <div>
//           $
//           {(row.shippingCost / 100).toFixed(2)
//             ? (row.shippingCost / 100).toFixed(2)
//             : 0}
//         </div>
//       );
//     },
//   },
//   { title: 'Edit', keyName: 'Edit' },
// ];

import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';

export const supplierColumns = (discountData) => [
  {
    title: 'Supplier',
    keyName: 'supplier',
  },
  {
    title: 'Cost',
    keyName: 'cost',
    customComponent: function costData(row) {
      let costAfterDiscount = row.cost;
      let discount;

      // if (discountData.brands) {
      //   discount = discountData.brands.find((dc) => dc.supplierId === row.supplierId);
      // } else if (discountData.prods) {
      //   discount = discountData.prods.find((dc) => dc.supplierId === row.supplierId);
      // } else if (discountData.vars) {
      //   discount = discountData.vars.find((dc) => dc.supplierId === row.supplierId);
      // }
      // if (discount) {
      //   if (discount.type === 'p') {
      //     costAfterDiscount = Math.ceil(row.cost * ((100 - discount.discount) / 100));
      //   } else if (discount.type === 'f') {
      //     costAfterDiscount = row.cost - discount.discount;
      //   } else if (discount.type === 'd') {
      //     costAfterDiscount = discount.discount;
      //   }
      // }
      // if (row.discount) {
      //   if (row.type) {
      //     costAfterDiscount = Math.floor(row.cost * ((100 - row.discount) / 100));
      //   } else {
      //     costAfterDiscount = row.cost - (row.discount * 100);
      //   }
      // }
      return (
        <div>
          {formatCentsToDollarsIntl(costAfterDiscount)}
          <br />
          {row.discount
            ? row.percent
              ? `- ${row.discount}%`
              : `- $${row.discount}`
            : ''}
        </div>
      );
      // const perc = (row.discount / 100) * (row.cost / 100);
      // const percentAmount = row.cost / 100 - perc;
      // const flatAmount = row.cost / 100 - row.discount;
      // if (row.percent !== null) {
      //   if (row.percent === true) {
      //     return <div>${percentAmount.toFixed(2)}</div>;
      //   } else {
      //     return <div>${flatAmount.toFixed(2)}</div>;
      //   }
      // } else {
      //   return (
      //     <div>
      //       ${(row.cost / 100).toFixed(2) ? (row.cost / 100).toFixed(2) : 0}
      //     </div>
      //   );
      // }
    },
  },
  {
    title: 'Quantity',
    keyName: 'quantity',
  },
  {
    title: 'Shipping Cost',
    keyName: 'shippingCost',
    customComponent: function shippingCost(row) {
      return (
        <div>
          $
          {(row.shippingCost / 100).toFixed(2)
            ? (row.shippingCost / 100).toFixed(2)
            : 0}
        </div>
      );
    },
  },
  { title: 'Edit', keyName: 'Edit' },
];
