import BaseApi from '../BaseApi';

import { BrandForEdit } from 'types/brandTypes';
import type { Search } from 'types/searchTypes';

interface SearchResponse {
  rows: BrandForEdit[];
  count: number;
}

class BrandApi extends BaseApi {
  constructor() {
    super();
  }

  put(id: number, payload: BrandForEdit) {
    return this.putBase<BrandForEdit>(
      `/api/v1/brands/${id}`,
      payload,
    );
  }

  post(payload: BrandForEdit) {
    return this.postBase<BrandForEdit>(
      `/api/v1/brands`,
      payload,
    );
  }

  getById(id: number) {
    return this.getBase<BrandForEdit>(`/api/v1/brands/${id}`);
  }

  search(payload: Partial<Search>) {
    return this.getBase<SearchResponse>(`/api/v1/brands/search`, payload);
  }
}

export default BrandApi;
