import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import PieChart from '@src/Components/common/charts/PieChart';

import ReportApi from '@oneAppCore/services/ReportApi';
import dateUtils from '@oneAppCore/utils/dates';

import { Props } from '../types';

function PlacedByChart({ unitsBack, unitType }: Props) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      
      const response = await ReportApi.get('ORDERS_COMPLETED_BY', {
        newerThan,
        page: 1,
        limit: 10000,
      });
      const rows = response.rows.filter((item) => item.Count > 0).reduce(
        (acc, row) => ({
          ...acc,
          [row.Name]: Number(row.Count),
        }),
        {},
      );
      setData(Object.values(rows));
      setLabels(Object.keys(rows));
    })();
  }, [unitsBack]);

  return (
    <Grid
      container
      direction="column"
      spacing={2}
    >
      <Grid
        alignItems="center"
        justifyContent="center"
        item
        container
      >
        <Typography variant="h5">Orders Placed By</Typography>
      </Grid>
      <Grid item xs={12} >
        <PieChart
          labels={labels}
          data={data}
          title="Placed By"
          plugins={{
            legend: {
              display: true,
              position: 'right',
              labels: {
                usePointStyle: true,
              },
            }
          }}
        />
      </Grid>
    </Grid>
  );
}

export default PlacedByChart;
