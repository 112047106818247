import { useState, FormEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Api from '@oneAppCore/services/Api';

import {
  Table,
  TablePagination,
  Card,
  CircularProgress,
  Button,
  Grid,
  Chip,
  Divider,
} from '@mui/material';
// import TablePagination from '@src/Components/common/ListView/DataList/DataTable/TablePagination';

import type { Form, Params } from '../types';
import type { Makes, Models } from '../../NewProductForm/types';
import type { GroupForm } from '../types';
import { columns } from './constants';
import TableHeader from './TableHeader';
import TableRows from './TableRows';
import AddGroupedProduct from './AddGroupedProduct';
import GroupBox from './GroupBox';
import ItemGroupsApi from '@oneAppCore/services/ItemGroupsApi';
import { FitmentForm, ManufacturerSupplierForm } from '../types';
import useGroupData from '@src/Components/Inventory/Management/hooks/useGroupData';
import { FontAwesome } from '@src/Components/common';
import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi/ManufacturerSuppliersApi';
import FitmentApi from '@oneAppCore/services/FitmentApi';
import useVariationData from '@src/hooks/swr/Inventory/useVariationData';

function GroupedTable({
  row,
  rows,
  updateList,
  defaultList,
  // rowsPerPage,
  mutate,
  product,
  updateProductForm,
  props,
  cellIndex,
}: {
  row?: any;
  // rowsPerPage?: number;
  props?: any;
  rows?: any;
  updateList?: any;
  defaultList?: any;
  mutate?: any;
  product?: Form;
  updateProductForm: any;
  cellIndex?: number;
}) {
  const { id: paramId } = useParams<Params>();

  const [form, setForm] = useState<any[]>(defaultList);
  const [suppliers, setSuppliers] = useState<ManufacturerSupplierForm[]>([]);
  const { data: groups, mutate: mutateGroup } = useGroupData(Number(paramId));
  const [fitment, setFitment] = useState<FitmentForm[][]>([[]]);
  const [makes, setMakes] = useState<Makes[]>([]);
  const [models, setModels] = useState<Models[]>([]);
  const [openedType, setOpenedType] = useState<string>('fitment');
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [openedCell, setOpenedCell] = useState<number>();
  const [hasRendered, setHasRendered] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const { data: variations, mutate: mutateVar } = useVariationData({
    id: Number(paramId),
  });
  const rowsPerPage = 5;

  const handleChangePage = (newPage: number) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    const changeCount = async () => {
      if (form?.length && form?.length / rowsPerPage !== totalPages) {
        setTotalPages(Math.ceil(form?.length / rowsPerPage));
      }
    };
    changeCount();
  }, [form?.length]);

  useEffect(() => {
    const getMakesAndModels = async () => {
      const { rows: make } = await FitmentApi.getMakes();
      const { rows: model } = await FitmentApi.getModels();
      setMakes(make);
      setModels(model);
    };
    getMakesAndModels();
    const updatedGroup =
      (groups?.group && Array.isArray(groups?.group)
        ? groups?.group?.slice()
        : form?.slice()) || [];
    if (updatedGroup.length < 1) {
      updatedGroup.push({});
    }
    setForm(updatedGroup);
  }, [groups]);
  const addSuppliers = (groupIndex: number) => {
    const updatedGroup = form?.slice() || [];
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers.push({ supplierId: 0 });
    updatedGroup[groupIndex].suppliers = updatedSuppliers;
    setSuppliers(updatedSuppliers);
    updateProductForm('group', updatedGroup);
  };

  const removeSuppliers = (index: number, groupIndex: number) => {
    const updatedGroup = form?.slice() || [];
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers.splice(index, 1);
    updatedGroup[groupIndex].suppliers = updatedSuppliers;
  };

  const updateSuppliers = <K extends keyof ManufacturerSupplierForm>(
    index: number,
    key: K,
    value: ManufacturerSupplierForm[K],
    groupIndex: number,
  ) => {
    const updatedGroup = form?.slice() || [];
    const updatedSuppliers = suppliers.slice();
    updatedSuppliers[index] = {
      ...updatedSuppliers[index],
      [key]: value,
    };
    updatedGroup[groupIndex].suppliers = updatedSuppliers;
    setSuppliers(updatedSuppliers);
    updateProductForm('group', updatedGroup);
  };

  const updateFitment = (
    index: number,
    second: number,
    key: any,
    value: FitmentForm,
  ) => {
    const updatedGroup = form?.slice() || [];
    const newFitment = fitment?.slice() || [];
    newFitment[index][second][key] = value;
    updatedGroup[index].fitment = newFitment[index];
    setFitment(newFitment);
  };

  const addProduct = () => {
    const updatedGroup = form?.slice() || [];
    updatedGroup.push({ variationId: 0, quantity: 1 });
    updateList(updatedGroup, 'group');
    setForm(updatedGroup);
    // updateProductForm('groups', updatedGroup);
  };

  const removeProduct = (index: number) => {
    const updatedGroup = form?.slice() || [];
    updatedGroup.splice(index, 1);
    updateList(updatedGroup, 'group');
    setForm(updatedGroup);
    // updateProductForm('groups', updatedGroup);
  };

  const setOpen = (cell: number, type: string = 'fitment') => {
    setOpenedCell(cell);
    setOpenedType(type);
  };

  const onChange = <K extends keyof GroupForm>(
    index: number,
    key: K,
    value: GroupForm[K],
  ) => {
    const updatedGroup = form?.slice() || [];
    updatedGroup[index] = {
      ...updatedGroup[index],
      [key]: value,
    };
    setForm(updatedGroup);
    updateProductForm('groups', updatedGroup);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
  };

  return (
    <>
      <form onSubmit={(e) => handleSubmit(e)}>
        <Card style={{ overflow: 'visible' }}>
          {groups ? (
            <Table aria-label="collapable table">
              <TableHeader columns={columns} />
              <TableRows
                columns={columns}
                rows={groups}
                openedType={openedType}
                setOpenedCell={setOpen}
                mutate={mutateGroup}
                page={page}
              // suppliers={supps}
              />
              {Boolean(form?.length) &&
                form
                  .map((item, index) => ({ item, index }))
                  .filter(({ item }) => !(item.name && item.variation))
                  .map(({ item: row, index: i }) => {
                    return (
                      <>
                        <AddGroupedProduct
                          key={`add-group-to-existing-${i}`}
                          group={row}
                          index={i}
                          groups={groups}
                          productIndex={form.length - 1}
                          columns={columns}
                          row={row}
                          mutate={mutateVar}
                          mutateGroup={mutateGroup}
                          removeProduct={removeProduct}
                          onChange={onChange}
                        />
                      </>
                    );
                  })}
              {paramId && (
                <Grid
                  item
                  container
                  justifyContent="flex-start"
                  style={{ marginTop: 10 }}
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={addProduct}
                    style={{ marginLeft: 10 }}
                  >
                    Add Product
                  </Button>
                </Grid>
              )}
              <TablePagination
                // currentPage={currentPage}
                // setCurrentPage={handleChangePage}
                // totalPages={totalPages}
                count={form.length}
                rowsPerPage={rowsPerPage}
                rowsPerPageOptions={[rowsPerPage]}
                page={page}
                onPageChange={(e, page) => {
                  setPage(page);
                }}
              />
            </Table>
          ) : (
            <>
              {Boolean(form?.length) &&
                form.map((row, i) => (
                  <>
                    <Grid xs={12} container>
                      <Grid item xs={11}>
                        <GroupBox
                          index={i}
                          group={row}
                          onChange={onChange}
                          row={row}
                          mutate={mutateGroup}
                          cellIndex={cellIndex}
                        />
                      </Grid>
                      <Grid
                        item
                        container
                        xs={1}
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Button
                          variant="contained"
                          style={{ backgroundColor: '#B61F1F' }}
                          onClick={() => removeProduct(i)}
                          size="small"
                        >
                          <FontAwesome
                            name="times"
                            type="fa"
                            form="fas"
                            style={{ color: '#FFFFFF' }}
                          />
                        </Button>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider style={{ marginTop: 10 }} />
                      </Grid>
                    </Grid>
                  </>
                ))}
              {!paramId && (
                <Grid
                  item
                  container
                  xs={12}
                  justifyContent="flex-start"
                  style={{ marginTop: 10 }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    onClick={addProduct}
                    style={{ margin: 10 }}
                  >
                    Add Product
                  </Button>
                </Grid>
              )}
            </>
          )}
        </Card>
      </form>
    </>
  );
}

export default GroupedTable;
