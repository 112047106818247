import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
dayjs.extend(customParseFormat);

import { colorTheme } from '@oneAppCore/constants/channels';
import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import BarChart from '@src/Components/common/charts/BarChart';
import ReportApi from '@oneAppCore/services/ReportApi';

import { Props } from '../types';
import { unitDateLabel } from '../utilities';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) => ({
  width: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  height: {},
}));

function UnitsSoldChart({ unitsBack, unitType, supplierId }: Props) {
  const [data, setData] = useState<any[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [lineData, setLineData] = useState<any[]>([]);
  const [today, setToday] = useState(false);
  const [yesterday, setYesterday] = useState(false);
  const theme = createTheme(colorTheme);
  const classes = useStyles();
  console.log(data, labels, 'testing');

  useEffect(() => {
    (async () => {
      let days = 13;
      let unit: dayjs.ManipulateType = 'days';

      if (unitsBack || (!unitsBack && unitsBack === 0)) days = unitsBack;
      if (unitType) unit = unitType as dayjs.ManipulateType;

      setData([]);
      const todayDate = dayjs().startOf('day');
      const yesterday = todayDate.subtract(1, 'day');
      const newerThan = todayDate.subtract(days, unit).toISOString();

      const response = await ReportApi.get('ORDERS_BY_CHANNEL_BAR', {
        newerThan,
        page: 1,
        limit: 1000000,
        supplierId,
      });
      console.log(response, 'caroline');

      const rowsToday = response.rows;
      const lastWeek = response.last;

      const isToday = rowsToday.some((row) => 'hour' in row);
      setToday(isToday);

      const isYesterday = rowsToday.some((row) =>
        dayjs(row.hour).isSame(yesterday, 'day'),
      );
      setYesterday(isYesterday);

      if (isToday || isYesterday) {
        const hours = Array.from({ length: 24 }, (_, i) => {
          const hour = i % 12 || 12;
          const period = i < 12 ? 'AM' : 'PM';
          return `${hour.toString().padStart(2, '0')}:00 ${period}`;
        });

        const channelsToday = Array.from(
          new Set(rowsToday.map((row) => row['Channel Name'])),
        );

        const channelsLast = Array.from(
          new Set(lastWeek?.map((row) => row['Channel Name'])),
        );

        const orderedRows = channelsToday.map((channel) => ({
          data: hours.map((formattedHour) => {
            const found = rowsToday.find((row) => {
              const rowHour = dayjs(row.hour).format('hh:00 A');
              return (
                rowHour === formattedHour && row['Channel Name'] === channel
              );
            });
            return found ? Number(found.Count) : 0;
          }),
          label: channel,
          backgroundColor: theme.palette[channel]?.main || 'rgba(0, 0, 0, 0.1)',
          hoverOffset: 5,
        }));
        const lineDataLastWeek = channelsLast?.map((channel) => ({
          data: hours.map((formattedHour) => {
            const found = lastWeek.find((row) => {
              const rowHour = dayjs(row.hour).format('hh:00 A');
              return (
                rowHour === formattedHour && row['Channel Name'] === channel
              );
            });
            return found ? Number(found.Count) : 0;
          }),
          label: `${channel} (Last Week)`,
          backgroundColor: theme.palette[channel]?.dark || 'rgba(0, 0, 0, 0.3)',
          borderColor: theme.palette[channel]?.dark || 'rgba(0, 0, 0, 0.3)',
          borderWidth: 2,
          fill: false,
          type: 'line',
        }));

        setLineData(lineDataLastWeek);

        setLabels(hours);
        setData(orderedRows);
      } else {
        const obj = {};
        const rows = response.rows.reduce((acc, row) => {
          const keyName = dayjs(
            row['Purchase Date'],
            'MM-DD-YYYY HH:mm:ss',
          ).format('MM/DD/YYYY');
          return {
            ...acc,
            [keyName]: acc[keyName]
              ? acc[keyName] + row['Count']
              : row['Count'],
          };
        }, {});
        const labelRows = Object.keys(rows)
          .sort()
          .reduce(
            (acc, key) => ({
              ...acc,
              [key]: rows[key],
            }),
            {},
          );
        response.rows.forEach((row) => {
          if (!obj[row['Channel Name']]) obj[row['Channel Name']] = [];
          obj[row['Channel Name']].push(row);
        });
        const orderedRows = [];

        Object.keys(obj).forEach((objKey) => {
          const rows = obj[objKey].reduce((acc, row) => {
            const keyName = dayjs(
              row['Purchase Date'],
              'MM-DD-YYYY HH:mm:ss',
            ).format('MM/DD/YYYY');
            return {
              ...acc,
              [keyName]: acc[keyName]
                ? acc[keyName] + row['Count']
                : row['Count'],
            };
          }, {});
          let arrayOfOrderedRows = [];
          Object.keys(labelRows)
            .filter((name) => name !== 'length')
            .sort()
            .forEach((labelName) => {
              if (!arrayOfOrderedRows[labelName])
                arrayOfOrderedRows[labelName] = 0;
            });
          const reducedRows = Object.keys(labelRows)
            .sort()
            .reduce((acc, key) => {
              let returningReducedObject = {
                ...acc,
                [key]: rows[key],
              };
              if (!rows[key])
                returningReducedObject = {
                  ...acc,
                  [key]: 0,
                };
              return returningReducedObject;
            }, {});
          arrayOfOrderedRows = Object.keys(reducedRows).map(
            (reducedRow, index) => {
              return reducedRows[reducedRow];
            },
          );
          const ret = {
            data: arrayOfOrderedRows,
            label: objKey,
            backgroundColor:
              theme.palette[objKey]?.main || 'rgba(0, 0, 0, 0.1)',
            hoverOffset: 5,
          };
          orderedRows.push(ret);
        });

        setData(orderedRows);
        setLabels(Object.keys(labelRows).filter((name) => name !== 'length'));
      }
    })();
  }, [unitsBack, supplierId, unitType]);

  const unitsLabel = `Orders By Channel ${unitDateLabel({
    unitsBack,
    unitType,
  })}`;

  return (
    <>
      <Grid
        container
        justifyContent="center"
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Typography variant="h5">{unitsLabel}</Typography>
        </Grid>
        <Grid item className={classes.width}>
          {today || yesterday ? (
            <BarChart
              labels={labels}
              data={[...lineData, ...data]}
              stacked
              title={unitsLabel}
              plugins={{
                legend: {
                  display: true,
                },
              }}
            />
          ) : (
            <BarChart
              labels={labels}
              data={data}
              stacked
              title={unitsLabel}
              plugins={{
                legend: {
                  display: true,
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default UnitsSoldChart;
