//? sub categories
export const SUPP_COUNT = 'supplier counts';
export const SUP_STOCK = 'supplier-stock';
export const SUP_COUNT = 'supplier-count';
export const SUP_OOS = 'supplier-oos';
export const BRD_STOCK = 'brand-stock';
export const BRD_OOS = 'brand-oos';
export const SUP_IR_STOCK = 'supplier-ir-stock';
export const SUP_IR_OOS = 'supplier-ir-oos';
export const BRD_IR_STOCK = 'brand-ir-stock';
export const BRD_IR_OOS = 'brand-ir-oos';
/* order cards - profit */
/* < 4%
4-10%
11-20%
>20% */
export const PROF_PERC_LT4 = 'profit-percent-4';
export const PROF_PERC_LT10 = 'profit-percent-10';
export const PROF_PERC_LT20 = 'profit-percent-20';
export const PROF_PERC_GT20 = 'profit-percent-20plus';

export const ORDER_COUNT = 'count';
export const ORDER_PROFIT = 'profit';
export const ORDER_MARGIN = 'profit-margin';
export const ORDER_COST = 'cost';
export const ORDER_PRICE = 'price';
export const ORDER_SHIP = 'shipping';
export const ORDER_SUB = 'subtotal';
export const ORDER_TAX = 'taxes';
export const ORDER_FEE = 'fees';
export const ORDER_GROSS = 'gross';


// ? categories
export const INVENTORY = 'inventory';
export const ANALYTICS_ORDERS = 'analytics-orders';
export const DSHBRD_CARDS = 'dashboard-cards';
