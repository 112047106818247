import StoreListingApi from '@oneAppCore/services/StoreListingApi';


export const useApprovePendingListings = async (ids: number[]) => {
    const payload = {
      status: 'PENDING'
    }
    for await ( const id of ids ) {
      try {
        await StoreListingApi.put(id, payload)
      } catch (error) {
        return error;
      }
    }
  return;
  };