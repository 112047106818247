// import {
//   TableCell,
//   TableRow,
//   Table,
//   TableContainer,
//   TableHead,
//   Select,
//   MenuItem,
//   TextField,
//   FormControl,
//   InputLabel,
//   Typography,
// } from '@mui/material';
// import { makeStyles } from '@mui/styles';
// import { supplierColumns } from './constants';
// import type { SupplierProps, SupplierConstant } from './types';
// import { FontAwesome } from '@src/Components/common';
// import { useEffect, useState } from 'react';
// import { Supplier as SupplierList } from '../../../types';
// import SupplierSelect from '@src/Components/common/Selects/SupplierSelect';
// import * as _ from 'lodash';
// import SupplierApi from '@oneAppCore/services/SupplierApi';
// import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi';
// import Api from '@oneAppCore/services/Api';
// import { useSnackbar } from 'notistack';

// import CurrencyTextField from '@src/Components/common/currencyTextField';
// import { mutate } from 'swr';
// import { primary } from '@src/Components/Theme/themes/constants';

// const useStyles = makeStyles(() => ({
//   headerColor: {
//     border: 0,
//     margin: 5,
//   },
//   fontColor: {
//     color: '#FFF',
//     backgroundColor: '#6e6b7b',
//     fontSize: 10,
//   },
//   bodyFont: {
//     fontSize: 12,
//   },
//   tableContainer: {
//     maxHeight: 440,
//     maxWidth: '100%',
//     overflowX: 'hidden',
//     margin: 10,
//     borderRadius: 5,
//     border: '1px solid #6e6b7b',
//   },
// }));

// type newSupplier = {
//   supplier?: SupplierList;
//   cost?: number;
//   quantity?: number;
//   shippingCost?: number;
// };

// type Supplier = {
//   id: number;
//   supplierId: number;
//   cost: number;
//   quantity: number;
//   shippingCost: number | null;
// };

// type EditSupp = {
//   id: number;
//   supplierId: number;
//   cost: number;
//   quantity: number;
//   shippingCost: number | null;
// };

// const SupplierTable = ({
//   row,
//   rows,
//   supplierData = [],
//   suppliers,
//   mutate,
//   discountData,
//   rowsPerPage,
//   page,
// }: SupplierProps) => {
//   const [enableEdit, setEnableEdit] = useState<boolean>(null);
//   const [editableColumn, setEditableColumn] = useState<{ suppIndex: number }>(
//     null,
//   );
//   const [newSupplier, setNewSupplier] = useState<newSupplier>({});
//   const [supps, setSupps] = useState([]);
//   const [mpn, setMpn] = useState('');
//   const [sku, setSku] = useState('');
//   const [varId, setVarId] = useState(0);
//   const [suppData, setSuppData] = useState<Supplier>();
//   const [supplierIds, setSupplierIds] = useState<number[]>([]);
//   const { enqueueSnackbar } = useSnackbar();
//   const classes = useStyles();
//   const [rowId, setRowId] = useState(null);
//   const [brand, setBrand] = useState('');
//   const [brandId, setBrandId] = useState(0);
//   const [editSupp, setEditSupp] = useState<EditSupp>({
//     id: null,
//     supplierId: null,
//     cost: null,
//     quantity: null,
//     shippingCost: null,
//   });
//   const [supplierArray, setSupplierArray] = useState([]);

//   const combinedData = rows.flatMap((row) => {
//     if (!row || !row.suppliers) return [];
//     const { imageUrl, name, variation, sku, manufacturerNo, suppliers } = row;
//     return suppliers?.map((supplier) => ({
//       variation,
//       sku,
//       manufacturerNo,
//       imageUrl,
//       name,
//       supplierId: supplier.supplierId,
//       supplier: supplier.supplier,
//       cost: supplier.cost,
//       quantity: supplier.quantity,
//       shippingCost: supplier.shippingCost,
//     }));
//   });

//   const paginateSupplierData = (combinedData, page_size, page_number) => {
//     return combinedData?.slice(
//       (page_number - 1) * page_size,
//       page_number * page_size,
//     );
//   };

//   const paginatedSupplierData = paginateSupplierData(
//     combinedData,
//     rowsPerPage,
//     page + 1,
//   );

//   useEffect(() => {
//     const grabSuppliers = async () => {
//       const suppliers = await Api.get(`/api/v1/suppliers/search`);
//       setSupps(suppliers.rows);
//     };
//     grabSuppliers();
//   }, []);
//   useEffect(() => {
//     if (supplierData) {
//       setSupplierIds(supplierData.map((sd) => sd.supplierId));
//     }
//   }, []);

//   const handleChange = (key, e) => {
//     setEditSupp((supp) => {
//       const updatedSupp = {
//         ...supp,
//         [key.keyName]: e.target.value,
//       };
//       return updatedSupp;
//     });
//   };

//   const invokeSuppUpdate = async (id: number) => {
//     const { supplierId, cost, quantity, shippingCost } = editSupp;

//     let payload = {
//       supplierId: supplierId !== null ? supplierId : suppData.supplierId,
//       cost: cost === null ? suppData.cost / 100 : cost,
//       quantity: quantity !== null ? quantity : suppData.quantity,
//       shippingCost:
//         shippingCost === null ? suppData.shippingCost / 100 : shippingCost,
//       brandId: row.brandId,
//       brand: row.brand,
//     };

//     try {
//       await ManufacturerSuppliersApi.put(rowId, payload);
//     } catch (e) {
//       enqueueSnackbar(e.message, { variant: 'error' });
//     } finally {
//       mutate();
//       setEditSupp({
//         id: null,
//         supplierId: null,
//         cost: null,
//         quantity: null,
//         shippingCost: null,
//       });
//       setEnableEdit(false);
//       setEditableColumn(null);
//       enqueueSnackbar('Successfully Updated!', { variant: 'success' });
//     }
//   };

//   const handleSelectChange = (event) => {
//     const selectedVariationId = event.target.value;
//     const selectedRow = rows.find(
//       (row) => row.variationId === selectedVariationId,
//     );

//     if (selectedRow) {
//       setVarId(selectedVariationId);
//       setMpn(selectedRow.manufacturerNo || '');
//       setSku(selectedRow.sku);
//     }
//   };

//   const addNewSupplier = async () => {
//     if (
//       !newSupplier.supplier ||
//       !newSupplier.cost ||
//       (!newSupplier.quantity && typeof newSupplier.quantity !== 'number') ||
//       (!newSupplier.shippingCost &&
//         typeof newSupplier.shippingCost !== 'number')
//     ) {
//       return;
//     }

//     const costInCents = newSupplier.cost * 100;
//     const shippingInCents = newSupplier.shippingCost * 100;

//     const payload = {
//       ...newSupplier,
//       manufacturerNo: mpn,
//       brandId: rows?.[0]?.brandId,
//       supplierId: newSupplier.supplier.id,
//       priority: 2,
//       cost: costInCents,
//       shippingCost: shippingInCents,
//       variationId: varId,
//     };
//     delete payload.supplier;
//     await ManufacturerSuppliersApi.post(payload)
//       .then(() => {
//         enqueueSnackbar(
//           'Created Manufcaturer Supplier ' + newSupplier.supplier.name,
//           { variant: 'success' },
//         );
//         mutate();
//       })
//       .catch((error) => {
//         enqueueSnackbar(error, { variant: 'error' });
//       });
//   };

//   // const enableEditing = (supp: Supplier, suppIndex: number) => {
//   //   const { id, supplierId, cost, quantity, shippingCost } = supp;
//   //   setSuppData({
//   //     id,
//   //     supplierId,
//   //     cost,
//   //     quantity,
//   //     shippingCost,
//   //   });
//   //   setEnableEdit(true);
//   //   setRowId(row.suppliers[suppIndex].suppid);
//   //   setEditableColumn({ suppIndex });
//   // };

//   const enableEditing = (supp: Supplier, suppIndex: number) => {
//     if (!row || !row.suppliers || !row.suppliers[suppIndex]) {
//       console.warn('Row or suppliers data is missing');
//       return;
//     }

//     const { id, supplierId, cost, quantity, shippingCost } = supp;

//     setSuppData({
//       id,
//       supplierId,
//       cost,
//       quantity,
//       shippingCost,
//     });
//     setEnableEdit(true);
//     setRowId(row.suppliers[suppIndex].suppid);
//     setEditableColumn({ suppIndex });
//   };

//   const suppliersData = rows.flatMap((row) => {
//     return row.suppliers
//       ? row.suppliers.map((supplier) => ({
//           id: supplier.supplierId,
//           name: supplier.supplier,
//           cost: supplier.cost,
//           discount: supplier.discount,
//           flat: supplier.flat,
//           percent: supplier.percent,
//           quantity: supplier.quantity,
//           shipping: supplier.shippingCost,
//         }))
//       : [];
//   });

//   return (
//     <>
//       {combinedData &&
//         combinedData.map((supplier: Supplier, suppIndex) => {
//           return (
//             <TableRow key={supplier.id}>
//               {supplierColumns(discountData, row).map((key) => {
//                 if (enableEdit && editableColumn.suppIndex === suppIndex) {
//                   if (key.keyName === 'supplier') {
//                     return (
//                       <TableCell key={`sup-${suppIndex}`}>
//                         <Select
//                           onChange={(e) =>
//                             handleChange({ keyName: 'supplierId' }, e)
//                           }
//                           style={{ maxWidth: 100, minWidth: 80 }}
//                           defaultValue={supplier.supplierId}
//                         >
//                           {supps.map((x) => {
//                             return (
//                               <MenuItem value={x.id} key={x.id + x.name}>
//                                 {x.name}
//                               </MenuItem>
//                             );
//                           })}
//                         </Select>
//                       </TableCell>
//                     );
//                   } else if (key.keyName === 'image') {
//                     return (
//                       <TableCell key={`img-${suppIndex}`}>
//                         <></>
//                       </TableCell>
//                     );
//                   } else if (key.keyName === 'cost') {
//                     return (
//                       <TableCell key={`cost-${suppIndex}`}>
//                         <TextField
//                           label="cost"
//                           defaultValue={supplier.cost / 100}
//                           type="number"
//                           onChange={(event) => handleChange(key, event)}
//                         />
//                       </TableCell>
//                     );
//                   } else if (key.keyName === 'quantity') {
//                     return (
//                       <TableCell key={`qty-${suppIndex}`}>
//                         <TextField
//                           label="quantity"
//                           defaultValue={supplier.quantity}
//                           type="number"
//                           onChange={(event) => handleChange(key, event)}
//                         />
//                       </TableCell>
//                     );
//                   } else if (key.keyName === 'shippingCost') {
//                     return (
//                       <TableCell key={`ship-${suppIndex}`}>
//                         <TextField
//                           label="shipping cost"
//                           defaultValue={supplier.shippingCost / 100}
//                           type="number"
//                           onChange={(event) => handleChange(key, event)}
//                         />
//                       </TableCell>
//                     );
//                   } else if (key.keyName === 'Edit') {
//                     let check =
//                       enableEdit && editableColumn.suppIndex === suppIndex;
//                     return (
//                       <TableCell key={'supplier_' + key.keyName + supplier.id}>
//                         <FontAwesome
//                           name={check ? 'check' : 'edit'}
//                           color={check ? '#50C878' : primary.main}
//                           type="fas"
//                           form="fa"
//                           onClick={() => {
//                             setBrand(String(row.brand));
//                             setBrandId(row.brandId);
//                             check
//                               ? invokeSuppUpdate(supplier.id)
//                               : enableEditing(supplier, suppIndex);
//                           }}
//                         />
//                       </TableCell>
//                     );
//                   }
//                 } else {
//                   if (key.keyName === 'Edit') {
//                     let check =
//                       enableEdit && editableColumn.suppIndex === suppIndex;
//                     return (
//                       <TableCell key={'supplier_' + key.keyName + supplier.id}>
//                         <FontAwesome
//                           name={check ? 'check' : 'edit'}
//                           color={check ? '#50C878' : primary.main}
//                           type="fas"
//                           form="fa"
//                           onClick={() => {
//                             check
//                               ? invokeSuppUpdate(supplier.id)
//                               : enableEditing(supplier, suppIndex);
//                           }}
//                         />
//                       </TableCell>
//                     );
//                   } else {
//                     return (
//                       <TableCell
//                         className={classes.bodyFont}
//                         key={'supplier_' + key.keyName + supplier.id}
//                       >
//                         {key.customComponent
//                           ? key.customComponent(supplier)
//                           : supplier[key.keyName]}
//                       </TableCell>
//                     );
//                   }
//                 }
//               })}
//             </TableRow>
//           );
//         })}
//       <TableRow>
//         {supplierColumns(discountData, row).map((row, index) => {
//           if (row.keyName === 'supplier') {
//             return (
//               <TableCell key={`sup-${index}`}>
//                 <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
//                   <InputLabel
//                     sx={{
//                       display: 'flex',
//                       alignItems: 'center',
//                       height: '100%',
//                       lineHeight: 'normal',
//                       padding: '0 8px',
//                     }}
//                   >
//                     Supplier
//                   </InputLabel>
//                   <Select
//                     onChange={(event: any) => {
//                       setNewSupplier((s) => ({
//                         ...s,
//                         [row.keyName]: supps?.filter(
//                           (supplier) => supplier.id === event.target.value,
//                         )[0],
//                       }));
//                     }}
//                     sx={{ width: '100%' }}
//                   >
//                     {supps
//                       .filter((supp) => !supplierIds.includes(supp.id))
//                       .map((x) => {
//                         return (
//                           <MenuItem value={x.id} key={x.id + x.name}>
//                             {x.name}
//                           </MenuItem>
//                         );
//                       })}
//                   </Select>
//                 </FormControl>
//               </TableCell>
//             );
//           } else if (row.keyName === 'imageUrl') {
//             return (
//               <TableCell
//                 key={`imageUrl-${index}`}
//                 sx={{ maxWidth: 10 }}
//               ></TableCell>
//             );
//           } else if (row.keyName === 'variation') {
//             return (
//               <TableCell key={`variation-${index}`}>
//                 <Select
//                   value={varId}
//                   onChange={(e) => handleSelectChange(e)}
//                   sx={{ minWidth: 100 }}
//                 >
//                   {rows.map((option) => (
//                     <MenuItem
//                       key={option.variationId}
//                       value={option.variationId}
//                     >
//                       {' '}
//                       {`${option.variation} - ${option.manufacturerNo}`}{' '}
//                     </MenuItem>
//                   ))}
//                 </Select>
//               </TableCell>
//             );
//           } else if (row.keyName === 'sku') {
//             return (
//               <TableCell key={`sku-${index}`} sx={{ maxWidth: 10 }}></TableCell>
//             );
//           } else if (row.keyName === 'manufacturerNo') {
//             return (
//               <TableCell
//                 key={`manufacturerNo-${index}`}
//                 sx={{ maxWidth: 10 }}
//               ></TableCell>
//             );
//           } else if (row.keyName === 'Edit') {
//             return (
//               <TableCell key={`edit-${index}`}>
//                 <FontAwesome
//                   name="check"
//                   type="fas"
//                   form="fa"
//                   onClick={function () {
//                     addNewSupplier();
//                     // clearText();
//                   }}
//                   style={{ cursor: 'pointer', color: '#50C878' }}
//                 />
//               </TableCell>
//             );
//           } else {
//             return (
//               <TableCell key={`n-${index}`}>
//                 <TextField
//                   label=""
//                   value={newSupplier[row.keyName]}
//                   required
//                   type="number"
//                   onChange={(event) =>
//                     setNewSupplier((supplier) => ({
//                       ...supplier,
//                       [row.keyName]: parseFloat(event.target.value),
//                     }))
//                   }
//                 />
//               </TableCell>
//             );
//           }
//         })}
//       </TableRow>
//     </>
//     // </Table>
//     // </TableContainer>
//   );
// };

// export default SupplierTable;

import {
  TableCell,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { supplierColumns } from './constants';
import type { SupplierProps, SupplierConstant } from './types';
import { FontAwesome } from '@src/Components/common';
import { useEffect, useState } from 'react';
import { Supplier as SupplierList } from '../../../types';
import SupplierSelect from '@src/Components/common/Selects/SupplierSelect';
import * as _ from 'lodash';
import SupplierApi from '@oneAppCore/services/SupplierApi';
import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi';
import Api from '@oneAppCore/services/Api';
import { useSnackbar } from 'notistack';

import CurrencyTextField from '@src/Components/common/currencyTextField';
import { mutate } from 'swr';
import { primary } from '@src/Components/Theme/themes/constants';

const useStyles = makeStyles(() => ({
  headerColor: {
    border: 0,
    margin: 5,
  },
  fontColor: {
    color: '#FFF',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  bodyFont: {
    fontSize: 12,
  },
  tableContainer: {
    maxHeight: 440,
    maxWidth: '100%',
    overflowX: 'hidden',
    margin: 10,
    borderRadius: 5,
    border: '1px solid #6e6b7b',
  },
}));

type newSupplier = {
  supplier?: SupplierList;
  cost?: number;
  quantity?: number;
  shippingCost?: number;
};

type Supplier = {
  id: number;
  supplierId: number;
  cost: number;
  quantity: number;
  shippingCost: number | null;
};

type EditSupp = {
  id: number;
  supplierId: number;
  cost: number;
  quantity: number;
  shippingCost: number | null;
};

const SupplierTable = ({
  row,
  supplierData = [],
  suppliers,
  mutate,
  discountData,
}: SupplierProps) => {
  const [enableEdit, setEnableEdit] = useState<boolean>(null);
  const [editableColumn, setEditableColumn] = useState<{ suppIndex: number }>(
    null,
  );
  const [newSupplier, setNewSupplier] = useState<newSupplier>({});
  const [supps, setSupps] = useState([]);
  const [suppData, setSuppData] = useState<Supplier>();
  const [supplierIds, setSupplierIds] = useState<number[]>([]);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [rowId, setRowId] = useState(null);
  const [brand, setBrand] = useState('');
  const [brandId, setBrandId] = useState(0);
  const [editSupp, setEditSupp] = useState<EditSupp>({
    id: null,
    supplierId: null,
    cost: null,
    quantity: null,
    shippingCost: null,
  });

  useEffect(() => {
    const grabSuppliers = async () => {
      const suppliers = await Api.get(`/api/v1/suppliers/search`);
      setSupps(suppliers.rows);
    };
    grabSuppliers();
  }, []);
  useEffect(() => {
    if (supplierData) {
      setSupplierIds(supplierData.map((sd) => sd.supplierId));
    }
  }, []);

  const handleChange = (key, e) => {
    setEditSupp((supp) => {
      const updatedSupp = {
        ...supp,
        [key.keyName]: e.target.value,
      };
      return updatedSupp;
    });
  };

  const invokeSuppUpdate = async (id: number) => {
    const { supplierId, cost, quantity, shippingCost } = editSupp;

    let payload = {
      supplierId: supplierId !== null ? supplierId : suppData.supplierId,
      cost: cost === null ? suppData.cost / 100 : cost,
      quantity: quantity !== null ? quantity : suppData.quantity,
      shippingCost:
        shippingCost === null ? suppData.shippingCost / 100 : shippingCost,
      brandId: row.brandId,
      brand: row.brand,
    };

    try {
      await ManufacturerSuppliersApi.put(rowId, payload);
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    } finally {
      mutate();
      setEditSupp({
        id: null,
        supplierId: null,
        cost: null,
        quantity: null,
        shippingCost: null,
      });
      setEnableEdit(false);
      setEditableColumn(null);
      enqueueSnackbar('Successfully Updated!', { variant: 'success' });
    }
  };

  const addNewSupplier = async () => {
    if (
      !newSupplier.supplier ||
      !newSupplier.cost ||
      (!newSupplier.quantity && typeof newSupplier.quantity !== 'number') ||
      (!newSupplier.shippingCost &&
        typeof newSupplier.shippingCost !== 'number')
    ) {
      return;
    }

    const costInCents = newSupplier.cost * 100;
    const shippingInCents = newSupplier.shippingCost * 100;

    const payload = {
      ...newSupplier,
      manufacturerNo: row.manufacturerNo,
      brandId: row.brandId,
      supplierId: newSupplier.supplier.id,
      priority: 2,
      cost: costInCents,
      shippingCost: shippingInCents,
      variationId: row.variationId,
    };
    delete payload.supplier;
    await ManufacturerSuppliersApi.post(payload)
      .then(() => {
        enqueueSnackbar(
          'Created Manufcaturer Supplier ' + newSupplier.supplier.name,
          { variant: 'success' },
        );
        mutate();
      })
      .catch((error) => {
        enqueueSnackbar(error, { variant: 'error' });
      });
  };

  const enableEditing = (supp: Supplier, suppIndex: number) => {
    const { id, supplierId, cost, quantity, shippingCost } = supp;
    setSuppData({
      id,
      supplierId,
      cost,
      quantity,
      shippingCost,
    });
    setEnableEdit(true);
    setRowId(row.suppliers[suppIndex].suppid);
    setEditableColumn({ suppIndex });
  };

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader size="small" aria-label="suppliers">
        <TableHead>
          <TableRow className={classes.headerColor}>
            {supplierColumns(discountData).map(
              (supplier: SupplierConstant, i) => (
                <TableCell
                  className={classes.fontColor}
                  sx={{
                    backgroundColor: '#72717a',
                    color: '#fff',
                  }}
                  key={'supplier_' + supplier.keyName + i}
                >
                  {supplier.title}
                </TableCell>
              ),
            )}
          </TableRow>
        </TableHead>
        {supplierData &&
          supplierData.map((supplier: Supplier, suppIndex) => {
            return (
              <TableRow key={supplier.id}>
                {supplierColumns(discountData).map((key) => {
                  if (enableEdit && editableColumn.suppIndex === suppIndex) {
                    if (key.keyName === 'supplier') {
                      return (
                        <TableCell key={`sup-${suppIndex}`}>
                          <Select
                            onChange={(e) =>
                              handleChange({ keyName: 'supplierId' }, e)
                            }
                            style={{ maxWidth: 100, minWidth: 80 }}
                            defaultValue={supplier.supplierId}
                          >
                            {supps.map((x) => {
                              return (
                                <MenuItem value={x.id} key={x.id + x.name}>
                                  {x.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </TableCell>
                      );
                    } else if (key.keyName === 'cost') {
                      return (
                        <TableCell key={`cost-${suppIndex}`}>
                          <TextField
                            label="cost"
                            defaultValue={supplier.cost / 100}
                            type="number"
                            onChange={(event) => handleChange(key, event)}
                          />
                        </TableCell>
                      );
                    } else if (key.keyName === 'quantity') {
                      return (
                        <TableCell key={`qty-${suppIndex}`}>
                          <TextField
                            label="quantity"
                            defaultValue={supplier.quantity}
                            type="number"
                            onChange={(event) => handleChange(key, event)}
                          />
                        </TableCell>
                      );
                    } else if (key.keyName === 'shippingCost') {
                      return (
                        <TableCell key={`ship-${suppIndex}`}>
                          <TextField
                            label="shipping cost"
                            defaultValue={supplier.shippingCost / 100}
                            type="number"
                            onChange={(event) => handleChange(key, event)}
                          />
                        </TableCell>
                      );
                    } else if (key.keyName === 'Edit') {
                      let check =
                        enableEdit && editableColumn.suppIndex === suppIndex;
                      return (
                        <TableCell
                          key={'supplier_' + key.keyName + supplier.id}
                        >
                          <FontAwesome
                            name={check ? 'check' : 'edit'}
                            color={check ? '#50C878' : primary.main}
                            type="fas"
                            form="fa"
                            onClick={() => {
                              setBrand(String(row.brand));
                              setBrandId(row.brandId);
                              check
                                ? invokeSuppUpdate(supplier.id)
                                : enableEditing(supplier, suppIndex);
                            }}
                          />
                        </TableCell>
                      );
                    }
                  } else {
                    if (key.keyName === 'Edit') {
                      let check =
                        enableEdit && editableColumn.suppIndex === suppIndex;
                      return (
                        <TableCell
                          key={'supplier_' + key.keyName + supplier.id}
                        >
                          <FontAwesome
                            name={check ? 'check' : 'edit'}
                            color={check ? '#50C878' : primary.main}
                            type="fas"
                            form="fa"
                            onClick={() => {
                              check
                                ? invokeSuppUpdate(supplier.id)
                                : enableEditing(supplier, suppIndex);
                            }}
                          />
                        </TableCell>
                      );
                    } else {
                      return (
                        <TableCell
                          className={classes.bodyFont}
                          key={'supplier_' + key.keyName + supplier.id}
                        >
                          {key.customComponent
                            ? key.customComponent(supplier)
                            : supplier[key.keyName]}
                        </TableCell>
                      );
                    }
                  }
                })}
              </TableRow>
            );
          })}
        <TableRow>
          {supplierColumns(discountData).map((row, index) => {
            if (row.keyName === 'supplier') {
              return (
                <TableCell key={`sup-${index}`}>
                  <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                    <InputLabel
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        height: '100%',
                        lineHeight: 'normal',
                        padding: '0 8px',
                      }}
                    >
                      Supplier
                    </InputLabel>
                    <Select
                      onChange={(event: any) => {
                        setNewSupplier((s) => ({
                          ...s,
                          [row.keyName]: supps?.filter(
                            (supplier) => supplier.id === event.target.value,
                          )[0],
                        }));
                      }}
                      sx={{ width: '100%' }}
                      // style={{
                      //   maxWidth: 100,
                      //   minWidth: 80,
                      // }}
                    >
                      {supps
                        .filter((supp) => !supplierIds.includes(supp.id))
                        .map((x) => {
                          return (
                            <MenuItem value={x.id} key={x.id + x.name}>
                              {x.name}
                            </MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </TableCell>
              );
            } else if (row.keyName === 'Edit') {
              return (
                <TableCell key={`edit-${index}`}>
                  <FontAwesome
                    name="check"
                    type="fas"
                    form="fa"
                    onClick={function () {
                      addNewSupplier();
                      // clearText();
                    }}
                    style={{ cursor: 'pointer', color: '#50C878' }}
                  />
                </TableCell>
              );
            } else {
              return (
                <TableCell key={`n-${index}`}>
                  <TextField
                    label=""
                    value={newSupplier[row.keyName]}
                    required
                    type="number"
                    onChange={(event) =>
                      setNewSupplier((supplier) => ({
                        ...supplier,
                        [row.keyName]: parseFloat(event.target.value),
                      }))
                    }
                  />
                </TableCell>
              );
            }
          })}
        </TableRow>
      </Table>
    </TableContainer>
  );
};

export default SupplierTable;
