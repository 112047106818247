import React, { memo } from 'react';

import useMe from '@src/hooks/swr/useMe';

import { WEBAHOLICS_ID } from '@oneAppCore/constants/domainLogic';

import { Switch, Route } from 'react-router-dom';

import type { LoggedInUser } from '@oneAppCore/types/userTypes';
import mainRoutes from './mainRoutes';

import type { Page } from './types';

const authenticateRoute = (route: Page, user: LoggedInUser) => {
  route.redirectUrl = '/login';
  route.authorized = true;
  if (route.userRequired && route.userRequired.length) {
    if (!user) {
      route.authorized = false;
    }
    if (user?.id) {
      const userIsIncluded = route.userRequired.includes(Number(user?.id))
      if (!userIsIncluded) {
        route.authorized = false;
      }
    } else {
      route.authorized = false;
    }
  }
  if (route.permissionRequired && route.permissionRequired.length) {
    if (!user) {
      route.authorized = false;
    }
    const findResults = user?.roles?.find((role: any) => {
      const { permissionType, permissionRequired } = route;
      const checkPermissionType = !permissionType || permissionType === 'any' ? role.read || role.write || role.update || role.delete : role[permissionType.toLowerCase()];
      return permissionRequired.includes(role.roleId) && checkPermissionType;
    }
    )
    if (!findResults) {
      route.authorized = false;
    }
  }
  if (route.loginRequired && !user) {
    route.authorized = false;
  }
  if (route.loggoutRequired && Boolean(user)) {
    route.redirectUrl = '/';
    route.authorized = false;
  }
  if (route.webaholicsOnly && user?.companyId !== WEBAHOLICS_ID) {
    route.redirectUrl = '/';
    route.authorized = false;
  }
  return route;
};

const RenderRoute = ({ route, user }: { route: Page, user: LoggedInUser }) => (
  <Switch key={route.key}>
    <Route
      exact={route.exact}
      path={route.path}
      render={() => (
        <route.layout
          authorized={route.authorized}
          redirectUrl={route.redirectUrl}
        >
          <route.component user={user} />
        </route.layout>
      )}
    />
  </Switch>
);

/*

function nullSort(descending: boolean = false, alphabetic: boolean = false) {
  return function (a: any, b: any): number {
    if (a === b) {
      return 0;
    }
    if (a === null) {
      return 1;
    }
    if (b === null) {
      return -1;
    }

    let ret
    if (alphabetic) {
      ret = alphabeticCollator.compare(a, b)
    } else {
      ret = naturalCollator.compare(a, b)
    }
    if (descending) {
      ret = -ret
    }
    return ret
  };
}


*/
const naturalCollator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
const alphabeticCollator = new Intl.Collator(undefined, {});

const nullSort = (property: string, descending: boolean = false, alphabetic: boolean = false) => {
  const retFunc = (a: any, b: any): number => {
    if (a[property] === b[property]) {
      return 0;
    }
    if (a[property] === null || a[property] === undefined) {
      return 1;
    }
    if (b[property] === null || b[property] === undefined) {
      return -1;
    }

    let ret;
    if (alphabetic) {
      ret = alphabeticCollator.compare(a, b);
    } else {
      ret = naturalCollator.compare(a, b);
    }
    if (descending) {
      ret = -ret;
    }
    return ret;
  };
  return retFunc;
};

const Routes = () => {
  const { data: user, error } = useMe();

  // App hasn't loaded the user yet, don't load the routes.
  if (!error && !user) return <></>;
  return (
    <>
      {mainRoutes/* .sort(nullSort('sequence')) */.map((route, index) => {
        route = authenticateRoute(route, user);
        return (
          <React.Fragment key={route.key}>
            {route.subRoutes &&
              route.subRoutes/* .sort(nullSort('sequence')) */.map((subRoute) => {
                subRoute = authenticateRoute(subRoute, user);
                return <RenderRoute route={subRoute} key={subRoute.key} user={user} />;
              })}
            {route.path && <RenderRoute route={route} key={route.key} user={user} />}
          </React.Fragment>
        );
      })}

      {/* <RenderRoute route={notFound} key={notFound.key} /> */}
    </>
  );
};

export default memo(Routes);
