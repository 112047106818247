import { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import {
  Grid,
  Tabs,
  Tab,
  Box,
  AppBar,
  Theme,
  CircularProgress,
  Paper,
  Typography,
  Divider,
} from '@mui/material';

import { useSupplierById } from '@src/hooks/swr/useSuppliers';
import { useHasAdminPermissions } from '@src/hooks/util/useHasPermission';
import useMe from '@src/hooks/swr/useMe';
import CreateSuppInfo from './CreateSuppInfo';
// import ApiInfo from './AdditionalInfo/AdditionalInfo';
import { makeStyles } from '@mui/styles';
import InfoIcon from '@mui/icons-material/Info';
import RoomIcon from '@mui/icons-material/Room';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

function tabProps(index) {
  return {
    id: `tab-${index}`,
  };
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    // backgroundColor: '#ffffff',
    borderRadius: 5,
    padding: 15,
  },
}));

function SupplierCreate({}) {
  const { pathname } = useLocation();
  const admin = useHasAdminPermissions();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  // const { data: supplier, mutate: mutateSupplier } = id
  //   ? useSupplierById(Number(id))
  //   : useSupplierById(Number(useMe().data.id));

  // console.log(supplier, 'supplier');

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Paper style={{ width: '100%' }}>
        {/* <Grid item container xs={12} style={{ padding: '24px' }}>
          <Typography style={{ fontSize: '1.125rem', fontWeight: 500 }}>
            {supplier?.name}
          </Typography>
        </Grid>
        <Grid xs={12} style={{ marginBottom: 15 }}>
          <Divider />
        </Grid> */}
        <Grid container xs={12} className={classes.root}>
          <Grid item container xs={3} style={{ justifyContent: 'flex-start' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              orientation="vertical"
              style={{ width: '90%', justifyContent: 'flex-start' }}
            >
              <Tab
                style={{ color: '#000000' }}
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <InfoIcon color="secondary" fontSize="small" />
                    <span style={{ marginLeft: 10 }}>Information</span>
                  </div>
                }
                {...tabProps(0)}
              />
              {/* <Tab
                style={{ color: '#000000' }}
                label={
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'flex-start',
                    }}
                  >
                    <CheckCircleIcon color="secondary" fontSize="small" />
                    <span style={{ marginLeft: 10 }}>API/FTP Information</span>
                  </div>
                }
                {...tabProps(1)}
              /> */}
            </Tabs>
            <Divider orientation="vertical" />
          </Grid>
          {value === 0 ? (
            <Grid item container xs={9}>
              <CreateSuppInfo edit={admin} />
            </Grid>
          ) : (
            <Grid item container xs={9}>
              {/* <ApiInfo edit={admin} /> */}
            </Grid>
          )}
        </Grid>
      </Paper>
    </>
  );
}

export default SupplierCreate;
