import { useEffect, useState } from 'react';
import { Grid, Tooltip } from '@mui/material';
import Api from '@oneAppCore/services/Api';
import AmazonModal from './AmazonModal';
import EbayModal from './EbayModal';
import WoocomModal from './WoocomModal';
import WalmartModal from './WalmartModal';
import ShopifyModal from './ShopifyModal';

function ChannelIcons() {
  const [icons, setIcons] = useState([]);
  const [modals, setModals] = useState({
    amazon: false,
    ebay: false,
    woocom: false,
    walmart: false,
    shopify: false,
  });

  useEffect(() => {
    const getChannels = async () => {
      try {
        const res = await Api.get('/api/v1/channels');
        setIcons(res || []);
      } catch (error) {
        console.error('Error fetching channels:', error);
      }
    };

    getChannels();
  }, []);

  const handleOpenModal = (channel) => {
    setModals((prevModals) => ({ ...prevModals, [channel]: true }));
  };

  const handleCloseModal = (channel) => {
    setModals((prevModals) => ({ ...prevModals, [channel]: false }));
  };

  return (
    <Grid container xs={12} alignItems="center" justifyContent="space-between">
      <Grid item container xs={12} justifyContent="center">
        <h1>Sync your store channels</h1>
      </Grid>
      <Grid item container xs={12} justifyContent="space-around">
        {icons
          .filter((channel) => channel.name !== 'All')
          .map((channel) => (
            <Grid item xs={2} key={channel.value} justifyContent="center">
              {channel.name === 'AMAZON' && (
                <Tooltip title={'Connect to Amazon'}>
                  <img
                    height={100}
                    width={100}
                    src={channel.image}
                    style={{ borderRadius: 10, cursor: 'pointer' }}
                    onClick={() => handleOpenModal('amazon')}
                  />
                </Tooltip>
              )}
              {channel.name === 'EBAY' && (
                <Tooltip title={'Connect to Ebay'}>
                  <img
                    height={100}
                    width={100}
                    src={channel.image}
                    style={{
                      backgroundColor: 'white',
                      padding: 10,
                      borderRadius: 10,
                      cursor: 'pointer',
                    }}
                    onClick={() => handleOpenModal('ebay')}
                  />
                </Tooltip>
              )}
              {channel.name === 'WOO_COMMERCE' && (
                <Tooltip title={'Connect to Woocommerce'}>
                  <img
                    height={100}
                    width={100}
                    src={channel.image}
                    style={{ borderRadius: 10, cursor: 'pointer' }}
                    onClick={() => handleOpenModal('woocom')}
                  />
                </Tooltip>
              )}
              {channel.name === 'WALMART' && (
                <Tooltip title={'Connect to Walmart'}>
                  <img
                    height={100}
                    width={100}
                    src={channel.image}
                    style={{ borderRadius: 10, cursor: 'pointer' }}
                    onClick={() => handleOpenModal('walmart')}
                  />
                </Tooltip>
              )}
              {channel.name === 'SHOPIFY' && (
                <Tooltip title={'Coming Soon!'}>
                  <img
                    height={100}
                    width={100}
                    src={channel.image}
                    style={{ borderRadius: 10, cursor: 'pointer' }}
                    onClick={() => handleOpenModal('shopify')}
                  />
                </Tooltip>
              )}
            </Grid>
          ))}

        <AmazonModal
          open={modals.amazon}
          onClose={() => handleCloseModal('amazon')}
        />
        <EbayModal
          open={modals.ebay}
          onClose={() => handleCloseModal('ebay')}
        />
        <WoocomModal
          open={modals.woocom}
          onClose={() => handleCloseModal('woocom')}
        />
        <WalmartModal
          open={modals.walmart}
          onClose={() => handleCloseModal('walmart')}
        />
        {/* <ShopifyModal
        // open={modals.shopify}
        // onClose={() => handleCloseModal('shopify')}
        /> */}
      </Grid>
    </Grid>
  );
}

export default ChannelIcons;
