import { Fragment, useEffect, useState } from 'react';
import FitmentTable from '../VariationsTable/TableRows/fitment/fitment';
import SupplierTable from '../VariationsTable/TableRows/suppliers/suppliers';
import ListingTable from '../VariationsTable/TableRows/listings/listings';
import { Grid, Button, Card, Typography } from '@mui/material';
import ItemGroupsApi from '@oneAppCore/services/ItemGroups';
import { Params } from '@src/Components/Users/UserView/types';
import { GroupForm, FitmentForm, ManufacturerSupplierForm } from '../types';
import { useParams } from 'react-router-dom';
import { Props } from './types';
import useQuery from '@src/hooks/util/useQuery';
import Api from '@oneAppCore/services/Api';
import useListings from '../hooks/useListings';
import { identity } from 'lodash';

function GroupedFLSTable({ suppliers }: Props) {
  const [groupForm, setGroupForm] = useState<GroupForm[]>([]);
  const [sameFit, setSameFit] = useState([]);
  const [sameSup, setSameSup] = useState([]);
  // const [sameSup, setSameSup] = useState(false);
  const [listingList, setListingList] = useState([]);
  const [listings, setListings] = useState([]);
  const { id: paramId } = useParams<Params>();

  const { data: listing, mutate: mutListing } = useListings(paramId);

  useEffect(() => {
    if (paramId) {
      (async () => {
        const { group } = await ItemGroupsApi.getGroupId(Number(paramId));
        setGroupForm(group);
        const suppObj = {};
        const fitObj = {};
        const test = {};
        const supl = [];
        const fitm1 = [];
        const t = [];
        const existingGroup = groupForm.length ? groupForm : group;

        const lengthOfItems = existingGroup.length;
        existingGroup.forEach((item) => {
          const fitment = item.fitment;
          const suppliers = item.suppliers;
          fitm1.push({ fit: fitment });
          supl.push({ supplier: suppliers });

          suppliers.forEach((supplier) => {
            const supplierId = supplier.supplierId;
            if (!suppObj[supplierId]) suppObj[supplierId] = [];
            suppObj[supplierId].push(supplier);

            const sharedSuppliers = Object.keys(suppObj)
              .map((key) => {
                if (suppObj[key].length === lengthOfItems) {
                  return suppObj[key][0];
                } else {
                  return null;
                }
              })
              .filter((item) => item);
            setSameSup(sharedSuppliers);
          });
          let sharedFit = [];
          fitment.forEach((fitm) => {
            const modelId = fitm.model;
            const years = fitm.year;
            if (!fitObj[`${modelId} -- ${years?.join(', ')}`])
              fitObj[`${modelId} -- ${years?.join(', ')}`] = [];
            fitObj[`${modelId} -- ${years?.join(', ')}`].push(fitm);

            const sharedFitment = Object.keys(fitObj)
              .map((key) => {
                if (fitObj[key].length === lengthOfItems) {
                  return fitObj[key][0];
                } else if (fitObj === null) {
                  return fitObj;
                } else {
                  return null;
                }
              })
              .filter((item) => item);
            sharedFit = sharedFitment;
          });
          setSameFit(sharedFit);
        });
        mutListing();
      })();
    } else {
      return;
    }
  }, [paramId]);

  return (
    <>
      <Card style={{ width: '100%' }}>
        <Grid container justifyContent="space-around">
          {/* {fitment.map((item, index) => {
          
            <div key={index}>
              <span>{item}</span>
            </div>;
          })} */}
          <Grid item xs={5}>
            {sameFit.length > 0 ?
              <FitmentTable
                fitmentData={sameFit}
              // variationId={}
              />
              : <Typography>No matching fitment Found</Typography>}
          </Grid>
          <Grid item xs={5}>
            <SupplierTable
              supplierData={sameSup}
              suppliers={suppliers}
            // mutate={}
            />
          </Grid>
          <Grid item xs={11}>
            <ListingTable listingData={listing?.rows} />
          </Grid>
        </Grid>
      </Card>
    </>
  );
}

export default GroupedFLSTable;
