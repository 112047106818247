import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import AsyncSelect from '../../common/Selects/AsyncSelect';
import ProductApi from '@oneAppCore/services/ProductApi';
import Api from '@oneAppCore/services/Api';
import SearchView from '@src/Components/common/containers/SearchView';
import { columns } from './constants';
import recoilSearch from '@src/Components/common/containers/SearchView/hooks/useSearchOptions/index';
import FitmentApi from '@oneAppCore/services/FitmentApi';
import useSearch from '@src/Components/common/containers/SearchView/hooks/useSearch';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: '100%',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
  h5: {
    marginBottom: 15,
    fontWeight: 700,
  },
  product: {
    zIndex: 10,
  },
  label: {
    color: theme.palette.grey[700],
  },
  submitBox: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
  },
}));

type CreateFitmentForm = {
  productId?: number;
  variationId?: number;
  newFitments?: any[];
  years?: any[];
  model?: string;
  make?: string;
  variationName?: string;
  skus?: string;
};

type DefaultValuesType = {
  variations?: any[];
  variationSkus?: any[];
  variationNames?: any[];
  fitments?: any[];
  make?: any[];
  model?: any[];
  years?: any[];
};

type ParamsHook = {
  id?: string;
  name?: string;
};

function CreateFitment() {
  const classes = useStyles();
  const { id, name } = useParams<ParamsHook>();
  const [searchOptions, setSearchOptions] = recoilSearch.useState();
  const [form, setForm] = useState<CreateFitmentForm>({});
  const [options, setOptions] = useState([]);
  const [defaultValues, setDefaultValues] = useState<DefaultValuesType>({
    variations: [],
    variationSkus: [],
    variationNames: [],
    fitments: [],
    make: [],
    model: [],
    years: [],
  });
  const [addNewClicked, setAddNewClicked] = useState(false);
  const [editClicked, setEditClicked] = useState([]);
  const [editData, setEditData] = useState([]);
  const { mutate } = useSearch();

  const filterModels = (models, make) => {
    return models.filter((e) => make === e.make);
  };

  const filterYears = (models, modelId) => {
    const filteredYears = models.filter((e) => modelId == e.id);
    if (filteredYears.length > 0) {
      return filteredYears[0].years;
    }
    return [];
  };

  const loadProducts = async (filter: string) => {
    if (filter?.length < 3) return [];
    const { rows: data } = await ProductApi.search({
      searchFilter: filter,
    });
    const newOptions = data.map((product) => ({
      value: product.id,
      label: `${product.name}-${product.id}`,
      variations: product?.variations,
      skus: product?.variationSkus,
      variationNames: product?.variationNames,
    }));
    setOptions(newOptions);
    return newOptions;
  };

  const update = async (key, value) => {
    if (key == 'productId') {
      const product = options.filter((element) => element.value == value)?.[0];
      setDefaultValues({
        ...defaultValues,
        variations: product.variations,
        variationSkus: product.skus,
        variationNames: product.variationNames,
      });
      setForm({
        productId: value,
        variationId: null,
      });
      return;
    }
    if (key == 'variationId') {
      let index = defaultValues.variations.indexOf(value);
      if (index != -1) {
        setForm((previous) => ({
          ...previous,
          [key]: value,
          years: [],
        }));
        const fitmantData = await Api.get(
          `/api/v1/fitment/get-fitment/${value}`,
        );

        setDefaultValues({ ...defaultValues, fitments: fitmantData.rows });
        setSearchOptions({
          ...searchOptions,
          url: `/api/v1/fitment/get-fitment/${value}`,
          limit: 5,
        });
        return;
      }
    }
    setForm((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  const setDefaults = async () => {
    let fitmentData = [];
    if (id) {
      const response = await Api.get(
        `/api/v1/fitment/get-fitment/${id}?limit=${searchOptions.limit}&page=${searchOptions.page}`,
      );
      if (response.rows) {
        fitmentData = response.rows;
      }
      setForm({ ...form, variationId: Number(id) });
      setSearchOptions({
        ...searchOptions,
        url: `/api/v1/fitment/get-fitment/${id}`,
        limit: 5,
      });
    }
    const { rows: make } = await FitmentApi.getMakes();
    const { rows: model } = await FitmentApi.getModels();
    setDefaultValues({
      ...defaultValues,
      make: make,
      model: model,
      fitments: fitmentData,
    });
  };

  const setDefaultFitment = async (variationId) => {
    const response = await Api.get(
      `/api/v1/fitment/get-fitment/${variationId}?limit=${searchOptions.limit}&page=${searchOptions.page}`,
    );
    if (response?.rows) {
      setDefaultValues({ ...defaultValues, fitments: response.rows });
    }
  };

  useEffect(() => {
    setDefaults();
  }, []);

  useEffect(() => {
    if (id) {
      setDefaultFitment(id);
    } else if (!id && form.variationId) {
      setDefaultFitment(form.variationId);
    }
  }, [searchOptions]);

  const addCancelFitment = () => {
    setForm({
      ...form,
      years: [],
      make: '',
      model: '',
    });
    setAddNewClicked(!addNewClicked);
  };

  const saveFitment = async () => {
    const data = {
      variationId: form.variationId,
      fitment: {
        year: form.years,
        make: form.make,
        model: form.model,
      },
    };
    const fitmentData = await Api.post(`/api/v1/fitment`, data);
    if (fitmentData.status == 'success') {
      mutate();
      setAddNewClicked(!addNewClicked);
    }
  };

  const isSaveDisable = () => {
    if (!(form.years.length > 0) || !form.make || !form.model) {
      return true;
    }
    return false;
  };

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={12}>
          <Card className={classes.root} style={{ width: '100%' }}>
            <Box p={2}>
              <Typography variant="h5" color="primary" className={classes.h5}>
                {id ? 'Edit Fitment' : 'Create Fitment'}
              </Typography>
              {
                <Grid container item sm={12} spacing={3}>
                  <Grid item xs={12} md={6} className={classes.product}>
                    {!id && (
                      <AsyncSelect
                        id="product"
                        required
                        label="Product"
                        loadOptions={loadProducts}
                        value={options.find(
                          (option) => option.value === form.productId,
                        )}
                        onChange={(e) => update('productId', e.value)}
                      />
                    )}
                    {id && name && (
                      <>
                        <Typography
                          variant="caption"
                          component="span"
                          className={classes.label}
                        >
                          Product
                        </Typography>
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="Product"
                          value={name}
                          disabled
                        />
                      </>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6} className={classes.product}>
                    <Typography
                      variant="caption"
                      component="span"
                      className={classes.label}
                    >
                      Variation*
                    </Typography>
                    <FormControl
                      required
                      size="small"
                      fullWidth
                      variant="outlined"
                      className={classes.formControl}
                      disabled={defaultValues.variations.length == 0}
                    >
                      {!id && (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={form.variationId}
                          onChange={(e) =>
                            update('variationId', e.target.value)
                          }
                        >
                          {defaultValues?.variations?.map(
                            (variation, vIndex) => (
                              <MenuItem key={variation} value={variation}>
                                {defaultValues?.variationSkus?.[vIndex]}-
                                {defaultValues?.variationNames?.[vIndex]}
                              </MenuItem>
                            ),
                          )}
                        </Select>
                      )}
                      {id && (
                        <TextField
                          fullWidth
                          variant="outlined"
                          name="variationId"
                          value={id}
                          disabled
                        />
                      )}
                    </FormControl>
                  </Grid>
                  {!id && !form.variationId && (
                    <Grid item xs={12}>
                      <Typography
                        variant="body2"
                        component="span"
                        className={classes.label}
                      >
                        {
                          '(Select a product and variation for that product to get related fitments and add a new fitment.)'
                        }
                      </Typography>
                    </Grid>
                  )}
                  {form.variationId && (
                    <Grid item xs={12}>
                      {!addNewClicked && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => addCancelFitment()}
                        >
                          Add New Fitment
                        </Button>
                      )}
                      {addNewClicked && (
                        <Grid item container xs={12} spacing={1}>
                          <Grid item xs={4} className={classes.product}>
                            <Typography
                              variant="caption"
                              component="span"
                              className={classes.label}
                            >
                              Make*
                            </Typography>
                            <FormControl
                              required
                              size="small"
                              fullWidth
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.make}
                                onChange={(e) => update('make', e.target.value)}
                              >
                                {defaultValues.make.map(({ make, id }) => {
                                  return (
                                    <MenuItem key={id} value={id}>
                                      {make}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} className={classes.product}>
                            <Typography
                              variant="caption"
                              component="span"
                              className={classes.label}
                            >
                              Model*
                            </Typography>
                            <FormControl
                              required
                              size="small"
                              fullWidth
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.model}
                                onChange={(e) =>
                                  update('model', e.target.value)
                                }
                              >
                                {filterModels(
                                  defaultValues.model,
                                  form.make,
                                ).map(({ model, id }) => {
                                  return (
                                    <MenuItem key={id} value={id}>
                                      {model}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Grid item xs={4} className={classes.product}>
                            <Typography
                              variant="caption"
                              component="span"
                              className={classes.label}
                            >
                              Years*
                            </Typography>
                            <FormControl
                              required
                              size="small"
                              fullWidth
                              variant="outlined"
                              className={classes.formControl}
                            >
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={form.years}
                                multiple
                                onChange={(e) =>
                                  update('years', e.target.value)
                                }
                              >
                                {filterYears(
                                  defaultValues.model,
                                  form.model,
                                ).map((year) => {
                                  return (
                                    <MenuItem
                                      key={year}
                                      value={year.toString()}
                                    >
                                      {year}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Grid>
                          <Box className={classes.submitBox}>
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() => saveFitment()}
                              disabled={isSaveDisable()}
                              style={{ margin: 3 }}
                            >
                              Save
                            </Button>
                            <Button
                              variant="outlined"
                              color="secondary"
                              onClick={() => addCancelFitment()}
                              style={{ margin: 3 }}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </Grid>
                      )}
                      <SearchView
                        columns={columns({
                          defaultValues,
                          setDefaultValues,
                          editClicked,
                          setEditClicked,
                          editData,
                          setEditData,
                          mutate,
                        })}
                      />
                    </Grid>
                  )}
                </Grid>
              }
            </Box>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default CreateFitment;
