import { columns, exportOptions } from './constants';
import SearchGrid from '@src/Components/common/containers/SearchGrid';
import SearchView from '@src/Components/common/containers/SearchView';
import { Grid } from '@mui/material';
import ProductApi from '@oneAppCore/services/ProductApi';
import useChannelCategories from '@src/hooks/swr/useChannelCategories';

function OrdersSearch() {
  const { data: channelCategories } = useChannelCategories();
  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <SearchGrid
        url="/api/v1/products/search"
        api={ProductApi}
        select
        selectTypes={['createProduct', 'bulkExport']}
        exportOptions={exportOptions}
        columns={columns(channelCategories)}
        editableColumns={['name', 'channelCategoryId']}
        filters={[
          {
            type: 'date range',
            name: 'Created At',
            keyName: 'createdAt',
          },
          {
            type: 'string',
            name: 'Sku',
            keyName: 'sku',
          },
          {
            type: 'string',
            name: 'MPN',
            keyName: 'mpn',
          },
          {
            type: 'string',
            name: 'Brand',
            keyName: 'brand',
          },
          {
            type: 'string',
            name: 'Category',
            keyName: 'categories',
          },
        ]}
      />
      {/* <SearchView
        url="/api/v1/products/search"
        select
        selectTypes={['createProduct', 'bulkExport']}
        columns={columns()}
        filters={[
          {
            type: 'date range',
            name: 'Created At',
            keyName: 'createdAt',
          },
          {
            type: 'string',
            name: 'Sku',
            keyName: 'sku',
          },
          {
            type: 'string',
            name: 'MPN',
            keyName: 'mpn',
          },
          {
            type: 'string',
            name: 'Brand',
            keyName: 'brand',
          },
          {
            type: 'string',
            name: 'Category',
            keyName: 'categories',
          },
        ]}
        exportOptions={exportOptions}
      /> */}
    </Grid>
  );
}

export default OrdersSearch;
