import BaseApi from '../BaseApi';

type MakesApi = {
  rows: { year: number; make: string; id: number }[];
  count: number;
};

type ModelsApi = {
  rows: { id: number; year: number[]; make: string; model: string }[];
  count: number;
};
class FitmentApi extends BaseApi {
  constructor() {
    super();
  }

  createFitment(payload) {
    return this.postBase<{ status: string }>(`/api/v1/fitment`, payload);
  }

  updateFitment(payload) {
    return this.putBase('/api/v1/fitment', payload);
  }

  getMakes = async (params?: any) => {
    return this.getBase<MakesApi>('/api/v1/fitment/makes', params);
  };

  // getMakes() {
  //   return this.getBase<MakesApi>('/api/v1/fitment/makes');
  // }

  getModels() {
    return this.getBase<ModelsApi>('/api/v1/fitment/models');
  }
}

export default FitmentApi;
