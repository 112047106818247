import { useState, useMemo, FormEvent, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useChannelCategories from '@src/hooks/swr/useChannelCategories';
import useQuery from '@src/hooks/util/useQuery';
import { useHistory } from 'react-router-dom';

import ChannelCategoriesApi from '@oneAppCore/services/ChannelCategoriesApi';
import { formFields } from './constants';
import { pick } from 'lodash';
import queryUtils from '@src/utils/queries';

import { Grid, Typography, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { TextField, Select, Backdrop } from '@src/Components/common';

import type { FormProps } from '@src/Components/common/containers/SearchView/CreationDrawer/types';
import type { ChannelCategory } from '@oneAppCore/types/channelTypes';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
}));

function CategoryViewDrawer({ onSubmit, close }: FormProps) {
  const classes = useStyles();

  const [form, setForm] = useState<ChannelCategory>({});
  const [saving, setSaving] = useState(false);

  const { data: channelCategories = [] } = useChannelCategories();
  const { enqueueSnackbar } = useSnackbar();

  const { id } = useQuery();
  const history = useHistory();

  useEffect(() => {
    if (id) {
      (async () => {
        const response = await ChannelCategoriesApi.getById(Number(id));
        const newForm = pick(response, formFields);
        setForm(newForm);
      })();
    } else {
      setForm({});
    }
  }, [id]);

  const update = <K extends keyof ChannelCategory>(
    key: K,
    value: ChannelCategory[K],
  ) => {
    setForm({
      ...form,
      [key]: value,
    });
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSaving(true);
    let changeCategory;
    try {
      if (id) {
        changeCategory = await ChannelCategoriesApi.put(Number(id), form);
        const newParams = queryUtils.remove('id');
        history.replace({
          search: newParams,
        });
      } else {
        changeCategory = await ChannelCategoriesApi.post(form);
      }
      enqueueSnackbar(`Created Category`, {
        variant: 'success',
      });
      await onSubmit(changeCategory);
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  const categoryOptions = useMemo(
    () =>
      channelCategories
        .filter(
          (category) =>
            category.id !== Number(id) &&
            category.parentCategoryId !== Number(id),
        )
        .map((category) => ({
          label: `${category.categoryName} ${
            category.parentCategoryName
              ? `<- ${category.parentCategoryName}`
              : ''
          }`,
          value: category.id,
        })),
    [channelCategories],
  );

  return (
    <form className={classes.form} onSubmit={submit}>
      <Grid container spacing={2} direction="column" className={classes.form}>
        <Grid item container justifyContent="center">
          <Typography variant="h4">Category Drawer</Typography>
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label="Category Name"
            name="categoryName"
            value={form?.categoryName || ''}
            onChange={(e) => update('categoryName', e.target.value)}
            required
          />
        </Grid>
        <Grid item>
          <Select
            options={categoryOptions}
            label="Parent Category"
            value={categoryOptions.find(
              (option) => option.value === form.parentCategoryId,
            )}
            onChange={(e) => update('parentCategoryId', e.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label="Amazon ID"
            name="amazonCategoryId"
            value={form?.amazonCategoryId || ''}
            onChange={(e) => update('amazonCategoryId', e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label="Ebay ID"
            name="ebayCategoryId"
            value={form?.ebayCategoryId || ''}
            onChange={(e) => update('ebayCategoryId', e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label="Walmart ID"
            name="walmartCategoryId"
            value={form?.walmartCategoryId || ''}
            onChange={(e) => update('walmartCategoryId', e.target.value)}
          />
        </Grid>
        <Grid item>
          <TextField
            fullWidth
            variant="outlined"
            label="WooCommerce ID"
            name="wooCommerceCategoryId"
            value={form?.wooCommerceCategoryId || ''}
            onChange={(e) => update('wooCommerceCategoryId', e.target.value)}
          />
        </Grid>
        <Grid item container justifyContent="space-around">
          <Grid item xs={5}>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={close}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={5}>
            <Button variant="contained" color="primary" type="submit" fullWidth>
              Save
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Backdrop open={saving} />
    </form>
  );
}

export default CategoryViewDrawer;
