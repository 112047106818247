import dayjs from 'dayjs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getColor } from '@src/utils/colors';
import numberUtils from '@oneAppCore/utils/numbers';
import ViewDrawerButton from './ViewDrawerButton';
import EditButton from './EditButton';
import {
  Grid,
  Typography,
  Link,
  Chip,
} from '@mui/material';
import type {
  Column,
} from '@src/Components/common/containers/SearchView/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import { dateFormat } from '@oneAppCore/constants/dates';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import {
  ACTIVE,
  DRAFT,
  INACTIVE,
  PENDING,
  REJECTED,
} from '@oneAppCore/constants/listings';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { BOOTSTRAP, GREENS, ORANGES, REDS } from '@oneAppCore/constants/colors';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  channelIds,
} from '@oneAppCore/constants/channels';
import TooltipTable from '@src/Components/common/TooltipTable';
import { orderProfitColors, orderStatusColors } from '@src/utils/orders';

export const columns = (
  changePriceFunc,
  tableRowsState,
  classes,
  // selectRow,
  // deselectRow,
): Column[] => [
    {
      title: 'Image',
      keyName: 'thumbnail',
      sortBy: false,
      align: 'center',
      customComponent: function ImageRender(row) {
        return (
          <img
            src={
              row.listingImages?.length
                ? row.listingImages[0]
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
            }}
            alt=""
            width={50}
            height={50}
          />
        );
      },
    },
    {
      title: 'Listing',
      keyName: 'listingName',
      sortBy: false,
      align: 'left',
      customComponent: function NameRender(row) {
        return (
          <Grid
            container
            item
            xs={12}
            key={row.id}
            style={{ minWidth: '170px', justifyContent: 'center' }}
          >
            <Grid item xs={12}>
              <div style={{ minWidth: '150px' }}>
                {row.name}
                {row.variation ? ` - (${row.variation})` : ''}
              </div>
            </Grid>
            <Grid item xs={12} spacing={1}>
              <div
                style={{
                  minWidth: '150px',
                  justifyContent: 'center',
                }}
              >
                <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
                  Channel ID:
                </span>
                {row?.channelProductId}
              </div>
            </Grid>
            <Grid item xs={12} spacing={1}>
              <div style={{ minWidth: '150px' }}>
                <span style={{ fontWeight: 'bold', marginRight: '3px' }}>SKU:</span>
                {row?.listingSku}
              </div>
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'Created At',
      keyName: 'createdAt',
      sortBy: true,
      align: 'center',
      customComponent: function DateRender(row) {
        return (
          <p style={{ margin: 0 }}>{dayjs(row.createdAt).format(dateFormat)}</p>
        );
      },
    },
    {
      title: 'Channel',
      keyName: 'channelName',
      align: 'center',
      customComponent: function Channel(row) {
        let href = '';
        if (row?.channelProductId) {
          if (row?.channelId === channelIds[AMAZON]) {
            href = AMAZON_ASIN_LINK(row?.channelProductId);
          } else if (row?.channelId === channelIds[WALMART]) {
            href = WALMART_PRODUCT_LINK(row?.channelProductId);
          } else if (row?.channelId === channelIds[EBAY]) {
            href = EBAY_PRODUCT_LINK(row?.channelProductId);
          }
        }
        return (
          <Grid
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: 5,
            }}
          >
            <img
              width={35}
              height={35}
              style={{ borderRadius: 10 }}
              src={
                row.icon != null
                  ? row.icon
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
            />
            {href !== '' && (
              <Link href={href} target="_blank">
                <FontAwesome name="external-link-alt" type="fa" form="fa" />
              </Link>
            )}
          </Grid>
        );
      },
    },
    {
      title: 'Price',
      keyName: 'price',
      sortBy: true,
      align: 'center',
      customComponent: function Price(row) {
        return (
          <Grid item>
            <Typography>{formatCentsToDollarsIntl(row.price)}</Typography>
          </Grid>
        );
      },
    },
    {
      title: 'Cost',
      keyName: 'cost',
      align: 'center',
      customComponent: function cost(row) {
        const { supplierArray } = row;
        // const lowestCostArray = supplierArray?.map((supplier) =>
        //   supplier.estimatedShipping > 0 &&
        //     supplier.estimatedShipping < supplier.shippingCost
        //     ? supplier.cost + supplier.estimatedShipping
        //     : supplier.cost + supplier.shippingCost,
        // );
        // const lowestSupplierCost = Array.isArray(lowestCostArray)
        //   ? formatCentsToDollarsIntl(Math.min(...lowestCostArray))
        //   : formatCentsToDollarsIntl(row.lowcost);

        const tooltipColumns: Column[] = [
          {
            title: 'Supplier',
            type: 'string',
            keyName: 'supplier',
          },
          {
            title: 'Cost',
            type: 'money',
            keyName: 'cost',
          },
          {
            title: 'Act. Ship',
            type: 'money',
            keyName: 'shippingCost',
          },
          {
            title: 'Est. Ship',
            type: 'money',
            keyName: 'estimatedShipping',
          },
        ];
        const tooltipRows = supplierArray?.map(({ metaData, name, cost, shippingCost, estimatedShipping }) => ({
          supplier: metaData?.shortName ||
            name
              .match(/\b(\w)/g)
              .join('')
              .toUpperCase(),
          cost,
          shippingCost,
          estimatedShipping,
        })) || [];
        return (
          <TooltipTable
            columns={tooltipColumns}
            rows={tooltipRows}
          >
            <Grid item>
              <Typography style={{ maxWidth: '120px' }}>
                {formatCentsToDollarsIntl(row?.minCost)}
              </Typography>
            </Grid>
          </TooltipTable>
        );
      },
    },
    {
      title: 'Profit',
      keyName: 'profitPercentage',
      sortBy: true,
      align: 'center',
      customComponent: function cost(row) {
        const backgroundColor = (() => {
          const colorsAndTints: any[] = [
            { tint: 'main', color: 'red' },
            { tint: 'main', color: 'orange' },
            { tint: 'main', color: 'green' },
          ];
          return orderProfitColors(row?.profit, [0, 5], colorsAndTints);
        })();
        const { profit } = row;
        let profitInDollar;
        if (profit) {
          profitInDollar = parseFloat(`${Number(profit) / 100}`).toFixed(2);
        } else {
          profitInDollar = '0';
        };
        return (
          <Grid
            container
            item
            xs={12}
            key={row.id}
            style={{ minWidth: '170px', justifyContent: 'center' }}
          >
            {' '}
            <Grid item xs={12}>
              {profitInDollar.includes('-') ? '-$' : '$'}{profitInDollar.includes('-') ? profitInDollar.slice(1) : profitInDollar}
              {/* Note: converted to dollars manually, because the formatCentsToDollarsIntl function was giving NaN values for some. */}
              {/* {formatCentsToDollarsIntl(row?.profit)} */}
            </Grid>
            <br />
            <Grid item xs={5}>
              <Chip
                style={{
                  backgroundColor: backgroundColor,
                  color: 'white',
                  width: '100%',
                  height: '100%',
                }}
                label={`${row?.profitPercentage}%`}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      title: 'Sales Count',
      keyName: 'salesCount',
      sortBy: true,
      align: 'center',
    },
    {
      title: 'Inventory',
      keyName: 'quantity',
      align: 'center',
    },
    {
      title: 'Created By',
      keyName: 'createdByName',
      sortBy: true,
      align: 'center',
    },
    /* {
          title: 'Sales Last 14 Days (Now/Prev.)',
          keyName: 'sales_14_days',
          sortBy: false, // change back once sales 14 days is less memory intensive
          align: 'center',
          customComponent: function Sales(row) {
            const sales = (row.orderItems || []).reduce((prev, curr) => prev + curr.itemPrice, 0);
            const prevSales = (row.orderItemsPrev || []).reduce((prev, curr) => prev + curr.itemPrice, 0);
            const color = sales > prevSales ? GREENS.dark : sales === prevSales ? ORANGES.dark : REDS.dark;
            return (
              <>
                <Grid item>
                  {<Typography>
                    <span style={{ color }}>{formatCentsToDollarsIntl(sales)}</span>&nbsp;
                    / {formatCentsToDollarsIntl(prevSales)}
                  </Typography>}
                </Grid>
              </>
            );
          },
        }, */
    {
      title: 'Status',
      keyName: 'status',
      align: 'center',
      customComponent: function StatusRender(row) {
        const { status } = row;
        const background =
          status === DRAFT
            ? BOOTSTRAP.v0.warning.color
            : status === INACTIVE
              ? BOOTSTRAP.v0.dark.color
              : status === PENDING
                ? BOOTSTRAP.v0.light.color
                : status === ACTIVE
                  ? BOOTSTRAP.v0.success.color
                  : status === REJECTED
                    ? BOOTSTRAP.v0.danger.color
                    : BOOTSTRAP.v0.light.color;
        return (
          <Grid item>
            <p
              style={{
                background,
                fontFamily: 'Montserrat, sans-serif',
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '18px',
                padding: '1px 9px',
                color: 'white',
                borderRadius: '17px',
                textAlign: 'center',
              }}
            >
              {row?.status?.charAt(0).toUpperCase() + row?.status?.slice(1)}
            </p>
          </Grid>
        );
      },
    },
    {
      keyName: 'actions',
      title: 'Actions',
      customComponent: function ActionButtons(row) {
        return (
          <Grid container xs={12}>
            <Grid item xs={6}>
              <EditButton row={row} />
            </Grid>
            <Grid item xs={6}>
              <ViewDrawerButton id={row.id} row={row} />
            </Grid>
          </Grid>
        );
      },
    },
  ];
