import { useEffect, useState } from 'react';

import { colorTheme } from '@oneAppCore/constants/issues';

import Grid from '@mui/material/Grid';
import { createTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import BarChart from '@src/Components/common/charts/BarChart';

import ReportApi from '@oneAppCore/services/ReportApi';

import { Props } from '../types';
import {  Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import dateUtils from '@oneAppCore/utils/dates';

const useStyles = makeStyles((theme: Theme) => ({
  width: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    [theme.breakpoints.up('md')]: {
      width: '80%',
    },
  },
  height: {},
}));

function IssueTypesChart({ unitsBack, unitType }: Props) {
  const [data, setData] = useState([]);
  const [labels, setLabels] = useState([]);
  const theme = createTheme(colorTheme);
  const classes = useStyles();

  const colors = [
    theme.palette.primary.light,
    theme.palette.secondary.main,
    theme.palette.warning.dark,
    theme.palette.error.dark,
    theme.palette.info.dark,
    theme.palette.success.dark,
    theme.palette.primary.main,
    theme.palette.secondary.light,
    theme.palette.warning.main,
    theme.palette.error.main,
    theme.palette.info.main,
    theme.palette.success.main,
  ];

  useEffect(() => {
    (async () => {
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      setData([]);
      const response = await ReportApi.get('ORDER_ISSUES', {
        newerThan,
        page: 1,
        limit: 1000000,
      });
      const obj = {};
      const rows = response.rows.reduce((acc, row) => {
        const keyName = row['Issue Type'];
        return {
          ...acc,
          [keyName]: acc[keyName],
        };
      }, {});
      const labelRows = Object.keys(rows)
        .sort()
        .reduce(
          (acc, key) => ({
            ...acc,
            [key]: rows[key],
          }),
          {},
        );
      response.rows.forEach((row) => {
        if (!obj[row['Resolved By']]) obj[row['Resolved By']] = [];
        obj[row['Resolved By']].push(row);
      });
      const orderedRows: {
        data: any;
        label: string;
        backgroundColor: string;
        hoverOffset: number;
      }[] = [];
      Object.keys(obj).forEach((objKey, objIndex) => {
        const rows = obj[objKey].reduce((acc, row) => {
          const keyName = row['Issue Type'];
          return {
            ...acc,
            [keyName]: row['Issue Count'],
          };
        }, {});
        let arrayOfOrderedRows = [];
        Object.keys(labelRows)
          .filter((name) => name !== 'length')
          .forEach((labelName) => {
            if (!arrayOfOrderedRows[labelName])
              arrayOfOrderedRows[labelName] = 0;
          });
        const reducedRows = Object.keys(labelRows)
          .sort()
          .reduce((acc, key) => {
            let returningReducedObject = {
              ...acc,
              [key]: rows[key],
            };
            if (!rows[key])
              returningReducedObject = {
                ...acc,
                [key]: 0,
              };
            return returningReducedObject;
          }, {});
        arrayOfOrderedRows = Object.keys(reducedRows).map((reducedRow) => {
          return reducedRows[reducedRow];
        });

        const backgroundColor = colors[objIndex % 12];
        let ret = {
          data: arrayOfOrderedRows,
          label: objKey,
          backgroundColor,
          hoverOffset: 5,
        };
        orderedRows.push(ret);
      });
      setData(orderedRows);
      setLabels(Object.keys(labelRows));
    })();
  }, [unitsBack]);

  return (
    <Grid
      container
      justifyContent="center"
      direction="column"
      alignItems="center"
      spacing={2}
    >
      <Grid item>
        <Typography variant="h5">Issue Types</Typography>
      </Grid>
      <Grid item className={classes.width}>
        <BarChart
          labels={labels}
          data={data}
          stacked
          title="Issue Types"
          plugins={{
            legend: {
              display: true,
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default IssueTypesChart;
