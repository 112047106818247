import { useState, useEffect, FormEvent } from 'react';
import { Grid } from '@mui/material';

import { columns } from './constants';
import SearchView from '@src/Components/common/containers/SearchView';
import companyApi from '@oneAppCore/services/CompanyApi'

function LabelReport() {
  const [companyProperties, setCompanyList] = useState([]);

  useEffect(() => {
    const func = async () => {
      try {
        const companies = await companyApi.getAll();
        setCompanyList(companies.map(c => ({ label: c.name, value: c.id })))
      } catch (e) {
      }
    }
    func();
  })

  return (
    <Grid item container justifyContent="center" alignItems="center" spacing={1}>
      <SearchView
        url="/api/v1/print-label/shipstation-report"
        columns={columns}
        filters={
          [
            {
              type: 'date range',
              name: 'Created At',
              keyName: 'createdAt',
            },
            {
              type: 'selector',
              name: 'Company',
              keyName: 'companyId',
              properties: companyProperties,
            },
          ]
        }
      />
    </Grid>
  );
}

export default LabelReport;
