import { useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import dayjs from 'dayjs';
import ReportApi from '@oneAppCore/services/ReportApi';
import { Props } from '../types';
import { defaultCardResponse } from './constants';
import { CardResponseType } from './types';
import dateUtils from '@oneAppCore/utils/dates';

function InventoryStats({ supplierId, unitsBack, unitType }: Props) {
  const [instock, setInstock] = useState<CardResponseType>(defaultCardResponse);
  const [inbound, setInbound] = useState<CardResponseType>(defaultCardResponse);
  const [zeroSales, setZeroSales] = useState<CardResponseType>(
    defaultCardResponse,
  );
  const [lowStock, setLowStock] = useState<CardResponseType>(
    defaultCardResponse,
  );

  useEffect(() => {
    (async () => {
      setInstock(defaultCardResponse);
      setInbound(defaultCardResponse);
      setZeroSales(defaultCardResponse);
      setLowStock(defaultCardResponse);
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      
      ReportApi.get('INSTOCK_INVENTORY', {
        newerThan,
        page: 1,
        limit: 1000000,
        supplierId,
      }).then((instockResponse) => {
        const [row] = instockResponse.rows;
        setInstock(row);
      });
      ReportApi.get('INBOUND_INVENTORY', {
        newerThan,
        page: 1,
        limit: 1000000,
        supplierId,
      }).then((inboundResponse) => {
        const [row] = inboundResponse.rows;
        setInbound(row);
      });
      ReportApi.get('ZERO_SALES', {
        newerThan,
        page: 1,
        limit: 1000000,
        supplierId,
      }).then((zeroSalesResponse) => {
        const [row] = zeroSalesResponse.rows;
        setZeroSales(row);
      });
      ReportApi.get('PRODUCTS_LOW_INVENTORY', {
        newerThan,
        page: 1,
        limit: 1000000,
        supplierId
      }).then((lowStockResponse) => {
        const [row] = lowStockResponse.rows;
        setLowStock(row);
      });
    })();
  }, [unitsBack, supplierId]);

  return (
    <Grid>
      <Typography variant="h5" style={{ color: '#616161', marginBottom: 10 }}>
        Inventory Stats
      </Typography>
      <Grid key="instock-stats" container style={{ marginTop: 5 }}>
        <Grid item xs={6}>
          <Typography key="instock-inv-title">
            <strong>In Stock:</strong>
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>
            {!isNaN(instock.value) && (
              <>
                {Number(instock.qty).toLocaleString()} / $
                {(Math.round(instock.value) / 100000).toFixed(2)}K
              </>
            )}
            {isNaN(instock.value) && (
              <>
                <span>Loading...</span>
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid key="inbound-stats" container style={{ marginTop: 5 }}>
        <Grid item xs={6}>
          <Typography key="inbound-inv-title">
            <strong>Inbound Inventory :</strong>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>
            {!isNaN(inbound.value) && (
              <>
                {Number(inbound.qty).toLocaleString()} / $
                {/* {inbound.value}K */}
                {(Math.round(inbound.value) / 100000).toFixed(2)}K
              </>
            )}
            {isNaN(inbound.value) && (
              <>
                <span>Loading...</span>
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid key="low-stock-stats" container style={{ marginTop: 5 }}>
        <Grid item xs={6}>
          <Typography key="low-stock-title">
            <strong>Products Low Inventory :</strong>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>
            {!isNaN(lowStock.value) && (
              <>
                {Number(lowStock.qty).toLocaleString()} / $
                {(Math.round(lowStock.value) / 100000).toFixed(2)}K
              </>
            )}
            {isNaN(lowStock.value) && (
              <>
                <span>Loading...</span>
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid key="zero-sales-stats" container style={{ marginTop: 5 }}>
        <Grid item xs={6}>
          <Typography key="zero-sales-title">
            <strong>Zero Sales 30+ Days:</strong>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography>
            {!isNaN(zeroSales.value) && (
              <>
                {Number(zeroSales.qty).toLocaleString()} / $
                {(Math.round(zeroSales.value) / 100000).toFixed(2)}K
              </>
            )}
            {isNaN(zeroSales.value) && (
              <>
                <span>Loading...</span>
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default InventoryStats;
