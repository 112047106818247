import BaseApi from './BaseApi';

import type {
  ManufacturerSupplierInternal,
  ManufacturerSupplierVirtualInventory,
  ManufacturerSupplierInternalPOBody,
} from '../types/restockTypes';

type SearchResponse = {
  data: ManufacturerSupplierInternal[];
  count: number;
}

class RestockApi extends BaseApi {
  constructor() {
    super();
  }

  create = async (params: Partial<ManufacturerSupplierInternal>) => {
    return this.postBase(`/api/v1/manufacturer-suppliers-restock`, params);
  };

  update = async (id:number, updateBody: Partial<ManufacturerSupplierInternal>) => {
    return this.putBase(`/api/v1/manufacturer-suppliers-restock/${id}`, updateBody);
  };

  readPOByUPC = async (upc:string, body: ManufacturerSupplierInternalPOBody) => {
    return this.getBase(`/api/v1/manufacturer-suppliers-restock/upc/${upc}`, body);
  };

  readAllPO = async (body: ManufacturerSupplierInternalPOBody) => {
    console.log('readAllPO', body);
    return this.getBase<SearchResponse>(`/api/v1/manufacturer-suppliers-restock/read`, body);
  };

  updateVirtualInventory = async (
    manufacturerId: number,
    updateBody: Partial<ManufacturerSupplierVirtualInventory>,
  ) => {
    return this.putBase(
      `/api/v1/manufacturer-suppliers-restock/virtual/${manufacturerId}`,
      updateBody,
    );
  };
  async showWpsCart(po_number: string) {
    return this.getBase(`/api/v1/manufacturer-suppliers-restock/wps/cart/${po_number}`);
  }
  async getWpsOrder(po_number: string) {
    return this.getBase(`/api/v1/manufacturer-suppliers-restock/wps/order/${po_number}`);
  }
  async deleteWpsCart(po_number: string) {
    return this.deleteBase(`/api/v1/manufacturer-suppliers-restock/wps/cart/${po_number}`);
  }
  async createWpsCart(po_number: string) {
    return this.postBase(`/api/v1/manufacturer-suppliers-restock/wps/cart/${po_number}`);
  }
  async addItemsToWpsCart(po_number: string, payload: any) {
    return this.putBase(`/api/v1/manufacturer-suppliers-restock/wps/cart/${po_number}`, payload);
  }
  async submitWpsRestockCart(po_number: string) {
    return this.postBase(`/api/v1/manufacturer-suppliers-restock/wps/cart/${po_number}/submit`);
  }
  async calculateAutoDistOrder(po_number: string, payload: any) {
    return this.postBase(`/api/v1/manufacturer-suppliers-restock/ad/calculate/${po_number}`, payload);
  }
  async placeAutoDistOrder(po_number: string, payload: any) {
    return this.postBase(`/api/v1/manufacturer-suppliers-restock/ad/order/${po_number}`, payload);
  }
}

export default new RestockApi();
