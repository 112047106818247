import {
  TableCell,
  TableRow,
  Table,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { packTable } from './constants';
import type { Packs, PackProps, PackConstant, PackValues } from './types';
import FontAwesome from '@src/Components/common/FontAwesome';
import FitmentApi from '@oneAppCore/services/FitmentApi';
import AddPackRow from './AddPackRow';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  headerColor: {
    border: 0,
    margin: 5,
  },
  fontColor: {
    color: '#FFF',
    backgroundColor: '#6e6b7b',
    fontSize: 10,
  },
  bodyFont: {
    fontSize: 12,
  },
  tableContainer: {
    maxHeight: 440,
    maxWidth: '100%',
    overflowX: 'hidden',
    margin: 10,
    borderRadius: 5,
    border: '1px solid #6e6b7b',
  },
}));

const MultipackTable = ({ packData, variationId, mutate }: PackProps) => {
  const [multipack, setMultipack] = useState<PackValues>({
    title: '',
    sku: '',
    manNo: '',
  });
  const [enableEdit, setEnableEdit] = useState<boolean>(null);
  const [editableColumn, setEditableColumn] = useState<{
    packIndex: number;
  }>(null);

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table stickyHeader>
        <TableHead>
          <TableRow className={classes.headerColor}>
            {packTable.map((pack: PackConstant, i) => (
              <TableCell
                className={classes.fontColor}
                sx={{
                  backgroundColor: '#72717a',
                  color: '#fff',
                }}
                key={'pack_' + pack.keyName + i}
              >
                {pack.title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {packData &&
          packData.map((pack: Packs, packIndex) => {
            return (
              <TableRow key={pack.id}>
                {packTable.map((key, index) => {
                  if (key.keyName === 'id') {
                    let check =
                      enableEdit && editableColumn.packIndex === packIndex;
                    return (
                      <TableCell key={'pack_' + key.keyName + pack.id}>
                        <FontAwesome
                          name={check ? 'check' : 'edit'}
                          color={check ? 'secondary' : 'primary'}
                          type="fas"
                          form="fa"
                          onClick={() => {
                            console.log('testing');
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </TableCell>
                    );
                  } else if (
                    enableEdit &&
                    editableColumn.packIndex === packIndex
                  ) {
                    return (
                      <TableCell key={`edit-${index}`}>
                        <Typography>{key.keyName === 'title'}</Typography>
                      </TableCell>
                    );
                  } else if (key.customComponent) {
                    return (
                      <TableCell
                        className={classes.bodyFont}
                        key={'pack_' + key.keyName + pack.id}
                      >
                        {key.customComponent(pack[key.keyName])}
                      </TableCell>
                    );
                  } else {
                    return (
                      <TableCell
                        className={classes.bodyFont}
                        key={'pack_' + key.keyName + pack.id}
                      >
                        {pack[key.keyName]}
                      </TableCell>
                    );
                  }
                })}
              </TableRow>
            );
          })}
        <AddPackRow mutate={mutate} variationId={variationId} />
      </Table>
    </TableContainer>
  );
};
export default MultipackTable;
