import { useEffect, useState, Fragment } from 'react';
import {
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportApi from '@oneAppCore/services/ReportApi';
import { Props } from '../types';
import { primary } from '@src/Components/Theme/themes/constants';
import dateUtils from '@oneAppCore/utils/dates';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'scroll',
  },
});

function TopProductsCard({ unitsBack, unitType }: Props) {
  const classes = useStyles();
  const [cardData, setCardData] = useState(null);
  const [pastData, setPastData] = useState(null);
  const [topProducts, setTopProducts] = useState(null);
  const [prevDays, setPrevDays] = useState(0);

  useEffect(() => {
    (async () => {
      setCardData(null);
      const { newerThan, newerThan2, daysPrev } = dateUtils.getDaysBack({ unitsBack, unitType });
      setPrevDays(daysPrev);

      const response = await ReportApi.get('TOP_PRODUCTS', {
        newerThan,
        page: 1,
        limit: 10,
      });

      const pastResponse = await ReportApi.get('TOP_PRODUCTS', {
        newerThan: newerThan2,
        olderThan: newerThan,
        page: 1,
        limit: 10,
      });
      setCardData({ response });
      setPastData({ pastResponse });
    })();
  }, [unitsBack]);

  return (
    <Grid style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Typography variant="h5" style={{ color: '#616161', marginBottom: 10 }}>
        Top 10 Products
      </Typography>
      <Grid
        key="top-prods-header"
        container
        style={{
          marginTop: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid item container xs={4}>
          <Typography variant="h6">Name</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">SKU</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">QTY</Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="h6">Prev.</Typography>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Divider style={{ background: primary.main, marginBottom: 5 }} />
      </Grid>

      {cardData?.response?.rows.map((row, index) => {
        const pastCount = pastData?.pastResponse?.rows[index]?.count ?? 0;
        const textColor =
          row?.count > pastCount
            ? 'green'
            : row?.count < pastCount
              ? 'red'
              : 'inherit';
        return (
          <Fragment key={`top-prods-${row?.name}-${row?.sku}-${index}`}>
            <Grid
              key={`top-prods-${row?.name}-${row?.sku}-${index}`}
              container
              style={{
                marginTop: 10,
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Grid item xs={5}>
                <Typography key={`${index}-top-prod-name-${row?.name}`}>
                  {row?.name}
                </Typography>
              </Grid>
              <Grid item xs={3} style={{ overflowWrap: 'anywhere' }}>
                <Typography>{row?.sku}</Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography style={{ color: textColor }}>
                  {Number(row?.count).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography>{Number(pastCount).toLocaleString()}</Typography>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Divider style={{ marginTop: 5, marginBottom: 10 }} />
            </Grid>
          </Fragment>
        );
      })}
    </Grid>
  );
}

export default TopProductsCard;
