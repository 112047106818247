import { useEffect, useState } from 'react';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Grid, IconButton, Tooltip, Modal, Button } from '@mui/material';
import FontAwesome from '@src/Components/common/FontAwesome';
import type { Props } from './types';
import { useSnackbar } from 'notistack';
import OrdersApi from '@oneAppCore/services/OrdersApi';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import { CHANNEL_REASONS_BY_ID } from '@oneAppCore/constants/channels';
import { Select } from '@src/Components/common';
import { addSpaceBeforeCapital } from '@oneAppCore/utils/formatters';

const useStyles = makeStyles((theme: Theme) => ({
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 50,
  },
  containerClass: {
    width: '100%',
    marginBottom: '-5px',
    position: 'relative',
  },
}));

function CancelButton({ row }: Props) {
  const [open, setOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState<string>('');
  const [reasonList, setReasonList] = useState([]);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const x = async () => {
      const reasons = CHANNEL_REASONS_BY_ID[row.channelId]?.map((r) => ({
        label: addSpaceBeforeCapital(r),
        value: r,
      }));
      setReasonList(reasons);
    };
    x();
  }, [row]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setCancelReason('');
    setOpen(false);
  };

  const handleReason = (reason: string) => {
    setCancelReason(reason);
  };

  const cancelOrder = async () => {
    try {
      const cancelPayload = {
        orderStatus: 'CANCELLED',
        issueType: cancelReason,
      };
      await OrdersApi.update(row.id, cancelPayload);
      await OrdersApi.revertInternal(row.orderItems.map(({ id, supplierId, quantity, storeListingId }) => ({ id, supplierId, quantity, storeListingId })))
      enqueueSnackbar('Cancelled Order', {
        variant: 'success',
      });
    } catch (e) {
      enqueueSnackbar('Error Cancelling Order', {
        variant: 'error',
      });
    } finally {
      handleClose();
    }
    await OrdersApi.cancel(row.id);
  };

  return (
    <>
      <Grid item container>
        <Tooltip title={'Cancel Order'}>
          <IconButton
            style={{
              fontSize: '14px',
              color: '#B61F1F',
              paddingLeft: '15%',
              paddingRight: '15%',
            }}
            onClick={handleOpen}
          >
            {/* <FontAwesome
              name="times-circle"
              type="far"
              form="fa"
              onClick={handleOpen}
            /> */}

            <CancelTwoToneIcon
              style={{ color: '#B61F1F' }}

            />
          </IconButton>
        </Tooltip>
        <Modal
          className={classes.modal}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            style={{
              width: 500,
              backgroundColor: 'white',
              justifyContent: 'center',
              borderRadius: 10,
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                justifyContent: 'center',
                width: '100%',
                borderRadius: '10px 10px 0px 0px',
              }}
            >
              <h2>Are you sure?</h2>
            </Grid>
            <Grid item container xs={10} style={{ justifyContent: 'center' }}>
              <p
                style={{
                  fontSize: '20px',
                  marginTop: 15,
                  marginBottom: 15,
                  textAlign: 'center',
                }}
              >
                This will cancel the customers order.
              </p>
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ padding: 10, justifyContent: 'center' }}
            >
              <Select
                containerClass={classes.containerClass}
                options={reasonList}
                value={reasonList.find(
                  (option) => option.value === cancelReason,
                )}
                onChange={(e) => handleReason(e.value)}
                placeholder={'Select Cancel Reason'}
                label={'Reason'}
                required
              />
            </Grid>
            <Grid
              item
              container
              xs={12}
              style={{ padding: 10, justifyContent: 'flex-end' }}
            >
              <Grid
                item
                container
                xs={2}
                style={{ justifyContent: 'flex-end' }}
              >
                <Button
                  variant="outlined"
                  style={{ border: '1px solid red', color: 'red' }}
                  onClick={() => {
                    handleClose();
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                container
                xs={5}
                style={{ padding: 10, justifyContent: 'flex-end' }}
              >
                <Button
                  title={
                    !cancelReason
                      ? 'Please select a reason to cancel order!'
                      : 'Cancel Order with Specified Reason'
                  }
                  variant="contained"
                  color="primary"
                  disabled={!cancelReason}
                  onClick={() => {
                    cancelOrder();
                  }}
                >
                  Cancel Order
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      </Grid>
    </>
  );
}

export default CancelButton;
