import BaseApi from '../BaseApi';
import type { Group } from '@oneAppCore/types/groupTypes';

class ItemGroupsApi extends BaseApi {
  constructor() {
    super();
  }

  getGroupId(id: number) {
    return this.getBase<any>(`/api/v1/item-groups/${id}`);
  }
}

export default ItemGroupsApi;
