import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useMe from '@src/hooks/swr/useMe';

import { removeJwtToken } from '@oneAppCore/services/Jwt';
import Api from '@oneAppCore/services/Api';

export default function Logout() {
  const history = useHistory();
  const { mutate: mutateMe } = useMe();

  useEffect(() => {
    (async () => {
      try {
        await Api.post('/api/v1/logout');
        removeJwtToken();
        await mutateMe();
        history.push('/login');
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  return <></>;
}
