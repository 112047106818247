import { Fragment, useEffect, useState } from 'react';

import {
  TableBody,
  TableCell,
  TableRow,
  Collapse,
  TextField,
  Tabs,
  Tab,
  Grid,
  Divider,
  InputAdornment,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { createTheme, Theme } from '@mui/material/styles';

import useProduct from '../../hooks/useProduct';
import type { Props } from './types';
import FitmentTable from './fitment';
import MultipackTable from './MultipackTable';
import SupplierTable from './suppliers';
import ListingTable from './listings';
import {
  convertDollarsToCents,
  formatCentsToDollars,
} from '@src/utils/currency';

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 2880,
      // tablet: 640,
      // laptop: 2000,
      // desktop: 3000,
    },
  },
});

// declare module '@mui/material/createBreakpoints' {
//   interface BreakpointOverrides {
//     xs: true;
//     sm: true;
//     md: true;
//     lg: true;
//     xl: true;
//     tablet: true;
//     laptop: true;
//     desktop: true;
//   }
// }

const useStyles = makeStyles((theme: Theme) => ({
  boxContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
  },
  cellPadding: {
    paddingBottom: 0,
    paddingTop: 0,
    width: '120%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  gridWidth: {
    [theme.breakpoints.up('lg')]: {
      width: 'fullWidth',
    },
    [theme.breakpoints.up('xl')]: {
      width: '115%',
    },
  },
}));

function tabProps(index) {
  return {
    id: `tab-${index}`,
  };
}

function TableRows({
  rows,
  columns,
  mutate,
  page,
  rowsPerPage,
  openedCell,
  openedType,
  setOpenedCell,
  imageCell,
  setImageCell,
  suppliers,
}: Props) {
  const classes = useStyles();
  const { data: product, mutate: mutateProduct } = useProduct();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [editCell, setEditCell] = useState<number>();
  const [value, setValue] = useState(0);
  const [modifiedCell, setModifiedCell] = useState({
    sku: '',
    manufacturerNo: '',
    title: '',
    itemPrice: 0,
    msrp: null,
    map: null,
  });

  const paginate = (array: any[], page_size: number, page_number: number) => {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
  };

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (editCell != undefined) {
      const {
        variation: title,
        sku,
        manufacturerNo,
        itemPrice,
        msrp,
        map,
      } = paginationData[editCell];
      setModifiedCell({ title, sku, manufacturerNo, itemPrice, msrp, map });
    }
  }, [editCell]);

  useEffect(() => {
    setEditMode(false);
  }, [page]);

  const paginationData = paginate(rows, rowsPerPage, page + 1);

  const centFields: string[] = ['itemPrice', 'map', 'msrp'];

  return (
    <>
      <TableBody>
        {paginationData.map((row, index) => (
          <Fragment key={row.id + index}>
            <TableRow>
              {columns.map((column) => {
                if (editMode && column.editable && editCell === index) {
                  return (
                    <TableCell
                      key={`${column.title}-${column.keyName}`}
                      style={{ minWidth: '130px' }}
                    >
                      {/* <TextField
                        variant="outlined"
                        // onChange={(e) => {
                        //   console.log(e.target.value);
                        //   setModifiedCell((cell) => ({
                        //     ...cell,
                        //     [column.keyName]: centFields.includes(
                        //       column.keyName,
                        //     )
                        //       ? convertDollarsToCents(e.target.value)
                        //       : e.target.value,
                        //   }));
                        // }}

                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const keyName = column.keyName;

                          setModifiedCell((cell) => ({
                            ...cell,
                            [keyName]:
                              inputValue === '' &&
                              (keyName === 'map' || keyName === 'msrp')
                                ? null
                                : centFields.includes(keyName)
                                ? convertDollarsToCents(inputValue)
                                : inputValue,
                          }));
                        }}
                        label={column.title}
                        defaultValue={
                          // centFields.includes(column.keyName)
                          //   ? formatCentsToDollars(row[column.keyName], {
                          //       removeMoneySign: true,
                          //     })
                          //   : row[column.keyName]

                          column.keyName === 'itemPrice' ||
                          column.keyName === 'msrp' ||
                          column.keyName === 'map'
                            ? (row[column.keyName] / 100).toFixed(2)
                            : row[column.keyName]
                        }
                      /> */}
                      <TextField
                        variant="outlined"
                        InputProps={{
                          startAdornment: ['map', 'msrp', 'itemPrice'].includes(
                            column.keyName,
                          ) ? (
                            <InputAdornment position="start">$</InputAdornment>
                          ) : null,
                        }}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const keyName = column.keyName;

                          setModifiedCell((cell) => {
                            const updatedValue =
                              inputValue === '' &&
                              (keyName === 'map' || keyName === 'msrp')
                                ? null
                                : centFields.includes(keyName)
                                ? convertDollarsToCents(inputValue)
                                : inputValue;

                            return {
                              ...cell,
                              [keyName]: updatedValue,
                            };
                          });
                        }}
                        label={column.title}
                        // defaultValue={
                        //   ['itemPrice', 'msrp', 'map'].includes(column.keyName)
                        //     ? (row[column.keyName] / 100).toFixed(2)
                        //     : row[column.keyName]
                        // }
                        defaultValue={
                          ['itemPrice', 'msrp', 'map'].includes(
                            column.keyName,
                          ) &&
                          row['map'] !== null &&
                          row['msrp'] !== null
                            ? (row[column.keyName] / 100).toFixed(2)
                            : row[column.keyName]
                        }
                      />
                    </TableCell>
                  );
                } else if (column.keyName === 'edit') {
                  return (
                    <TableCell key={`${column.title}-${column.keyName}`}>
                      {column.editHandler(
                        paginationData[editCell],
                        modifiedCell,
                        editMode,
                        setEditMode,
                        index,
                        editCell,
                        setEditCell,
                        mutate,
                      )}
                    </TableCell>
                  );
                } else {
                  return (
                    <TableCell
                      key={`${column.title}-${column.keyName}`}
                      align={column.align === 'center' ? 'center' : 'left'}
                    >
                      {column.customComponent
                        ? column.customComponent({
                            row,
                            setOpenedCell,
                            openedCell,
                            openedType,
                            setImageCell,
                            imageCell,
                            cellIndex: index,
                            mutate,
                          })
                        : row[column.keyName]}
                    </TableCell>
                  );
                }
              })}
            </TableRow>
            {paginationData[openedCell] !== undefined && openedCell === index && (
              <TableRow>
                <TableCell className={classes.cellPadding} colSpan={12}>
                  <Collapse
                    style={{ width: '100%' }}
                    in={paginationData[openedCell] !== undefined}
                    unmountOnExit
                  >
                    {paginationData[openedCell] !== undefined &&
                      openedCell === index && (
                        <Grid
                          item
                          container
                          xs={12}
                          justifyContent="flex-start"
                        >
                          {openedType === 'fitment' && (
                            <>
                              <Grid
                                className={classes.boxContainer}
                                item
                                xs={12}
                                // xl={12}
                              >
                                <Tabs
                                  value={value}
                                  onChange={handleChange}
                                  orientation="vertical"
                                  // sx={{justifyContent: 'flex-start'}}
                                >
                                  <Tab
                                    style={{ color: '#000' }}
                                    label={
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        fitment
                                      </div>
                                    }
                                    {...tabProps(0)}
                                  />
                                  <Tab
                                    style={{ color: '#000' }}
                                    label={
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        Suppliers
                                      </div>
                                    }
                                    {...tabProps(1)}
                                  />
                                  <Tab
                                    style={{ color: '#000' }}
                                    label={
                                      <div
                                        style={{
                                          display: 'flex',
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        Listings
                                      </div>
                                    }
                                    {...tabProps(2)}
                                  />
                                  <Tab
                                    style={{ color: '#000' }}
                                    label={
                                      <div
                                        style={{
                                          display: 'flex',
                                          textAlign: 'left',
                                          justifyContent: 'flex-start',
                                        }}
                                      >
                                        Multipack
                                      </div>
                                    }
                                    {...tabProps(3)}
                                  />
                                </Tabs>
                                <Divider orientation="vertical" />
                                {value === 0 ? (
                                  <FitmentTable
                                    fitmentData={
                                      paginationData[openedCell].fitment
                                    }
                                    variationId={
                                      paginationData[openedCell].variationId
                                    }
                                    mutate={mutate}
                                  />
                                ) : value === 1 ? (
                                  <Grid
                                    className={classes.boxContainer}
                                    item
                                    xs={12}
                                    // xl={12}
                                  >
                                    {row && (
                                      <SupplierTable
                                        row={row}
                                        supplierData={
                                          paginationData[openedCell].suppliers
                                        }
                                        discountData={{
                                          brands:
                                            paginationData[openedCell]
                                              .discountBrands,
                                          vars:
                                            paginationData[openedCell]
                                              .discountVars,
                                          prods:
                                            paginationData[openedCell]
                                              .dixcountProds,
                                        }}
                                        suppliers={suppliers}
                                        mutate={mutate}
                                      />
                                    )}
                                  </Grid>
                                ) : value === 2 ? (
                                  <Grid
                                    className={classes.boxContainer}
                                    style={{ zIndex: 90 }}
                                    item
                                    xs={12}
                                    // md={4}
                                    // lg={4}
                                    // xl={12}
                                  >
                                    <ListingTable
                                      listingData={
                                        paginationData[openedCell].listings
                                      }
                                      supplierData={
                                        paginationData[openedCell].suppliers
                                      }
                                      discountData={{
                                        brands:
                                          paginationData[openedCell]
                                            .discountBrands,
                                        vars:
                                          paginationData[openedCell]
                                            .discountVars,
                                        prods:
                                          paginationData[openedCell]
                                            .dixcountProds,
                                      }}
                                      mutate={mutate}
                                      variationId={
                                        paginationData[openedCell].variationId
                                      }
                                    />
                                  </Grid>
                                ) : (
                                  <Grid
                                    className={classes.boxContainer}
                                    style={{ zIndex: 90 }}
                                    item
                                    xs={12}
                                  >
                                    <MultipackTable
                                      packData={
                                        paginationData[openedCell].packs
                                      }
                                      variationId={
                                        paginationData[openedCell].variationId
                                      }
                                      mutate={mutate}
                                    />
                                  </Grid>
                                )}
                              </Grid>
                              <Grid
                                className={classes.boxContainer}
                                item
                                xs={12}
                                // xl={12}
                              >
                                {/* {row && (
                                  <SupplierTable
                                    row={row}
                                    supplierData={
                                      paginationData[openedCell].suppliers
                                    }
                                    discountData={{
                                      brands:
                                        paginationData[openedCell]
                                          .discountBrands,
                                      vars:
                                        paginationData[openedCell].discountVars,
                                      prods:
                                        paginationData[openedCell]
                                          .dixcountProds,
                                    }}
                                    suppliers={suppliers}
                                    mutate={mutate}
                                  />
                                )} */}
                              </Grid>
                            </>
                          )}
                        </Grid>
                      )}
                  </Collapse>
                </TableCell>
              </TableRow>
            )}
          </Fragment>
        ))}
      </TableBody>
    </>
  );
}

export default TableRows;
