import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';

import type { ProductOperations } from './types';

export default function useProductOperations() {
  return useSWR<ProductOperations[]>(
    `/api/v1/reports/operations/products`,
    Api.get,
  );
}
