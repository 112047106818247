import { useState, useEffect } from 'react';
import Api from '@oneAppCore/services/Api';
import { useSnackbar } from 'notistack';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import StoreSelect from '../../common/Selects/StoreSelect';
import AsyncSelect from '../../common/Selects/AsyncSelect';
import ProductApi from '@oneAppCore/services/ProductApi';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.spacing(2), // 16px
    transition: '0.3s',
    boxShadow: '0px 14px 80px rgba(34, 35, 58, 0.2)',
    position: 'relative',
    maxWidth: '100%',
    overflow: 'initial',
    background: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
  },
  h5: {
    marginBottom: 15,
    fontWeight: 700,
  },
  product: {
    zIndex: 10
  }
}));

type CreateListingForm = {
  productId?: number;
  variationId?: number;
  sku?: string,
  price?: number,
  minPrice?: number,
  maxPrice?: number,
  storeId?: number,
  storeChannel?: any,
};

type DefaultValuesType = {
  variations?: any[];
  skus?: any[];
  storeChannels?: any[];
}

const channelEnums = {
  WOO_COMMERCE: 'wooCommerce',
  AMAZON: 'amazon',
  WALMART: 'walmart',
  EBAY: 'ebay'
}

function NewListings() {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [form, setForm] = useState<CreateListingForm>({});
  const [defaultValues, setDefaultValues] = useState<DefaultValuesType>({
    variations: [],
    skus: [],
    storeChannels: [],
  });
  const { enqueueSnackbar } = useSnackbar();

  const loadProducts = async (filter: string) => {
    if (filter?.length < 3) return [];
    const { rows: data } = await ProductApi.search({
      searchFilter: filter,
    });
    const newOptions = data.map((product) => ({
      value: product.id,
      label: `${product.name}-${product.id}`,
      variations: product.variations,
      skus: product.skus,
    }));
    setOptions(newOptions);
    return newOptions;
  };

  const setUserStoreChannels = async (storeId) => {
    try {
      const query = {
        storeId,
      };
      const { userStoreChannels } = await Api.get(
        `${process.env.PUBLIC_URL}/api/v1/me/filter-store-channels`,
        query,
      );
      let exportChannelsArray = userStoreChannels.map(channel => channelEnums[channel.name])
      setDefaultValues({ ...defaultValues, storeChannels: exportChannelsArray })
    } catch (e) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const update = (
    key,
    value,
  ) => {
    if (key == 'productId') {
      const product = options.filter(element => element.value == value)?.[0];
      let defaultSelected = {
        variations: product.variations,
        skus: product.skus.split(', '),
        storeChannels: [],
      }
      setDefaultValues(defaultSelected);
    }
    if (key == 'variationId') {
      let index = defaultValues.variations.indexOf(value);
      if (index != -1) {
        setForm((previous) => ({
          ...previous,
          [key]: value,
          sku: defaultValues.skus[index]
        }));
        return;
      }
    }
    if (key == 'storeId') {
      value && setUserStoreChannels(value);
    }
    setForm((previous) => ({
      ...previous,
      [key]: value,
    }));
  };

  const isDisabled = () => {
    if (!form.productId || !form.variationId || !form.price || !form.sku || !form.storeId) {
      return true;
    }
    return false;
  }

  return (
    <Grid container>
      <Grid item xs={12} lg={12}>
        <Card className={classes.root} style={{ width: '70%' }}>
          <Box p={2}>
            <Typography variant='h5' color='primary' className={classes.h5}>Create Listings</Typography>
            <Grid container item sm={12} spacing={3}>
              <Grid item xs={12} className={classes.product}>
                <AsyncSelect
                  id="product"
                  required
                  label="Product"
                  loadOptions={loadProducts}
                  value={options.find(
                    (option) => option.value === form.productId,
                  )}
                  onChange={(e) => update('productId', e.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl required size='small' fullWidth variant="outlined" className={classes.formControl} disabled={defaultValues.variations.length == 0}>
                  <InputLabel id="demo-simple-select-label">Variation</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={form.variationId}
                    onChange={(e) => update('variationId', e.target.value)}
                    label="Variation"
                  >
                    {defaultValues?.variations?.map((variation) => (
                      <MenuItem key={variation} value={variation}>{variation}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Listing Sku"
                  name="sku"
                  value={form.sku ? form.sku : ''}
                  onChange={(e) => update('sku', e.currentTarget.value)}
                  required
                  disabled={!form.variationId}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Price"
                  name="price"
                  value={form.price}
                  onChange={(e) => update('price', e.currentTarget.value)}
                  required
                  type='number'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Min Price"
                  name="minPrice"
                  value={form.minPrice}
                  onChange={(e) => update('minPrice', e.currentTarget.value)}
                  type='number'
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label="Max Price"
                  name="maxPrice"
                  value={form?.maxPrice || ''}
                  onChange={(e) => update('maxPrice', e.currentTarget.value)}
                  type='number'
                />
              </Grid>
              <Grid item xs={12} className={classes.product}>
                <StoreSelect
                  required
                  value={form.storeId}
                  onChange={(option) => update('storeId', option.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl size='small' fullWidth variant="outlined" className={classes.formControl} disabled={!form.storeId}>
                  <InputLabel id="demo-simple-select-outlined-label">Store Channel</InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={form.storeChannel}
                    onChange={(e) => update('storeChannel', e.target.value)}
                    label="Store Channel"
                  >
                    {defaultValues.storeChannels.length == 0 &&<MenuItem key={''} value=''>None</MenuItem>}
                    {defaultValues?.storeChannels?.map((c) => (
                      <MenuItem key={c} value={c}>{c}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={isDisabled()}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}

export default NewListings;
