import Api from '@oneAppCore/services/Api';
import useSWR from 'swr';
import recoilReportOptions from '../useReportOptions';

import type { SelectedReport } from './types';

export default function useSelectedReport() {
  const reportOptions = recoilReportOptions.useReportOptions();

  return useSWR<SelectedReport>(
    reportOptions.selectedReport
      ? `/api/v1/reports/${reportOptions.selectedReport}?page=${reportOptions.page}&limit=${reportOptions.limit}&csv=${reportOptions.csv}&newerThan=${reportOptions.newerThan}&channelId=${reportOptions.channelId}`
      : null,
    Api.get,
    {
      revalidateOnFocus: false,
    },
  );
}
